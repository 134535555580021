<template>
  <div class="flex flex-col">
    <woot-modal-header
      :header-title="$t('CONVERSATION.REPLYBOX.SCHEDULE_SEND')"
      :header-content="$t('CONVERSATION.REPLYBOX.SCHEDULE_TOOLTIP')"
    />
    <woot-modal-body>
      <form
        class="modal-content flex flex-col w-full"
        @submit.prevent="applyText"
      >
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-1">
            <select
              id="delayHours"
              v-model="delayHours"
              class="w-full"
              required
            >
              <option
                v-for="hour in leftHours"
                :key="hour"
                :value="hour"
                :label="
                  hour === 1
                    ? $t('CONVERSATION.REPLYBOX.HOUR')
                    : `${hour} ${$t('CONVERSATION.REPLYBOX.HOURS')}`
                "
              />
            </select>
          </div>
        </div>

        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button variant="clear" @click.prevent="onClose">
            {{
              $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.BUTTONS.CANCEL')
            }}
          </woot-button>
          <woot-button type="submit" :disabled="!delayHours">
            {{ $t('CONVERSATION.REPLYBOX.SEND') }}
          </woot-button>
        </div>
      </form>
    </woot-modal-body>
  </div>
</template>

<script>
export default {
  props: {
    hours: {
      type: Array,
      required: true,
    },
    lastMessageTime: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      delayHours: null,
    };
  },
  computed: {
    leftHours() {
      if (!this.lastMessageTime) return this.hours;
      return this.hours.filter(hour => {
        const currentTime = new Date();
        const lastMessageTime = new Date(this.lastMessageTime);
        const diff = currentTime - lastMessageTime;
        const hours = Math.floor(diff / 1000 / 60 / 60);
        return hours + hour < 24;
      });
    },
  },
  methods: {
    onClose() {
      this.delayHours = null;
      this.$emit('close');
    },

    applyText() {
      this.$emit('set-delay', this.delayHours);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-content {
  @apply pt-2 px-8 pb-8;
}

.container {
  width: 100%;
}
</style>
