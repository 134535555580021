<template>
  <div v-if="!getUIFlags.isFetching" class="settings--content">
    <settings-section
      v-if="getWaProfile.status !== 'FAIL'"
      :title="$t('INBOX_MGMT.PROFILE.API_CALLBACK.TITLE')"
      :sub-title="$t('INBOX_MGMT.PROFILE.API_CALLBACK.SUBTITLE')"
    >
      <label
        :class="{ error: $v.isValidPicFile.$invalid }"
        class="medium-9 columns settings-item"
      >
        {{ $t('INBOX_MGMT.PROFILE.PROFILE_PIC.LABEL', { maxSize: 5 }) }}
        <woot-avatar-uploader
          :src="formData.profilePic"
          class="settings-item"
          @change="handleImageUpload"
        />
        <span v-if="$v.isValidPicFile.$invalid" class="message">
          {{ $t('INBOX_MGMT.PROFILE.PROFILE_PIC.ERROR.FILE_SIZE_LIMIT', { maxSize: 5 }) }}
        </span>
      </label>
      <label class="medium-9 columns settings-item">
        {{ $t('INBOX_MGMT.PROFILE.TYPE.LABEL') }}
        <select v-model="formData.type">
          <option disabled value="">{{ $t('INBOX_MGMT.PROFILE.TYPE.PLACEHOLDER') }}</option>
          <option
            v-for="option in options"
            :value="option.value"
            :key="option.value"
            >{{ option.label }}</option
          >
        </select>
      </label>
      <woot-input
        v-model.trim="formData.address"
        class="medium-9 columns settings-item"
        :label="$t('INBOX_MGMT.PROFILE.ADDRESS.LABEL')"
        :placeholder="$t('INBOX_MGMT.PROFILE.ADDRESS.PLACEHOLDER')"
        :class="{ error: $v.formData.address.$invalid }"
        :error="$v.formData.address.$invalid
            ? $t('INBOX_MGMT.PROFILE.ERROR.TEXT_SIZE', { maxSize: 256 })
            : ''
        "
      />
      <woot-input
        v-model.trim="formData.description"
        class="medium-9 columns settings-item"
        :label="$t('INBOX_MGMT.PROFILE.DESCRIPTION.LABEL')"
        :placeholder="$t('INBOX_MGMT.PROFILE.DESCRIPTION.PLACEHOLDER')"
        :class="{ error: $v.formData.description.$invalid }"
        :error="$v.formData.description.$invalid
            ? $t('INBOX_MGMT.PROFILE.ERROR.TEXT_SIZE', { maxSize: 256 })
            : ''
        "
      />
      <woot-input
        v-model.trim="formData.email"
        class="medium-9 columns settings-item"
        :label="$t('INBOX_MGMT.PROFILE.EMAIL.LABEL')"
        :placeholder="$t('INBOX_MGMT.PROFILE.EMAIL.PLACEHOLDER')"
        :class="{ error: $v.formData.email.$invalid }"
        :error="$v.formData.email.$invalid
            ? $t('INBOX_MGMT.PROFILE.EMAIL.ERROR')
            : ''
        "
      />
      <woot-input
        v-model.trim="formData.website"
        class="medium-9 columns settings-item"
        :label="$t('INBOX_MGMT.PROFILE.WEBSITE.LABEL')"
        :placeholder="$t('INBOX_MGMT.PROFILE.WEBSITE.PLACEHOLDER')"
        :class="{ error: $v.formData.website.$invalid }"
        :error="$v.formData.website.$invalid
            ? $t('INBOX_MGMT.PROFILE.WEBSITE.ERROR')
            : ''
        "
      />
      <woot-input
        v-model.trim="formData.about"
        class="medium-9 columns settings-item"
        :label="$t('INBOX_MGMT.PROFILE.ABOUT.LABEL')"
        :placeholder="$t('INBOX_MGMT.PROFILE.ABOUT.PLACEHOLDER')"
        :class="{ error: $v.formData.about.$invalid }"
        :error="$v.formData.about.$invalid
            ? $t('INBOX_MGMT.PROFILE.ABOUT.ERROR')
            : ''
        "
      />
      <woot-submit-button
        type="submit"
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        @click="onUpdateProfile"
        :loading="getUIFlags.isUpdating"
        :disabled="getUIFlags.isUpdating || $v.$invalid"
      />
    </settings-section>
  </div>
  <div v-else>
    <loading-state />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { maxLength, email, required, requiredIf } from 'vuelidate/lib/validators';
import { shouldBeUrl } from 'shared/helpers/Validators';
import { checkFileSizeLimit } from '../../../../../../shared/helpers/FileHelper';
import SettingsSection from '../../../../../components/SettingsSection.vue';
import LoadingState from '../../../../../components/widgets/LoadingState.vue';
import alertMixin from '../../../../../../shared/mixins/alertMixin';

export default {
  components: {
    SettingsSection,
    LoadingState,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      getWaProfile: 'inboxes/getWaProfile',
      getUIFlags: 'inboxes/getUIFlags',
    }),
  },
  data() {
    return {
      formData: {
        address: '',
        description: '',
        email: '',
        website: '',
        about: '',
        type: null,
        profilePic: '',
        profilePicFile: null,
      },
      isValidPicFile: true,
      options: [
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.AUTOMOTIVE'), value: 1 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.BEAUTY'), value: 2 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.CLOTHING'), value: 3 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.EDUCATION'), value: 4 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.ENTERTAINMENT'), value: 5 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.EVENT'), value: 6 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.FINANCE'), value: 7 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.FOOD'), value: 8 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.PUBLIC'), value: 9 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.HOTEL'), value: 10 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.MEDICAL'), value: 11 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.NON_PROFIT'), value: 12 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.PROFESSIONAL'), value: 13 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.SHOPPING'), value: 14 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.TRAVEL'), value: 15 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.RESTAURANT'), value: 16 },
        { label: this.$t('INBOX_MGMT.PROFILE.TYPE.OPTIONS.OTHER'), value: 17 },
      ],
    };
  },
  validations: {
    formData: {
      website: {
        shouldBeUrl,
        maxLength: maxLength(256),
      },
      address: {
        maxLength: maxLength(256),
      },
      description: {
        maxLength: maxLength(256),
      },
      email: {
        email,
        maxLength: maxLength(128),
      },
      about: {
        required: requiredIf(function () {
          return this.getWaProfile.about;
        }),
        maxLength: maxLength(136),
      },
    },
    isValidPicFile: {
      fileLimitMb(value) {
        return value;
      },
    }
  },
  watch: {
    getWaProfile(e) {
      if (e) this.setWaProfile();
      if (e?.status === 'FAIL') {
        this.showAlert(
          this.$t('INBOX_MGMT.PROFILE.ERROR.LOAD')
        );
      }
    },
  },
  created() {
    this.fetchWaProfile();
  },
  methods: {
    onUpdateProfile() {
      this.updateWaProfile();
    },
    handleImageUpload({ file, url }) {
      this.formData.profilePicFile = file;
      this.formData.profilePic = url;
      this.isValidPicFile = checkFileSizeLimit(file, 5);
    },
    fetchWaProfile() {
      this.$store.dispatch('inboxes/getWaProfile', this.inbox.id);
    },
    async updateWaProfile() {
      if (this.$v.$invalid) return;

      try {
        await this.$store.dispatch('inboxes/setWaProfile', {
          inboxId: this.inbox.id,
          formData: { ...this.prepareFormData() },
        });
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE')
        );
      }
    },
    setWaProfile() {
      if (!this.setWaProfile) return;

      const {
        address,
        description,
        email,
        website,
        about,
        type,
        profilePic,
      } = this.getWaProfile;

      this.formData = {
        address,
        description,
        email,
        website,
        about,
        type,
        profilePic,
      };
    },
    prepareFormData() {
      // if you need to clear this data, you can send " "(from documentation)
      const replaceFormData = Object.fromEntries(Object.entries(this.formData).map(([key, value]) => [key, value === '' ? ' ' : value]));

      return Object.fromEntries(
        Object.entries(replaceFormData).filter(([key, value]) => value !== null && value !== '')
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';

.settings-item {
  padding-bottom: var(--space-normal);
}
</style>
