export const state = {
  primaryMenuWithTitles: (localStorage.getItem('uiSettings_primaryMenuWithTitles') || 'true') === 'true',
};

export const mutations = {
  ['SET_PRIMARY_MENU_WITH_TITLES'](_state, value) {
    localStorage.setItem('uiSettings_primaryMenuWithTitles', value)
    _state.primaryMenuWithTitles = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
