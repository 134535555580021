import ApiClient, {Utility} from './ApiClient';

const buildCreatePayload = (data) => {
  let payload;
  let {headerSampleFile, ...item} = data
  if (headerSampleFile) {
    data = item;
    payload = new FormData();

    payload.append('headerSampleFile', headerSampleFile, headerSampleFile.name);
    Utility.convertModelToFormData(data, payload, '')
  } else {
    payload = data;
    //if (headerSampleFile === null) payload.headerSampleFile = null //explicit deletion
  }
  return payload;
};

export default new class extends ApiClient {
  constructor() {
    super('online_wa/templates', { accountScoped: true });
  }

  create(data) {
    return super.create(buildCreatePayload({ ...data}));
  }

  update(id, data) {
    return super.update(id, buildCreatePayload(data));
  }

  delete({inbox_id, name}) {
    return super.delete(`${inbox_id}-${name}`);
  }

  getSupportedInboxTypes() {
    return super.get('supported_inbox_types');
  }
}
