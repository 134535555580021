<template>
  <div class="contact--profile">
    <div class="contact--info">
      <h3 class="sub-block-title contact--name">
        {{ contact.name }}
      </h3>
    </div>
    <div>
      <img :src="thumbnail_url"/>
    </div>
    <div>
      {{description}}
    </div>
    <div>
      {{ $t('CONVERSATION_SIDEBAR.VIDEO_INFO.URL') }}: <a :href="share_url" target="_blank">open video</a>
    </div>
    <div>
      {{ $t('CONVERSATION_SIDEBAR.VIDEO_INFO.DURATION') }}: {{ duration }}
    </div>
    <div>
      {{ $t('CONVERSATION_SIDEBAR.VIDEO_INFO.VIEWS') }}: {{ video_views }}
    </div>
    <div>
      {{ $t('CONVERSATION_SIDEBAR.VIDEO_INFO.LIKES') }}: {{ likes }}
    </div>
    <div>
      {{ $t('CONVERSATION_SIDEBAR.VIDEO_INFO.CREATED') }}: {{ create_time }}
    </div>
  </div>

</template>
<script>

export default {
  name: "VideoInfo",
  props: ['chat', 'contact'],
  data() {
    return {
    }
  },
  computed: {
    description() {
      return this.chat?.additional_attributes?.name || ''
    },
    share_url() {
      return this.chat?.additional_attributes?.share_url || ''
    },
    thumbnail_url() {
      return this.chat?.additional_attributes?.thumbnail_url || ''
    },
    video_views() {
      return this.chat?.additional_attributes?.video_views || 0
    },
    likes() {
      return this.chat?.additional_attributes?.likes || 0
    },
    duration() {
      const seconds = this.chat?.additional_attributes?.video_duration || 0;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const convertTime = (seconds > 59)
        ? `${minutes} minute(s) and ${remainingSeconds} seconds`
        : `${remainingSeconds} seconds`;

      return convertTime
    },
    create_time() {
      const unixTime = this.chat?.additional_attributes?.create_time || 0;
      const date = new Date(unixTime * 1000)
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
      return formattedDate;

    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);
  padding-bottom: 3rem !important;

  .user-thumbnail-box {
    margin-right: var(--space-normal);
  }
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: start; // d99d
  margin-bottom: 0.7rem;
}

.contact--name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 0;

}

</style>
