<template>
  <form
    class="small-12 medium-12 columns"
    @submit.prevent="submit">
    <div class="row">
      <woot-input
        ref="phoneNumberInput"
        v-model.trim="phoneNumber"
        type="number"
        class="medium-4 columns"
        :label="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.PHONE_NUMBER.LABEL')"
        :placeholder="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.PHONE_NUMBER.PLACEHOLDER')"
      />
      <woot-input
        ref="textInput"
        v-model.trim="text"
        type="text"
        class="medium-4 columns"
        :label="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.TEXT.LABEL')"
        :placeholder="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.TEXT.PLACEHOLDER')"
      />
    </div>
    <div class="row">
      <woot-button
        type="submit"
          :is-disabled="$v.$invalid"
          data-testid="label-submit"
      >
        {{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.COPY_BUTTON') }}
      </woot-button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import {required} from "vuelidate/lib/validators";
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import contactMixin from "dashboard/mixins/contactMixin";

export default {
  name: "ChatWithBusiness",
  mixins: [contactMixin],
  data() {
    return {
      phoneNumber: undefined,
      text: '',
    }
  },
  validations: {
    phoneNumber: {required}
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      getCurrentAccountId: 'getCurrentAccountId',
    }),
    encodedText() {
      return encodeURIComponent(this.text)
    },
    script() {
      const phone = this.israelPhone2e164(this.phoneNumber)?.replace('+', '');
      return this.text
        ? `https://wa.me/${phone}?text=${this.encodedText}`
        : `https://wa.me/${phone}`;
    },
    account() {
      return this.getAccount(this.getCurrentAccountId)
    },
  },
  watch: {
    account() {
      this.swapInputs();
    },
  },
  mounted() {
    this.swapInputs();
  },
  methods: {
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        await copyTextToClipboard(this.script);
        bus.$emit('newToastMessage', this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'));
      } catch (e) {
        console.error(e)
      }
    },
    swapInputs() {
      const elem1 = this.$refs.phoneNumberInput.$el;
      const elem2 = this.$refs.textInput.$el;
      const parent2 = elem2.parentNode;
      if (this.account.locale === 'he') {
        parent2.insertBefore(elem1, elem2);
        elem2.classList.add('marginRightSpace');
      } else {
        elem1.classList.add('marginRightSpace');
      }
    },
  },
}
</script>

<style scoped>
.marginRightSpace {
  margin-right: 10px;
}
</style>
