<template>
  <modal name="importModal" :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('IMPORT_CONTACTS.TITLE')">
        <p>
          {{ $t('IMPORT_AUDIENCE.DESC') }}
          <a :href="csvUrl" download="import-wa-mass-audience-sample">{{
            $t('IMPORT_AUDIENCE.DOWNLOAD_LABEL')
          }}</a>
        </p>
        <label>
          <input
            v-model="isUtf8"
            type="checkbox"
            name="isUtf8"
          />
          UTF8
        </label>
      </woot-modal-header>
      <div class="row modal-content">
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('IMPORT_AUDIENCE.FORM.LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept="text/csv,.xlsx"
              @change="handleFileUpload"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="uploadFile"
            >
              {{ $t('IMPORT_AUDIENCE.FORM.SUBMIT') }}
            </woot-button>
            <button class="button clear" @click.prevent="onClose">
              {{ $t('IMPORT_AUDIENCE.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
const xlsx = require('xlsx');

export default {
  components: {
    Modal,
  },
  mixins: [alertMixin],
  props: {
    names: {
      type: Array,
      default: []
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onResult: {
      type: Function,
      default: data => {},
    },
  },
  data() {
    return {
      show: true,
      file: '',
      isUtf8: true,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    csvUrl() {
      return '/downloads/import-campaign_audience-sample.xlsx';
    },
  },
  methods: {
    uploadFile() {
      try {
        if (!this.file) return;
        const reader = new FileReader();

        let uploadFunc = this.uploadFileCsv;

        const extPattern = new RegExp(`\\.xlsx$`, 'i')
        if (extPattern.test(this.file.name)) {
          uploadFunc = this.uploadFileXlsx
        }

        uploadFunc(reader, data => {
          this.onResult(data)
        })

        this.onClose();
        //this.showAlert(this.$t('IMPORT_AUDIENCE.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('IMPORT_AUDIENCE.ERROR_MESSAGE')
        );
      }
    },
    uploadFileCsv(reader, onResult) {
      reader.onload = e => {
        const text = e.target.result;
        const data = this.csvToArray(text,this.names);
        onResult(data)
      };

      reader.readAsText(this.file, this.isUtf8 ? undefined : 'CP1255');
    },
    uploadFileXlsx(reader, onResult) {
      reader.onload = e => {
        const blob = e.target.result;
        const workbook = xlsx.read(blob, {type: 'binary'});
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        const rows = xlsx.utils.sheet_to_json(worksheet, {
          header: "A",
          range: 0,
          defval: null
        });

        const data = rows.map(x => Object.values(x));

        onResult(this.overloadHeaders(this.names, data))
      };

      reader.readAsBinaryString(this.file);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    csvToArray(str, names = [], delimiter = ",") {
      const data = str.split(/\r?\n/).map(x=>x.split(delimiter))

      return this.overloadHeaders(names, data);
    },
    overloadHeaders(names, data) {
      if (!data || data.length < 2) return []

      let headers = data[0]
      const rows = data.slice(1)

      if (names.length > 0)  headers = headers
        .map((x,i) => names.length - 1 >= i ? names[i] : null )

      return rows.map(function (values) {
        const el = headers.reduce(function (object, header, index) {
          if (header) object[header] = typeof values[index] === 'string' ? values[index]
            .replace(/((\r\n)|(\n)|(\r))$/g, '')
            .replace(/((\r\n)|(\n)|(\r))/g, '. ') : values[index];
          return object;
        }, {});
        return el;
      });
    }
  },
};
</script>
