import Vue from 'vue';
import * as types from '../mutation-types';
import ContactAPI from '../../api/contacts';
import ConversationApi from '../../api/conversations';

export const createMessagePayload = (payload, message) => {
  const { content, cc_emails, bcc_emails, mailSubject } = message;
  payload.append('message[content]', content);
  if (cc_emails) payload.append('message[cc_emails]', cc_emails);
  if (bcc_emails) payload.append('message[bcc_emails]', bcc_emails);
  if (mailSubject) payload.append('additional_attributes[mail_subject]', mailSubject);
};

export const createConversationPayload = ({ params, contactId, files }) => {
  const { inboxId, message, sourceId, contact = {} /* d99d */, mailSubject, assigneeId, newConversation /* d99d */ } = params;
  const payload = new FormData();

  if (message) {
    createMessagePayload(payload, message);
  }

  if (files && files.length > 0) {
    files.forEach(file => payload.append('message[attachments][]', file));
  }

  if (contact.email) payload.append('contact[email]', contact.email); // d99d
  if (contact.phone_number) payload.append('contact[phone_number]', contact.phone_number); // d99d
  if (contact.name) payload.append('contact[name]', contact.name); // d99d
  payload.append('inbox_id', inboxId);
  if (contactId /* d99d */) payload.append('contact_id', contactId);
  if (sourceId /* d99d */) payload.append('source_id', sourceId);
  payload.append('additional_attributes[mail_subject]', mailSubject);
  if (assigneeId) payload.append('assignee_id', assigneeId);
  if (newConversation) payload.append('is_new_conversation', newConversation); // d99d

  return payload;
};

export const createWhatsAppConversationPayload = ({ params }) => {
  const { inboxId, message, contactId, contact, mailSubject /* d99d */, sourceId, assigneeId, newConversation /* d99d */} = params;
  const {contentAttributes: content_attributes,...messageBody} = message // d99d

  const payload = {
    inbox_id: inboxId,
    contact_id: contactId,
    contact, // d99d
    source_id: sourceId,
    message: {...messageBody, content_attributes}, // d99d added content_attributues
    assignee_id: assigneeId,
    additional_attributes: mailSubject ? {mail_subject: mailSubject} : undefined,
    is_new_conversation: newConversation ? newConversation : undefined,
  };

  return payload;
};

const setNewConversationPayload = ({
  isFromWhatsApp,
  params,
  contactId,
  files,
}) => {
  if (isFromWhatsApp) {
    return createWhatsAppConversationPayload({ params });
  }
  return createConversationPayload({
    params,
    contactId,
    files,
  });
};

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContactConversation: $state => id => {
    return $state.records[Number(id)] || [];
  },
};

export const actions = {
  create: async ({ commit, dispatch }, { params, isFromWhatsApp }) => {
    commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
      isCreating: true,
    });
    const { contactId, files } = params;
    try {
      const payload = setNewConversationPayload({
        isFromWhatsApp,
        params,
        contactId,
        files,
      });

      const { data } = await ConversationApi.create(payload);
      commit(types.default.ADD_CONTACT_CONVERSATION, {
        id: contactId,
        data,
      });
      if (contactId) dispatch('contacts/fetchContactableInbox', contactId, { root: true }); // d99d to refresh available inboxes
      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isCreating: false,
      });
    }
  },
  get: async ({ commit }, contactId) => {
    commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await ContactAPI.getConversations(contactId);
      commit(types.default.SET_CONTACT_CONVERSATIONS, {
        id: contactId,
        data: response.data.payload,
      });
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isFetching: false,
      });
    }
  },
};

export const mutations = {
  [types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_CONTACT_CONVERSATIONS]: ($state, { id, data }) => {
    Vue.set($state.records, id, data);
  },
  [types.default.ADD_CONTACT_CONVERSATION]: ($state, { id, data }) => {
    const conversations = $state.records[id] || [];

    const updatedConversations = [...conversations];
    const index = conversations.findIndex(
      conversation => conversation.id === data.id
    );

    if (index !== -1) {
      updatedConversations[index] = { ...conversations[index], ...data };
    } else {
      updatedConversations.push(data);
    }

    Vue.set($state.records, id, updatedConversations);
  },
  [types.default.DELETE_CONTACT_CONVERSATION]: ($state, id) => {
    Vue.delete($state.records, id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
