import { render, staticRenderFns } from "./CustomAttributeSelector.vue?vue&type=template&id=5fcc0af9&scoped=true&"
import script from "./CustomAttributeSelector.vue?vue&type=script&lang=js&"
export * from "./CustomAttributeSelector.vue?vue&type=script&lang=js&"
import style0 from "./CustomAttributeSelector.vue?vue&type=style&index=0&id=5fcc0af9&prod&lang=scss&scoped=true&"
import style1 from "./CustomAttributeSelector.vue?vue&type=style&index=1&id=5fcc0af9&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fcc0af9",
  null
  
)

export default component.exports