<template>
  <div class="contact--profile">
    <div class="contact--info">
      <h3 class="sub-block-title contact--name">
        {{ contact.name }}
      </h3>
    </div>

    <div v-for="att in attachments">
      <img v-if="att.file_type==='image'" :src="att.data_url || att.original_url"/>
      <video v-if="att.file_type==='video'"playsInline :src="att.data_url || att.original_url"/>
    </div>

    <div>
      {{description}}
    </div>

  </div>

</template>

<script>

import FBChannel2 from '../../../api/channel/fbChannel2';
import Video from "../../../components/widgets/conversation/bubble/Video.vue";

export default {
  name: "PostInfo",
  components: {Video},
  props: ['chat', 'contact'],
  data() {
    return {
    }
  },
  mounted() {
    //
  },
  computed: {
    attachments() {
      return this.chat?.additional_attributes?.attachments || []
    },
    description() {
      return this.chat?.additional_attributes?.name || ''
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.contact--profile {
  position: relative;
  align-items: flex-start;
  padding: var(--space-normal);
  padding-bottom: 3rem !important;

  .user-thumbnail-box {
    margin-right: var(--space-normal);
  }
}

.contact--details {
  margin-top: var(--space-small);
  width: 100%;
}

.contact--info {
  text-align: start; // d99d
  margin-bottom: 0.7rem;
}

.contact--name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-small);
}

.contact--name {
  text-transform: capitalize;
  white-space: normal;
  margin: 0 var(--space-smaller) 0 0;

}

</style>
