<template>
  <settings-section
      :title="$t('INBOX_MGMT.TIKTOK_REAUTHORIZE.TITLE')"
      :sub-title="$t('INBOX_MGMT.TIKTOK_REAUTHORIZE.SUBTITLE')"
  >
    <div class="tiktok--login">
      <woot-button
          color-scheme="success"
          @click="reauthorize"
      >
        {{ $t('INBOX_MGMT.TIKTOK_REAUTHORIZE.BUTTON') }}
      </woot-button>
    </div>
  </settings-section>
</template>

<script>
/* global FB */
import SettingsSection from '../../../../../components/SettingsSection';
import alertMixin from 'shared/mixins/alertMixin';
import TiktokClient from "../../../../../api/channel/tiktokClient";

export default {
  name: 'TiktokReauthorize',
  components: {
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: Number,
      required: true,
    },
  },
  mounted() {
  },

  methods: {
    async reauthorize() {
      const response = await TiktokClient.generateAuthorization({ inbox_id: this.inboxId });
      const {
        data: { url },
      } = response;

      window.location.href = url;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.tiktok--login {

}
</style>
