<template>
<div v-if="isShown" class="reply--wrapper" :class="wrapClass">
    <div style="width: 100%">
        <div class="reply-body--wrapper" :class="{'incoming-bg': isIncoming, 'template-bg': isTemplate}">
                <span class="vert-line"></span>
                <div class="reply-body cursor-pointer" @click="onReplyContentClick(data.content_attributes.in_reply_to)">
                    <div class="reply-content">
                        <div class="reply-author">{{replyName}}</div>
                        <div dir="auto" class="reply-text">{{replyText}}</div>
                    </div>

                </div>
                <div v-if="replyThumb" class="thump-wrapper">
                    <div class="reply-thumb">
                        <div style="width: 100%; height: 100%; position: relative;">
                            <div class="image--wrapper">
                                <div v-if="replyThumb.url" class="image-content" :class="mediaClass"
                                     :style="{ backgroundImage: 'url(' + this.replyThumb.url + ')' }"
                                />
                                <fluent-icon v-else class="attach-icon" :icon="replyThumb.icon" size="24" />
                            </div>

                        </div>
                    </div>
                </div>



        </div>
    </div>

</div>
</template>

<script>
import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  name: "Reply",
  props: {
    replyData: {},
    data: {},
    isFromBot: false,

  },
  computed: {
    isShown() {
      return !!this.replyData
    },
    wrapClass() {
      return {
        'incoming-schema': this.replyIsIncoming,
        'outgoing-schema': !this.replyIsIncoming,
      }
    },
    replyName() {
      return this.replyData.sender?.name
    },
    replyText() {
      return this.replyData.content
    },
    replyIsIncoming() {
      return this.replyData.message_type === MESSAGE_TYPE.INCOMING
    },
    replyThumb() {
      let res = undefined
      if (this.replyData.file_type) {
        res = {url:  this.replyData.thumb_url}
        if (!res.url) {
          switch (this.replyData.file_type) {
            case 'video':
              res.icon = 'video';
              break;
            case 'audio':
              res.icon = 'microphone';
              break;
          default:
              res.icon = 'document'
          }
        }
      }

      return res;
    },
    mediaClass() {
      return this.replyData.file_type && 'content-' + this.replyData.file_type;
    },
    isIncoming() {
      return this.data?.message_type === MESSAGE_TYPE.INCOMING
    },
    isTemplate() {
      return this.data?.message_type === MESSAGE_TYPE.TEMPLATE || this.isFromBot
    },
  },
  methods: {
    onReplyContentClick(messageId) {
      bus.$emit('setActiveChatOnMessage', messageId)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

div[dir=ltr] .reply--wrapper {
  margin: 0 -8px 6px -10px;
}
div[dir=rtl] .reply--wrapper {
  margin: 0 -10px 6px -8px;
}

.reply-body--wrapper {
  background-color: #0071d9;
  border-radius: 7.5px;
  position: relative;
  display: flex;
  overflow: hidden;

  &.incoming-bg {
    background-color: #edf6ff;
  }

  &.template-bg {
    background-color: #b372db;
  }
}

.vert-line {
  flex: none;
  width: 4px;
}

div[dir=ltr] .reply-body {
  padding: 4px 12px 8px 8px;
}

div[dir=rtl] .reply-body {
  padding: 4px 8px 8px 12px;
}

.reply-body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 42px;
  max-height: 82px;
  overflow: hidden;
}

.reply-content {
  flex-grow: 1;
  overflow: hidden;
}

.reply-author {
  display: inline-flex;
  width: 100%;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: 22px;
}

.reply-text {
  display: -webkit-box;
  max-height: 60px;
  overflow: hidden;
  font-size: $font-size-mini;
  line-height: 20px;
  color: var(--quoted-message-text);
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.outgoing-schema {
  .vert-line {
    background-color: #53bdeb;
  }

  .reply-author {
    color: #53bdeb;
  }

}

.incoming-schema {
  .vert-line {
    background-color: #42b94c;
  }
  .reply-author {
    color: #42b94c;
  }
}

//image
.thump-wrapper {
  flex: 0 0 auto;
  overflow: hidden;
}

.reply-thumb {
  background-position: 50%;
  background-size: cover;
  position: relative;
  width: 58px;
  height: 100%;

  .image--wrapper {
    height: 100%;
    display: flex;
    width: 100%;
    position: absolute;
    z-index: 1;
    align-items: center;
    justify-content: center;

    .attach-icon {
      opacity: 0.5;
    }
  }
}

.image-content {
  background-position: 50%;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;

  &.content-video,&.content-audio {
    background-size: contain;
    position: absolute;
    width: 50%;
    height: 50%;
    background-repeat: no-repeat;
    opacity: 0.6;
  }
  &.content-video {
    width: 40%;
    height: 40%;
  }
}




</style>
