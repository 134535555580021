<template>
  <div class="video message-text__wrap">
    <div @click="onClick" class="center" />
    <video :src="url" muted playsInline @click="onClick" />
    <woot-modal :show.sync="show" :on-close="onClose">
      <video :src="url" controls autoplay playsInline
        class="modal-video skip-context-menu"
      />
    </woot-modal>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: url(~dashboard/assets/images/play-rectangle-button.png) no-repeat center center;
}
</style>
