<template>
  <div class="card note-wrap private-message-wrap">
    <div class="header">
      <div class="meta">
        <thumbnail
          :title="user.name"
          :src="user.thumbnail"
          :username="user.name"
          size="20px"
        />
        <div class="date-wrap">
          <span class="fw-medium"> {{ user.name }} </span>
          <span class="fw-medium time-stamp">{{ readableTime }} ({{ inboxName }})</span>
        </div>
      </div>
    </div>
    <p v-dompurify-html="formatMessage(message || '')" class="note__content" />
    <div v-for="attachment in attachments" class="medium-6 columns">
      <all-bubble :file="attachment" />
    </div>
  </div>
</template>

<script>
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import timeMixin from 'dashboard/mixins/time';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import AllBubble from '../../../components/AllBubble.vue';

export default {
  mixins: [timeMixin, messageFormatterMixin],
  components: {
    Thumbnail,
    AllBubble,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    user: {
      type: Object,
      default: () => {},
    },
    message: {
      type: String,
      default: '',
    },
    createdAt: {
      type: Number,
      default: 0,
    },
    inboxName: {
      type: String,
      default: '',
    },
    attachments: {
      type: Array,
      default: undefined,
    },
  },
  computed: {
    readableTime() {
      return this.messageStampFull(this.createdAt)
    },
  },
};
</script>

<style lang="scss" scoped>
// For RTL direction view

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: var(--font-size-mini);

  .meta {
    display: flex;
    align-items: center;

    .date-wrap {
      margin: 0 var(--space-smaller);
      padding: var(--space-micro);
      color: var(--color-body);
    }
  }
}

.private-message-wrap {
  background-color: #fff3d5;
  border: 1px solid #ffdb7f;
}
</style>
