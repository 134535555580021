import ApiClient from '../ApiClient';

class TiktokClient extends ApiClient {
  constructor() {
    super('online_tiktok', { accountScoped: true });
  }

  generateAuthorization(payload) {
    return axios.post(`${this.url}/authorization`, payload);
  }

  tiktokLike(payload) {
    return axios.post(`${this.url}/like`, payload);
  }

  tiktokHide(payload) {
    return axios.post(`${this.url}/hide`, payload);
  }
}

export default new TiktokClient();
