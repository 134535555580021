<template>
  <section class="w-full">

    <woot-modal
                :show.sync="show"
                :on-close="onCancel">
      <div class="column">
        <woot-modal-header
          :header-title="$t('FACEBOOK2.FB_POST.NEW_POST')"
        />
        <new-fb-post-form
          :inbox="inbox"
          :is-submitting="isSubmitting"
          @submit="onSubmit"
          @cancel="onCancel"
        />
      </div>
    </woot-modal>
  </section>
</template>

<script>


import NewFbPostForm from "./NewFbPostForm.vue";
import OnlineFbAPI from 'dashboard/api/onlinefb'
import alertMixin from "shared/mixins/alertMixin";

export default {
  mixins: [alertMixin],
  components: {NewFbPostForm},
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    onSuccess: {
      type: Function,
      default: undefined,
    },
    inbox: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      isSubmitting: false
    }
  },

  methods: {
    onCancel() {
      this.$emit("update:show", false)
    },
    async onSubmit(payload) {
      this.isSubmitting = true;
      try {
        const {data} = await OnlineFbAPI.createPost(payload)
        if (!data) throw 'empty result...'

        this.showAlert(this.$t('FACEBOOK2.FB_POST.API.SEND_SUCCESS'));
        this.onCancel();
      } catch (err) {
        console.log('err.message', err, err.message)
        switch (err.message) {
          case 'image_ratio':
            this.showAlert(this.$t('FACEBOOK2.FB_POST.API.RATIO_ERROR'));
            break;
          case 'image':
            this.showAlert(this.$t('FACEBOOK2.FB_POST.API.IMAGE_ERROR'));
            break;
          default:
            this.showAlert(this.$t('PLATFORM.API.SAVE_ERROR'));
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss">
.message-input {
  min-height: 8rem;
}
</style>
