import wootConstants from '../../../constants/globals';
import { CONVERSATION_PRIORITY_ORDER } from 'shared/constants/messages';
import { evaluateSLAStatus } from '@chatwoot/utils';

const parseSLATime = (formattedTime) => {
  const units = {
    y: 31536000, // 60 * 60 * 24 * 365
    mo: 2592000, // 60 * 60 * 24 * 30
    d: 86400, // 60 * 60 * 24
    h: 3600, // 60 * 60
    m: 60,
  };

  const parts = formattedTime.split(' ');
  let totalSeconds = 0;

  parts.forEach(part => {
    const match = part.match(/^(\d+)(y|mo|d|h|m)$/);
    if (match) {
      const value = parseInt(match[1], 10);
      const unit = match[2];
      if (units[unit]) {
        totalSeconds += value * units[unit];
      }
    }
  });

  return totalSeconds;
};

const SORT_OPTIONS = {
  last_activity_at_asc: ['sortOnLastActivityAt', 'asc'],
  last_activity_at_desc: ['sortOnLastActivityAt', 'desc'],
  created_at_asc: ['sortOnCreatedAt', 'asc'],
  created_at_desc: ['sortOnCreatedAt', 'desc'],
  priority_asc: ['sortOnPriority', 'asc'],
  priority_desc: ['sortOnPriority', 'desc'],
  waiting_since_asc: ['sortOnWaitingSince', 'asc'],
  waiting_since_desc: ['sortOnWaitingSince', 'desc'],
  sla_threshold_asc: ['sortOnSlaThreshold', 'asc'],
  sla_threshold_desc: ['sortOnSlaThreshold', 'desc'],
};
const sortAscending = (valueA, valueB) => valueA - valueB;
const sortDescending = (valueA, valueB) => valueB - valueA;

const getSortOrderFunction = sortOrder =>
  sortOrder === 'asc' ? sortAscending : sortDescending;

const sortConfig = {
  sortOnLastActivityAt: (a, b, sortDirection) =>
    getSortOrderFunction(sortDirection)(a.last_activity_at, b.last_activity_at),

  sortOnCreatedAt: (a, b, sortDirection) =>
    getSortOrderFunction(sortDirection)(a.created_at, b.created_at),

  sortOnPriority: (a, b, sortDirection) => {
    const DEFAULT_FOR_NULL = sortDirection === 'asc' ? 5 : 0;

    const p1 = CONVERSATION_PRIORITY_ORDER[a.priority] || DEFAULT_FOR_NULL;
    const p2 = CONVERSATION_PRIORITY_ORDER[b.priority] || DEFAULT_FOR_NULL;

    return getSortOrderFunction(sortDirection)(p1, p2);
  },
  sortOnSlaThreshold: (a, b, sortDirection) => {
    let aTime = 0;
    let bTime = 0;
    if (a.applied_sla) {
      aTime = parseSLATime(
        evaluateSLAStatus({
          appliedSla: a.applied_sla,
          chat: a,
        }).threshold
      );
    }
    if (b.applied_sla) {
      bTime = parseSLATime(
        evaluateSLAStatus({
          appliedSla: b.applied_sla,
          chat: b,
        }).threshold
      );
    }
    if (aTime === 0 && bTime === 0) return 0;
    if (aTime === 0) return 1;
    if (bTime === 0) return -1;

    // Determine sorting order based on direction
    return sortDirection === 'asc' ? aTime - bTime : bTime - aTime;
  },
  sortOnWaitingSince: (a, b, sortDirection) => {
    const sortFunc = getSortOrderFunction(sortDirection);
    if (!a.waiting_since || !b.waiting_since) {
      if (!a.waiting_since && !b.waiting_since) {
        return sortFunc(a.created_at, b.created_at);
      }
      return sortFunc(a.waiting_since ? 0 : 1, b.waiting_since ? 0 : 1);
    }

    return sortFunc(a.waiting_since, b.waiting_since);
  },
};

export const sortComparator = (a, b, sortKey) => {
  const [sortMethod, sortDirection] =
    SORT_OPTIONS[sortKey] || SORT_OPTIONS.last_activity_at_desc;
  return sortConfig[sortMethod](a, b, sortDirection);
};

export const findPendingMessageIndex = (chat, message) => {
  const { echo_id: tempMessageId } = message;
  return chat.messages.findIndex(
    m => m.id === message.id || m.id === tempMessageId
  );
};

export const filterByStatus = (chatStatus, filterStatus) =>
  filterStatus === 'all'
    ? true
    : chatStatus === filterStatus ||
      (filterStatus === wootConstants.STATUS_TYPE.ACTIVE &&
        chatStatus !== wootConstants.STATUS_TYPE.RESOLVED);

export const filterByInbox = (shouldFilter, inboxId, chatInboxId) => {
  const isOnInbox = Number(inboxId) === chatInboxId;
  return inboxId ? isOnInbox && shouldFilter : shouldFilter;
};

export const filterByTeam = (shouldFilter, teamId, chatTeamId) => {
  const isOnTeam = Number(teamId) === chatTeamId;
  return teamId ? isOnTeam && shouldFilter : shouldFilter;
};

export const filterByLabel = (shouldFilter, labels, chatLabels) => {
  const isOnLabel = labels.every(label => chatLabels.includes(label));
  return labels.length ? isOnLabel && shouldFilter : shouldFilter;
};
export const filterByUnattended = (
  shouldFilter,
  conversationType,
  firstReplyOn,
  waitingSince
) => {
  return conversationType === 'unattended'
    ? (!firstReplyOn || !!waitingSince) && shouldFilter
    : shouldFilter;
};

// d99d
export const filterByUnattended99 = (
  shouldFilter,
  conversationType,
  messages
) => {
  if (conversationType !== 'unattended' || messages.length <= 0)
    return shouldFilter;
  const lastMessage = messages
    .filter(x => x.message_type <= 1 && !x.private)
    .last();
  return (
    (!lastMessage ||
      lastMessage.additional_attributes?.campaign_id ||
      lastMessage.message_type === 0) &&
    shouldFilter
  );
};
// .

export const applyPageFilters = (conversation, filters) => {
  const { inboxId, status, labels = [], teamId, conversationType } = filters;
  const {
    status: chatStatus,
    inbox_id: chatInboxId,
    labels: chatLabels = [],
    meta = {},
    first_reply_created_at: firstReplyOn,
    messages, // d99d
    waiting_since: waitingSince,
  } = conversation;
  const team = meta.team || {};
  const { id: chatTeamId } = team;
  let shouldFilter = filterByStatus(chatStatus, status);
  shouldFilter = filterByInbox(shouldFilter, inboxId, chatInboxId);
  shouldFilter = filterByTeam(shouldFilter, teamId, chatTeamId);
  shouldFilter = filterByLabel(shouldFilter, labels, chatLabels);
  /* d99d remarked out
  shouldFilter = filterByUnattended(
    shouldFilter,
    conversationType,
    firstReplyOn,
    waitingSince
  );
  */

  // d99d
  shouldFilter = filterByUnattended99(shouldFilter, conversationType, messages);
  // .

  return shouldFilter;
};
