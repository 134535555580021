<template>
  <label class="input-container">
    <span v-if="label">{{ label }}</span>
    <div class="position-relative">
      <input
        class="bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-100 border-slate-200 dark:border-slate-600"
        :class="{ 'input-margin': error }"
        :value="value"
        :type="inputType"
        :placeholder="placeholder"
        :readonly="readonly"
        :style="styles"
        @input="onChange"
        @blur="onBlur"
      />
      <div :class="['absolute inset-y-0 flex items-', directionClasses]">
        <woot-button
          variant="link"
          size="small"
          class="button--visibility bg-white_transparent"
          color-scheme="secondary"
          :icon="toggle ? 'eye-show' : 'eye-hide'"
          @click.prevent="handleEvent"
        />
      </div>
    </div>

    <p v-if="helpText" class="help-text">{{ helpText }}</p>
    <span v-if="error" class="message">
      {{ error }}
    </span>
  </label>
</template>

<script>
import LocaleMixin from '../../../../shared/mixins/localeMixin';

export default {
  mixins: [LocaleMixin],
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inputType: 'password',
      toggle: true,
    };
  },
  computed: {
    directionClasses() {
      if (this.rtl) {
        return 'left-0 pl-3';
      }
      return 'right-0 pr-3';
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', e.target.value);
    },
    onBlur(e) {
      this.$emit('blur', e.target.value);
    },
    handleEvent() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
        this.toggle = false;
      } else {
        this.toggle = true;
        this.inputType = 'password';
      }
    },
  },
};
</script>
<style scoped lang="scss">
.help-text {
  @apply mt-0.5 text-xs not-italic text-slate-600 dark:text-slate-400;
}

.input-margin {
  margin-bottom: 2px !important;
}

.message {
  margin-top: 0 !important;
}
.bg-white_transparent {
  background: none !important;
}
</style>
