<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('INBOX_MGMT.IMPORT_CONVERSATIONS.TITLE')">
          <p>{{ $t('INBOX_MGMT.IMPORT_CONVERSATIONS.DESC') }}</p>
          <div v-if="statusImport" style="margin-top: 1rem;">
            <strong>{{ $t('INBOX_MGMT.IMPORT_CONVERSATIONS.LAST_RESULT_TITLE') }}: </strong>
            <woot-button
                    color-scheme="info"
                    variant="link"
                    icon="ion-android-refresh"
                    @click="fetchImportStatus"
            >
            </woot-button>
            <code class="sub-head hljs " style="white-space: pre; max-height: 20rem">{{statusImportText}}</code>
          </div>
      </woot-modal-header>
      <div class="row modal-content">
        <div class="medium-12 columns">
          <label>
            <span>{{ $t('INBOX_MGMT.IMPORT_CONVERSATIONS.FORM.LABEL') }}</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept=".zip"
              @change="handleFileUpload"
            />
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-button
              :disabled="uiFlags.isCreating || !file"
              :loading="uiFlags.isCreating"
              @click="uploadFile"
            >
              {{ $t('INBOX_MGMT.IMPORT_CONVERSATIONS.FORM.SUBMIT') }}
            </woot-button>
            <button class="button clear" @click.prevent="onClose">
              {{ $t('INBOX_MGMT.IMPORT_CONVERSATIONS.FORM.CANCEL') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    Modal,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    inboxId: {
      type: [String, Number],
      default: 0,
    }
  },
  data() {
    this.fetchImportStatus()
    return {
      show: true,
      file: '',
      statusImportText: undefined,
      statusImport: undefined
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  methods: {
    async uploadFile() {
      try {
        if (!this.file) return;
        await this.$store.dispatch('inboxes/import', {inboxId: this.inboxId, file: this.file});
        this.onClose();
        this.showAlert(this.$t('INBOX_MGMT.IMPORT_CONVERSATIONS.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.IMPORT_CONVERSATIONS.ERROR_MESSAGE')
        );
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    async fetchImportStatus() {
      try {
        const {status, message} = await this.$store.dispatch('inboxes/statusOfLastImport', this.inboxId);
        this.statusImport = status
        this.statusImportText = message
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.IMPORT_CONVERSATIONS.ERROR_MESSAGE')
        );
      }
    }
  },
};
</script>
