<template>
  <div class="card note-wrap private-attach-wrap">
    <div class="header">
      <div class="meta">
        <div class="row">
          <div class="medium-2" v-for="attachment in privateAttachments">
            <all-bubble
                :file="attachment"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AllBubble from '../../components/AllBubble.vue';

export default {
  components: {
    AllBubble,
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      privateAttachments: [],
    };
  },
  mounted() {
    this.fetchPrivateAttachments();
  },
  methods: {
    async fetchPrivateAttachments() {
      const { contactId: id } = this;
      this.privateAttachments = await this.$store.dispatch(
        'contacts/privateAttachments',
        { id }
      );
    },
  },
};
</script>

<style lang="scss">
.private-attach-wrap {
  .file--icon {
    color: var(--w-400);
  }
  .meta {
    .text-block-title {
      color: black;
    }

    h5 {
      font-size: 10px;
    }
    .download {
      color: var(--w-400);
    }
  }
  .modal-container {
    img {
      width: 500px !important;
    }
    video {
      height: 100% !important;
      width: 100% !important;
    }

    .modal--close {
      z-index: 2;
    }
  }
  .location .meta,
  .video {
    width: 100px;
  }
  .message-text__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 100px;
    margin: 10px;

    video,
    img {
      width: 100px;
    }
  }
}
</style>
