<template>
  <div class="small-12 medium-12 columns">
  <form
    class="small-12 medium-4 columns"
    @submit.prevent="submit">
  <woot-input
    v-model.trim="phoneNumber"
    type="number"

    :label="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.PHONE_NUMBER.LABEL')"
    :placeholder="$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.PHONE_NUMBER.PLACEHOLDER')"
  />
      <woot-input
        ref="textInput"
        v-model.trim="text"
        type="text"
        :label="
          $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.TEXT.LABEL')
        "
        :placeholder="
          $t(
            'INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WITH_BUSINESS.TEXT.PLACEHOLDER'
          )
        "
      />

  
    <woot-button
      type="submit"
      :is-disabled="$v.$invalid"
    >
      {{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.GENERATE_SCRIPT_BUTTON') }}
    </woot-button>


  </form>
    <label v-if="script" class="columns medium-12" style="margin-top: 2rem">
      {{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.WA_WIDGET.SCRIPT.LABEL') }}
      <woot-code :script="script" />
    </label>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import contactMixin from "dashboard/mixins/contactMixin";
import { mapGetters } from 'vuex';

export default {
  name: "WaWidget",
  mixins: [contactMixin],
  data() {
    return {
      phoneNumber: undefined,
      text: '',
      script: undefined,
    }
  },
  validations: {
    phoneNumber: {required}
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    account() {
      return this.getAccount(this.accountId);
    },
    iPracticomFeatures() {
      return this.account.platform_features?.iPracticom;
    },
    oneCityFeatures() {
      return this.account.platform_features?.one_city;
    },
    brand() {
      if (this.iPracticomFeatures) {
        return {
          name: 'iPracticom',
          logo: 'ip-com-logo.svg',
          link: 'http://www.ip-com.co.il/',
        };
      }
      if (this.oneCityFeatures) {
        return {
          name: 'One City',
          // logo: '/brand-assets/ip-com-logo.svg',
          link: 'https://www.onecity.co.il/',
        };
      }
      return null;
    },
  },
  methods: {
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        const phone = this.israelPhone2e164(this.phoneNumber)?.replace('+', '')
        this.script = `
     <script>
      (function(d,t) {
        var BASE_URL="${window.location.origin}";
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = BASE_URL+"/packs/js/wa_widget.js";
        var options = {
          baseUrl: BASE_URL,
          phoneNumber:"${phone}",
          startText: "${encodeURIComponent(this.text)}"
          ${this.brand ? `brand: ${JSON.stringify(this.brand)},` : ''}
        };
        s.onload = function() {
          window.onlineWaWidget.run(options);
        };
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      })(document,"script");
    <\/script>
        `
      } catch (e) {
        console.error(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
