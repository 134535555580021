import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/wasettings'),
      name: 'online_wa_home',
      roles: ['administrator'],
      redirect: frontendURL('accounts/:accountId/wasettings/templates'),
    },
    {
      path: frontendURL('accounts/:accountId/wasettings/templates'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'online_wa_templates_list';
        return {
          headerTitle: 'WA_SETTINGS.TEMPLATES.HEADER',
          headerButtonText: 'WA_SETTINGS.TEMPLATES.ADD.HEADER_BTN_TXT',
          icon: 'ion-social-whatsapp',
          newButtonRoutes: ['online_wa_templates_list'],
          showBackButton,
          keepAlive: false,
        };
      },
      children: [
        {
          path: '',
          name: 'online_wa_templates',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'online_wa_templates_list',
          roles: ['administrator'],
          component: () => import(/* webpackChunkName: "online_wa" */ './WaTemplates'),
          children: [

          ]
        },
        {
          path: 'new',
          name: 'online_wa_templates_new',
          roles: ['administrator'],
          component: () => import(/* webpackChunkName: "online_wa" */ './templates/create/Index'),
        },

      ],
    },
    //for the future
    // {
    //   path: frontendURL('accounts/:accountId/wasettings'),
    //   component: SettingsContent,
    //   props: {
    //     headerTitle: 'WA_SETTINGS.PROFILE.HEADER',
    //     icon: 'ion-social-whatsapp',
    //   },
    //   children: [
    //     {
    //       path: 'profile',
    //       name: 'online_wa_profile',
    //       roles: ['administrator'],
    //       component: WaProfile,
    //     },
    //   ],
    // },
    {
      path: frontendURL('accounts/:accountId/wasettings/docs'),
        name: 'online_wa_docs',
        roles: ['administrator'],
        component: () => import(/* webpackChunkName: "online_wa" */ './WaDocs'),
    },
  ],
};
