<template>
  <div v-if="shouldShowNotification" class="fixed rounded-md p-4 notification">
    <label class="notification-text font-bold">{{ notificationMessage }}</label>
    <woot-button
      class="text-white notification-button"
      :disabled="!showActionButton"
      @click="updateAccount"
    >
      {{
        countdown > 0
          ? `${$t('UNPAID_PROFILE_NOTIFICATION.WAIT')} (${countdown})`
          : actionButtonMessage
      }}
    </woot-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import adminMixin from 'dashboard/mixins/isAdmin';
import accountMixin from 'dashboard/mixins/account';
const START_HOUR = 8;
const END_HOUR = 17;
const NOTIFICATION_FREQUENCY = 100; // in minutes
export default {
  mixins: [adminMixin, accountMixin],
  data() {
    return {
      showActionButton: false,
      countdown: 30,
      timer: null,
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      currentUser: 'getCurrentUser',
    }),
    notificationMessage() {
      return this.$t('UNPAID_PROFILE_NOTIFICATION.MESSAGE');
    },
    actionButtonMessage() {
      return this.$t('UNPAID_PROFILE_NOTIFICATION.CONFIRM');
    },
    shouldShowNotification() {
      return this.shouldInterrupt();
    },
  },
  watch: {
    shouldShowNotification(newVal) {
      if (newVal) {
        this.startCountdown();
      } else {
        this.resetCountdown();
      }
    },
  },
  mounted() {
    if (this.shouldShowNotification) {
      this.startCountdown();
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startCountdown() {
      this.countdown = 30;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.showActionButton = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    resetCountdown() {
      this.showActionButton = false;
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    async updateAccount() {
      try {
        await this.$store.dispatch('updateProfile', {
          unpaid_notification_happened: true,
        });
      } catch (error) {
        console.log(error);
      }
    },
    shouldInterrupt() {
      const account = this.getAccount(this.accountId);
      if (!account) return false;

      let startHour = new Date();
      startHour.setHours(START_HOUR, 0, 0, 0); // Set to today at 10:00
      let endHour = new Date();
      endHour.setHours(END_HOUR, 0, 0, 0); // Set to today at 17:00
      // if now between 08:00 and 17:00
      if (new Date() >= startHour && new Date() <= endHour) {
        if (account.unpaid_notification) {
          let interruptionUpdatedAt = new Date(
            this.currentUser.unpaid_notification_timestamp
          );
          let midnight = new Date();
          midnight.setHours(0, 0, 0, 0);
          // was updated yesterday
          if (interruptionUpdatedAt < midnight) {
            return true;
          }
          let oneHourAgo = new Date();
          oneHourAgo.setMinutes(
            oneHourAgo.getMinutes() - NOTIFICATION_FREQUENCY
          );
          // was updated less that hour ago
          if (interruptionUpdatedAt > oneHourAgo) {
            return false;
          }
          if (this.currentUser.unpaid_notification_count < 5) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
<style scoped>
.notification {
  background-color: #ee5a4b;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
  z-index: 9999;
}
.notification-text {
  white-space: pre-line;
  text-align: center;
  color: #ffffff;
  font-size: large;
  position: relative;
}
.notification-button {
  margin-top: 10px;
  font-size: large;
  position: relative;
  background: #405261 !important;
}
</style>
