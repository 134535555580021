<template>
  <bubble-image
    v-if="file.file_type === 'image' && !hasImageError"
    :url="file.data_url"
    @error="onImageLoadError"
  />
  <audio
    v-else-if="file.file_type === 'audio'"
    controls
    class="skip-context-menu w-full"
  >
    <source :src="file.data_url" />
  </audio>
  <bubble-video
    v-else-if="file.file_type === 'video'"
    :url="file.data_url"
  />
  <bubble-location
    v-else-if="file.file_type === 'location'"
    :latitude="file.coordinates_lat"
    :longitude="file.coordinates_long"
    :name="file.fallback_title"
  />
  <bubble-contact
    v-else-if="file.file_type === 'contact'"
    name=""
    :phone-number="file.fallback_title"
  />
  <bubble-file v-else :url="file.data_url" />
</template>
<script>
import { mapGetters } from 'vuex';
import BubbleImage from 'dashboard/components/widgets/conversation/bubble/Image';
import BubbleLocation from 'dashboard/components/widgets/conversation/bubble/Location';
import BubbleContact from 'dashboard/components/widgets/conversation/bubble/Contact';
import BubbleFile from 'dashboard/components/widgets/conversation/bubble/File';
import BubbleVideo from 'dashboard/components/widgets/conversation/bubble/Video';
import contentTypeMixin from 'shared/mixins/contentTypeMixin';

export default {
  components: {
    BubbleVideo,
    BubbleFile,
    BubbleContact,
    BubbleLocation,
    BubbleImage,
  },
  mixins: [contentTypeMixin],
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasImageError: false,
    };
  },
  computed: {
    ...mapGetters({
      getSelectedChat: 'getSelectedChat',
    }),
  },
  methods: {
    onImageLoadError() {
      this.hasImageError = true;
    },
  },
};
</script>
