const buildcustomizeBold = () => (state, silent) => {
  if (silent) { return false; }

  const token = state.src;
  let pos = state.pos;

  if (token[pos] === '*') {
    let count = 1;
    const max = state.posMax;
    while (++pos < max) {
      if (token[pos] === '*') {
        count++;
        break;
      }
    }

    if (count === 2) {
      const isItalInside = state.pos < max && token[state.pos+1] === '_' && token[pos-1] === '_';
      const tokenContent = isItalInside ? token.slice(state.pos + 2, pos-1) : token.slice(state.pos + 1, pos);
      const strongToken = state.push('strong_open', 'strong', 1);

      if (isItalInside) state.push('em_open', 'em', 1);

      const strongContentToken = state.push('text', '', 0);
      strongContentToken.content = tokenContent;

      if (isItalInside) state.push('em_close', 'em', -1);

      const strongCloseToken = state.push('strong_close', 'strong', -1);

      state.pos = pos + 1;

      return true;
    }
  }

  return false;
};

export default function customizeBoldPlugin(md) {
  md.inline.ruler.before('emphasis', 'customizeBold', buildcustomizeBold(md));
}
