import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import RoleAPI from '../../api/roles';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getRoles(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getRoles({ commit }) {
    commit(types.SET_ROLE_UI_FLAG, { isFetching: true });
    try {
      const response = await RoleAPI.get();
      commit(types.SET_ROLES, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ROLE_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createRole({ commit }, roleObj) {
    commit(types.SET_ROLE_UI_FLAG, { isCreating: true });
    try {
      const response = await RoleAPI.create(roleObj);
      commit(types.ADD_ROLE, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ROLE_UI_FLAG, { isCreating: false });
    }
  },
  update: async function updateRole({ commit }, { id, ...updateObj }) {
    commit(types.SET_ROLE_UI_FLAG, { isUpdating: true });
    try {
      const response = await RoleAPI.update(id, updateObj);
      commit(types.EDIT_ROLE, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ROLE_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async function deleteRole({ commit }, id) {
    commit(types.SET_ROLE_UI_FLAG, { isDeleting: true });
    try {
      await RoleAPI.delete(id);
      commit(types.DELETE_ROLE, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_ROLE_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_ROLE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_ROLE]: MutationHelpers.create,
  [types.SET_ROLES]: MutationHelpers.set,
  [types.EDIT_ROLE]: MutationHelpers.update,
  [types.DELETE_ROLE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};