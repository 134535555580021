<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div ref="parent" v-on-clickaway="hideContactList" class="relative">
    <input
      ref="input"
      v-model="inputValue"
      type="text"
      :placeholder="
        placeholder || $t('CONTACT_FORM.FORM.PHONE_NUMBER.PLACEHOLDER')
      "
      class="mb-0"
      @focus="showContactList"
    />
    <woot-dropdown-menu
      v-if="searchContacts.length && showContacts"
      class="absolute bg-white w-full rounded-b border border-solid border-slate-200 dark:border-slate-700 max-h-72 overflow-auto divide-y divide-slate-200"
    >
      <!-- Do not replace v-show with computed property, clickaway won't work properly  -->
      <woot-dropdown-item
        v-for="contact in searchContacts"
        v-show="!alreadySelected.includes(contact.email)"
        :key="contact.id"
        class="!mb-0"
      >
        <woot-button
          variant="hollow"
          color-scheme="secondary"
          class="w-full px-2.5 border-none rounded-none h-16 truncate"
          @click="onSelected(contact)"
        >
          <contact-dropdown-item
            :thumbnail="contact.thumbnail"
            :identifier="contact.id"
            :name="contact.name"
            :phone-number="contact.phone_number"
            :email="contact.email"
            :company-name="contact.additional_attributes.company_name"
          />
        </woot-button>
      </woot-dropdown-item>
    </woot-dropdown-menu>
  </div>
</template>

<script>
import ContactAPI from 'dashboard/api/contacts';
import { mixin as clickaway } from 'vue-clickaway';
import ContactDropdownItem from './ContactDropdownItem.vue';

export default {
  components: { ContactDropdownItem },
  mixins: [clickaway],
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      default: 'phone_number',
    },
    skipSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRequested: false,
      showContacts: false,
      searchContacts: [],
    };
  },
  computed: {
    inputValue: {
      get() {
        if (this.field == 'phone_number') return this.phoneNumber;
        return this.email;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    alreadySelected() {
      return this.email
        .replaceAll(',', ' ')
        .split(' ')
        .filter(val => val);
    },
  },
  watch: {
    phoneNumber(newVal) {
      this.getContacts(newVal);
    },
    email(newVal) {
      this.getContacts(this.emailQuery(newVal));
    },
  },
  methods: {
    emailQuery(newVal) {
      return this.multiple ? newVal.split(',').slice(-1)[0] : newVal;
    },
    async getContacts(query) {
      this.isRequested = true;
      try {
        const {
          data: { payload },
        } = await ContactAPI.search(query, 1, 'name', '', this.field);
        this.searchContacts = payload;
      } catch (error) {
        console.log(error);
        this.showAlert(this.$t('MERGE_CONTACTS.SEARCH.ERROR_MESSAGE'));
      }
    },
    onSelected(contact) {
      this.$emit('on-select-contact', contact);
      if (this.multiple) {
        this.$refs.input.focus();
        return;
      }
      this.showContacts = false;
    },
    showContactList() {
      this.showContacts = true;
      this.getContacts(this.phoneNumber || this.emailQuery(this.email));
    },
    hideContactList() {
      this.showContacts = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
