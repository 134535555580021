<template>
  <settings-section
    :title="$t('INBOX_MGMT.GMAIL_REAUTHORIZE.TITLE')"
    :sub-title="$t('INBOX_MGMT.GMAIL_REAUTHORIZE.SUBTITLE')"
  >
    <div class="smtp-details-wrap">
      <form @submit.prevent="requestAuthorization">
        <woot-submit-button
          icon="brand-twitter"
          :button-text="$t('INBOX_MGMT.ADD.GMAIL.BUTTON_CONTINUE_TITLE')"
          type="submit"
          :loading="isRequestingAuthorization"
        />
      </form>
    </div>
  </settings-section>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import gmailClient from 'dashboard/api/channel/gmailClient';
import SettingsSection from '../../../../../components/SettingsSection.vue';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return { isRequestingAuthorization: false };
  },
  methods: {
    async requestAuthorization() {
      try {
        this.isRequestingAuthorization = true;
        const response = await gmailClient.generateAuthorization();
        const {
          data: { url },
        } = response;
        window.location.href = url;
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.GMAIL.ERROR_MESSAGE'));
      } finally {
        this.isRequestingAuthorization = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.smtp-details-wrap {
  margin-bottom: var(--space-medium);
}
</style>
