<template>
  <div class="input-radio-list">
    <div class="radio-group">
      <div v-for="item in items" :key="itemKey(item)" class="radio-group-item">
        <div class="input-wrap">
          <input
            :id="itemKey(item)"
            :name="`${name} -radio-input`"
            type="radio"
            :checked="item.checked"
            class="text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            @change="onChange({ ...item, checked: true })"
          />
        </div>
        <label :for="itemKey(item)" class="radio-item-content">
          <span class="text-gray-900 dark:text-gray-300">{{ itemLabel(item) }}</span>
          <p
            :id="itemKey(item) + '-text'"
            class="desc text-gray-500 dark:text-gray-300"
          >
            {{ itemDesc(item) }}
          </p>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '../../../v3/components/Form/Input.vue';

export default {
  components: { Input },
  props: {
    name: {
      type: String,
      default: 'string',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    itemKey(item) {
      return item.key || item.id || this.itemLabel(item);
    },
    itemLabel(item) {
      return item.label || item.title || item.name;
    },
    itemDesc(item) {
      return item.description || item.desc;
    },
    onChange(item) {
      this.$emit('change', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-radio-list {
  .radio-group-item {
    @apply flex mb-3;
  }
  .input-wrap {
    @apply flex items-center h-5;
    input {
      @apply w-4 h-4 focus:ring-2 mt-1 mb-0 cursor-pointer;
    }
  }
  label {
    @apply ms-2 cursor-pointer;
    span {
      @apply font-medium;
    }
    p.desc {
      @apply text-sm font-normal mt-0;
    }
  }
}
</style>
