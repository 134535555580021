/* eslint arrow-body-style: 0 */
import Index from "./Index";
import {frontendURL} from "dashboard/helper/URLHelper";
import SettingsContent from "../settings/Wrapper";

export default [
  {
    path: frontendURL('accounts/:accountId/changelog'),
    roles: ['administrator', 'agent'],
    component: SettingsContent,
    props: {
      headerTitle: 'SIDEBAR.CHANGELOG',
      icon: 'ion-information-circled',
      showNewButton: false,
    },
    children: [
      {
        path: '',
        name: 'changelog_index',
        component: Index,
        props: {descriptionKey: 'CHANGELOG_DESCRIPTION'},
        roles: ['administrator', 'agent'],
      },
    ]
  },
  {
    path: frontendURL('accounts/:accountId/help'),
    roles: ['administrator', 'agent'],
    component: SettingsContent,
    props: {
      headerTitle: 'SIDEBAR.HElP_AND_SUPPORT',
      icon: 'ion-help',
      showNewButton: false,
    },
    children: [
      {
        path: '',
        name: 'help_home',
        component: Index,
        props: {descriptionKey: 'HELP_DESCRIPTION'},
        roles: ['administrator', 'agent'],
      },
    ]
  },
]
