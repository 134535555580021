<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('FACEBOOK2.PRIVATE_REPLY.TITLE')"
        :header-content="$t('FACEBOOK2.PRIVATE_REPLY.DESC')"
      />
      <form class="row" @submit.prevent="sendMessage">
        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('FACEBOOK2.PRIVATE_REPLY.MESSAGE.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="false"
              :enable-canned-responses="false"
              :placeholder="$t('FACEBOOK2.PRIVATE_REPLY.MESSAGE.PLACEHOLDER')"
              @blur="$v.content.$touch"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                  messageSent.showLoading
              "
              :button-text="$t('FACEBOOK2.PRIVATE_REPLY.SUBMIT')"
              :loading="messageSent.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('FACEBOOK2.PRIVATE_REPLY.CANCEL') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from 'components/buttons/FormSubmitButton';
import Modal from 'components/Modal';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';
import OnlineFbAPI from '../../../api/onlinefb';

export default {
  components: {
    WootSubmitButton,
    Modal,
    WootMessageEditor,
  },
  mixins: [alertMixin],
  props: {
    message: {
      type: Object,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shortCode: '',
      content: this.responseContent || '',
      messageSent: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    content: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.content = '';
      this.$v.content.$reset();
    },
    async sendMessage() {
      this.messageSent.showLoading = true;
      try {
        const { inbox_id, source_id } = this.message;
        await OnlineFbAPI.sendPrivateReply({inbox_id, source_id, message: this.content})

        this.messageSent.showLoading = false;
        this.showAlert(this.$t('FACEBOOK2.PRIVATE_REPLY.API.SUCCESS_MESSAGE'));
        this.resetForm();
        this.onClose();

      } catch (error) {
        this.messageSent.showLoading = false;
        this.showAlert(this.$t('FACEBOOK2.PRIVATE_REPLY.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }

  .ProseMirror-woot-style {
    min-height: 20rem;

    p {
      font-size: var(--font-size-default);
    }
  }

  .message-editor {
    border: 1px solid var(--s-200);
  }
}
</style>
