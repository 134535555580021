<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <settings-sub-page-header
      :header-title="$t('INBOX_MGMT.ADD.GMAIL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.GMAIL.DESCRIPTION')"
    />
    <form
      class="gmail--sign-in-form"
      @submit.prevent="requestAuthorization"
    >
      <woot-submit-button
        icon="brand-gmail"
        :button-text="$t('INBOX_MGMT.ADD.GMAIL.BUTTON_CONTINUE_TITLE')"
        type="submit"
        :loading="isRequestingAuthorization"
      />
    </form>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import gmailClient from 'dashboard/api/channel/gmailClient';
import SettingsSubPageHeader from '../../../SettingsSubPageHeader.vue';

export default {
  components: { SettingsSubPageHeader },
  mixins: [alertMixin],
  data() {
    return { isRequestingAuthorization: false, withConsent: false};
  },
  methods: {
    async requestAuthorization() {
      try {

        this.isRequestingAuthorization = true;
        const response = await gmailClient.generateAuthorization({});
        const {
          data: { url },
        } = response;
        window.location.href = url;
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.GMAIL.ERROR_MESSAGE'));
      } finally {
        this.isRequestingAuthorization = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gmail--sign-in-form {
  @apply mt-6;
}
</style>
