import { utcToZonedTime } from 'date-fns-tz';
import { isTimeAfter } from 'shared/helpers/DateHelper';

export default {
  computed: {
    workingHoursEnabled() {
      return !!this.inbox.working_hours_enabled;
    },
    workingHours() {
      return this.inbox.working_hours;
    },
    timezone() {
      return this.inbox.timezone;
    },
    isInBetweenTheWorkingHours() {
      const {
        openHour,
        openMinute,
        closeHour,
        closeMinute,
        closedAllDay,
        openAllDay,
      } = this.currentDayAvailability;
      if (openAllDay) {
        return true;
      }

      if (closedAllDay) {
        return false;
      }

      const today = this.getDateWithOffset(this.timezone);
      const currentHours = today.getHours();
      const currentMinutes = today.getMinutes();
      const isAfterStartTime = isTimeAfter(
        currentHours,
        currentMinutes,
        openHour,
        openMinute
      );
      const isBeforeEndTime = isTimeAfter(
        closeHour,
        closeMinute,
        currentHours,
        currentMinutes
      );
      return isAfterStartTime && isBeforeEndTime;
    },
    currentDayAvailability() {
      const dayOfTheWeek = this.getDateWithOffset(this.timezone).getDay();
      const [workingHourConfig = {}] = this.workingHours.filter(
        workingHour => workingHour.day_of_week === dayOfTheWeek
      );
      return {
        closedAllDay: workingHourConfig.closed_all_day,
        openHour: workingHourConfig.open_hour,
        openMinute: workingHourConfig.open_minutes,
        closeHour: workingHourConfig.close_hour,
        closeMinute: workingHourConfig.close_minutes,
        openAllDay: workingHourConfig.open_all_day,
      };
    },
    isInBusinessHours() {
      return this.workingHoursEnabled ? this.isInBetweenTheWorkingHours : true;
    },
  },

  methods: {
    getDateWithOffset(utcOffset) {
      return utcToZonedTime(new Date().toISOString(), utcOffset);
    },
  },
};
