/* global axios */

import ApiClient from '../ApiClient';

class BuilderAPI extends ApiClient {
  constructor() {
    super('integrations/builder', { accountScoped: true });
  }

  getLoginLink(conversationId) {
    return axios.get(`${this.url}/url`);
  }
}

export default new BuilderAPI();
