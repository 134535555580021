/* global axios */
import ApiClient from './ApiClient';

class DescriptionAPI extends ApiClient {
  constructor() {
    super('description', { accountScoped: true });
  }

  show(id) {
    return axios.get(`${this.url}`, {params: {descr_key: id}});
  }
}

export default new DescriptionAPI;
