<template>
  <form
    class="conversation--form"
    @submit.prevent="onFormSubmit"
    @keydown="handleFormKeydown"
  >
    <div>
      <div class="row">
        <div class="columns">
          <label :class="{ error: $v.targetInbox.$error }">
            {{ $t('NEW_CONVERSATION.FORM.INBOX.LABEL') }}
            <select v-model="targetInbox">
              <option
                v-for="inbox in inboxes"
                :key="inbox.id"
                :value="inbox"
              >
                {{ inbox.name }}
              </option>
            </select>
            <span v-if="$v.targetInbox.$error" class="message">
              {{ $t('NEW_CONVERSATION.FORM.INBOX.ERROR') }}
            </span>
          </label>
        </div>
        <div v-if="(isDraft || !contact.phone_number) && !isByEmail" class="columns" style="margin-inline-start: 1rem; flex: 0.8;">
          <label :class="{ error: $v.contactInner.phone_number.$error }">
            {{$t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL')}}
            <contact-input-search
              :phone-number.sync="contactInner.phone_number"
              @on-select-contact="onSelectContact"
              @input="setPhoneNumber"
              field="phone_number"
            />
            <span v-if="$v.contactInner.phone_number.$error" class="message">
              {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.ERROR') }}
            </span>
          </label>
        </div>
        <div v-if="isByEmail" class="columns" style="margin-inline-start: 1rem; flex: 0.8;">
          <label :class="{ error: $v.contactInner.email.$error }">
            {{$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL')}}
            <contact-input-search
              :email.sync="contactInner.email"
              @on-select-contact="onSelectContactEmail"
              @input="setEmail"
              field="email"
            />
            <span v-if="$v.contactInner.email.$error" class="message">
              {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.ERROR') }}
            </span>
          </label>
        </div>
        <div class="columns" style="margin-inline-start: 1rem;flex: 1.2;">
          <label>
            {{ contact.phone_number ? $t('NEW_CONVERSATION.FORM.TO.LABEL') : $t('CONTACT_FORM.FORM.NAME.LABEL') }}
            <input
              v-model.trim="contactInner.name"
              type="text"
              :placeholder="$t('CONTACT_FORM.FORM.NAME.PLACEHOLDER')"
            />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="columns">
          <div class="canned-response">
            <canned-response
              v-if="showCannedResponseMenu && hasSlashCommand"
              :search-key="cannedResponseSearchKey"
              @click="replaceTextWithCannedResponse"
            />
          </div>
          <online-wa-templates
            v-if="isOnlineWaInboxV2"
            :inbox-id="targetInbox.id"
            @on-select-template="toggleWaTemplate"
            @on-send="onSendWhatsAppReply"
          />
          <whatsapp-templates
            v-else-if="hasWhatsappTemplates"
            :inbox-id="targetInbox.id"
            @on-select-template="toggleWaTemplate"
            @on-send="onSendWhatsAppReply"
          />
          <div v-else-if="isAnEmailChannel">
            <div class="w-full">
              <label :class="{ error: $v.subject.$error }">
                {{ $t('NEW_CONVERSATION.FORM.SUBJECT.LABEL') }}
                <input
                  v-model="subject"
                  type="text"
                  :placeholder="$t('NEW_CONVERSATION.FORM.SUBJECT.PLACEHOLDER')"
                  @input="$v.subject.$touch"
                />
                <span v-if="$v.subject.$error" class="message">
                    {{ $t('NEW_CONVERSATION.FORM.SUBJECT.ERROR') }}
                </span>
              </label>
            </div>
            <div class="w-full">
                <label>
                  {{ $t('NEW_CONVERSATION.FORM.MESSAGE.LABEL') }}
                </label>
                <reply-email-head
                  :cc-emails.sync="ccEmails"
                  :bcc-emails.sync="bccEmails"
                />
                <div class="editor-wrap">
                  <woot-message-editor
                    v-model="message"
                    class="message-editor"
                    :class="{ editor_warning: $v.message.$error }"
                    :enable-variables="true"
                    :signature="signatureToApply"
                    :allow-signature="true"
                    :placeholder="$t('NEW_CONVERSATION.FORM.MESSAGE.PLACEHOLDER')"
                    @toggle-canned-menu="toggleCannedMenu"
                    @blur="$v.message.$touch"
                  >
                    <template #footer>
                      <message-signature-missing-alert
                        v-if="isSignatureEnabledForInbox && !messageSignature"
                        class="!mx-0 mb-1"
                      />
                      <div class="mb-3 mt-px">
                        <woot-button
                          v-tooltip.top-end="signatureToggleTooltip"
                          icon="signature"
                          color-scheme="secondary"
                          variant="smooth"
                          size="small"
                          :title="signatureToggleTooltip"
                          @click.prevent="toggleMessageSignature"
                        />
                      </div>
                    </template>
                  </woot-message-editor>
                  <span v-if="$v.message.$error" class="editor-warning__message">
                {{ $t('NEW_CONVERSATION.FORM.MESSAGE.ERROR') }}
              </span>
              </div>
            </div>
          </div>
          <label v-else :class="{ error: $v.message.$error }">
            {{ $t('NEW_CONVERSATION.FORM.MESSAGE.LABEL') }}
            <textarea
              v-model="message"
              class="message-input"
              type="text"
              :placeholder="$t('NEW_CONVERSATION.FORM.MESSAGE.PLACEHOLDER')"
              @input="$v.message.$touch"
            />
            <span v-if="$v.message.$error" class="message">
              {{ $t('NEW_CONVERSATION.FORM.MESSAGE.ERROR') }}
            </span>
          </label>
          <div v-if="isEmailOrWebWidgetInbox" class="flex flex-col">
            <file-upload
              ref="uploadAttachment"
              input-id="newConversationAttachment"
              :size="4096 * 4096"
              :accept="allowedFileTypes"
              :multiple="true"
              :drop="true"
              :drop-directory="false"
              :data="{
                direct_upload_url: '/rails/active_storage/direct_uploads',
                direct_upload: true,
              }"
              @input-file="onFileUpload"
            >
              <woot-button
                class-names="button--upload"
                icon="attach"
                emoji="📎"
                color-scheme="secondary"
                variant="smooth"
                size="small"
              >
                {{ $t('NEW_CONVERSATION.FORM.ATTACHMENTS.SELECT') }}
              </woot-button>
              <span
                class="text-slate-500 ltr:ml-1 rtl:mr-1 font-medium text-xs dark:text-slate-400"
              >
                {{ $t('NEW_CONVERSATION.FORM.ATTACHMENTS.HELP_TEXT') }}
              </span>
            </file-upload>
            <div
              v-if="hasAttachments"
              class="max-h-20 overflow-y-auto mb-4 mt-1.5"
            >
              <attachment-preview
                class="[&>.preview-item]:dark:bg-slate-700 flex-row flex-wrap gap-x-3 gap-y-1"
                :attachments="attachedFiles"
                :remove-attachment="removeAttachment"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hasWhatsappTemplates" class="modal-footer">
      <button v-if="!isSingleWindow" class="button clear" @click.prevent="onCancel">
        {{ $t('NEW_CONVERSATION.FORM.CANCEL') }}
      </button>
      <woot-button type="submit" :is-loading="conversationsUiFlags.isCreating" :is-disabled="!isAbleToSendMsg">
        {{ $t('NEW_CONVERSATION.FORM.SUBMIT') }}
      </woot-button>
    </div>
    <div class="row">
      <label>
        <input
          v-model="isAssigneeMe"
          type="checkbox"
        />
        {{ $t('NEW_CONVERSATION.FORM.ASSIGNEE_ME_LABEL') }}
      </label>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import Thumbnail from 'dashboard/components/widgets/Thumbnail';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import ReplyEmailHead from 'dashboard/components/widgets/conversation/ReplyEmailHead';
import CannedResponse from 'dashboard/components/widgets/conversation/CannedResponse.vue';
import WhatsappTemplates from 'dashboard/routes/dashboard/conversation/contact/WhatsappTemplates.vue';
import ContactInputSearch from '../../modules/contact/components/ContactInputSearch.vue';
import alertMixin from 'shared/mixins/alertMixin';
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import { required, requiredIf } from 'vuelidate/lib/validators';
import inboxMixin from "shared/mixins/inboxMixin";
import OnlineWaTemplates from "dashboard/components/widgets/conversation/watemplates/OnlineWaTemplates.vue";
import agentMixin from "dashboard/mixins/agentMixin";
import {isPhoneIsrael} from "../../../shared/helpers/Validators";
import contactMixin from "../../mixins/contactMixin";
import hasPermissionMixin from 'dashboard/mixins/hasPermission';
import MessageSignatureMissingAlert from 'dashboard/components/widgets/conversation/MessageSignatureMissingAlert';
import {
  appendSignature,
  removeSignature,
} from 'dashboard/helper/editorHelper';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import {validEmailsByComma} from "../../components/widgets/conversation/helpers/emailHeadHelper";
import FileUpload from 'vue-upload-component';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';
import { ALLOWED_FILE_TYPES } from 'shared/constants/messages';
import fileUploadMixin from 'dashboard/mixins/fileUploadMixin';
import InboxDropdownItem from 'dashboard/components/widgets/InboxDropdownItem.vue';

export default {
  components: {
    ContactInputSearch,
    OnlineWaTemplates,
    Thumbnail,
    WootMessageEditor,
    ReplyEmailHead,
    CannedResponse,
    WhatsappTemplates,
    InboxDropdownItem,
    FileUpload,
    AttachmentPreview,
    MessageSignatureMissingAlert,
  },
  mixins: [alertMixin, inboxMixin, agentMixin, contactMixin, uiSettingsMixin, fileUploadMixin, hasPermissionMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
    singleInbox: {
      type: Object,
      default: undefined,
    },
    isSingleWindow: {
      type: Boolean,
      default: true,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      subject: '',
      message: '',
      showCannedResponseMenu: false,
      cannedResponseSearchKey: '',
      selectedInbox: '',
      bccEmails: '',
      ccEmails: '',
      attachedFiles: [],
      messagePayload: undefined,
      isAssigneeMe: true,
      whatsappTemplateSelected: false,
      contactInner: {phone_number: this.contact?.phone_number,
        email: this.contact?.email,
        name: this.contact?.name || this.contact?.phone_number?.replace(/\+/, '')},
    };
  },
  validations: {
    subject: {
      required: requiredIf('isAnEmailChannel'),
    },
    message: {
      requiredIfPresented: requiredIf(function () {
        return !this.isOnlineWaInboxV2
      }),
    },
    targetInbox: {
      required,
    },
    contactInner: {
      phone_number: {
        isPhoneIsrael (val ) { return isPhoneIsrael(val) || this.isAnEmailChannel },
      },
      email: {
        required: requiredIf(function(){return this.isAnEmailChannel}),
        hasValidEmails(value) {
          return validEmailsByComma(value || '');
        },
      }
    },
  },
  created() {
    this.setDefaultInbox()
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      conversationsUiFlags: 'contactConversations/getUIFlags',
      currentUser: 'getCurrentUser',
      globalConfig: 'globalConfig/get',
      messageSignature: 'getMessageSignature',
    }),
    emailMessagePayload() {
      const payload = {
        inboxId: this.targetInbox.id,
        contact: this.normalisedContact(),
        message: { content: this.message },
        assigneeId: (this.isAssigneeMe ? this.currentUser.id : undefined),
      };
      if (this.isAnEmailChannel) {
        payload.contact.phone_number = undefined;
        payload.mailSubject = this.subject;
        payload.newConversation = true;

        if (this.ccEmails) {
          payload.message.cc_emails = this.ccEmails;
        }

        if (this.bccEmails) {
          payload.message.bcc_emails = this.bccEmails;
        }

        if (this.attachedFiles && this.attachedFiles.length) {
          payload.files = [];
          this.setAttachmentPayload(payload);
        }
      } else {
        payload.contact.email = undefined;
        payload.files = [];
      }
      return payload;
    },
    inboxes() {
      return this.$store.getters['inboxes/getInboxes'].filter(
        inbox =>
          this.hasInboxPermission('Inbox new message', inbox.id) &&
          this.isValidInbox(inbox) &&
          (inbox.channel_type !== 'Channel::Email' || !this.contact?.phone_number) &&
          (!this.isDraft || this.contact?.phone_number || inbox.channel_type === 'Channel::Email') // only show email inboxes if from email links
      ); // todo create some global helper
    },
    targetInbox: {
      get() {
        return this.selectedInbox || {};
      },
      set(value) {
        this.selectedInbox = value;
      },
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    isAnEmailInbox() {
      return this.isAnEmailChannel;
    },
    isEmailOrWebWidgetInbox() { // for the future
      return this.isAnEmailInbox || false;
    },
    isSignatureEnabledForInbox() {
      return this.isAnEmailInbox && this.sendWithSignature;
    },
    inbox() {
      return this.targetInbox || {};
    },
    hasWhatsappTemplates() {
      return !!this.targetInbox?.message_templates
        || this.isOnlineWaInboxV2 // d99d
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    signatureToApply() {
      return this.messageSignature;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    isByEmail() {
      return this.isAnEmailChannel || (!this.contact?.phone_number && this.contact?.email);
    },
    isAbleToSendMsg() {
      return this.inboxes.length > 0; // this.targetInbox
    },
  },
  watch: {
    message(value) {
      this.hasSlashCommand = value[0] === '/' && !this.isEmailOrWebWidgetInbox;
      const hasNextWord = value.includes(' ');
      const isShortCodeActive = this.hasSlashCommand && !hasNextWord;
      if (isShortCodeActive) {
        this.cannedResponseSearchKey = value.substring(1);
        this.showCannedResponseMenu = true;
      } else {
        this.cannedResponseSearchKey = '';
        this.showCannedResponseMenu = false;
      }
    },
    inboxes() {
      this.setDefaultInbox()
    },
  },
  mounted() {
    this.setFocusToFirstInput();
  },
  methods: {
    isValidInbox(inbox) {
      return (
        (inbox.api_type && inbox.api_type.charAt(0) === 'w') ||
        (inbox.channel_type === 'Channel::Sms' && inbox.provider === '99digital') ||
        (inbox.channel_type === 'Channel::Email' && inbox.api_type?.length)
      );
    },
    handleFormKeydown(event) {
      if (event.key === 'Enter') {
        if (document.activeElement.type !== 'submit') {
          event.preventDefault();
        }
      }
    },
    setDefaultInbox() {
      if (this.inboxes.length && !this.targetInbox.id) {
        if (
          this.singleInbox?.id &&
          this.inboxes.some(x => x.id === this.singleInbox.id)
        ) {
          this.targetInbox = this.singleInbox;
        } else {
          this.targetInbox = this.defaultInboxForContact();
        }
      }
    },
    defaultInboxForContact() {
      let inbox;
      if (this.contact.phone_number) {
        inbox = this.inboxes.find(x => x.api_type?.charAt(0) === 'w');
        inbox ||= this.inboxes.find(x => x.channel_type === 'Channel::Sms');
      }
      if (this.contact.email) {
        inbox ||= this.inboxes.find(x => x.channel_type === 'Channel::Email');
      }
      return inbox || this.inboxes[0];
    },
    setFocusToFirstInput() {
      this.$nextTick(() => {
        const submitButton = this.$el.querySelector('.button.primary');
        if (submitButton) {
          submitButton.setAttribute('tabindex', '-1');
          submitButton.focus();
        }
      });
    },
    setAttachmentPayload(payload) {
      this.attachedFiles.forEach(attachment => {
        if (this.globalConfig.directUploadsEnabled) {
          payload.files.push(attachment.blobSignedId);
        } else {
          payload.files.push(attachment.resource.file);
        }
      });
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          currentChatId: this.contact.id,
          resource: blob || file,
          isPrivate: this.isPrivate,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    onCancel() {
      this.$emit('cancel');
    },
    replaceTextWithCannedResponse(message) {
      setTimeout(() => {
        this.message = message;
      }, 50);
    },
    prepareWhatsAppMessagePayload({ message: content, templateParams, ...rest /* d99d */ }) {
      const payload = {
        inboxId: this.targetInbox.id,
        contact: this.normalisedContact(),
        message: { content, template_params: templateParams, ...rest /* d99d */ },
        assigneeId: (this.isAssigneeMe ? this.currentUser.id : undefined),
      };
      return payload;
    },
    onFormSubmit() {
      this.createConversation(this.emailMessagePayload);
    },
    async createConversation(payload) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const data = await this.onSubmit({ params: payload, isFromWhatsApp: !this.isAnEmailChannel });
        const action = this.isSingleWindow ? undefined : {
          type: 'link',
          to: `/app/accounts/${data.account_id}/conversations/${data.id}`,
          message: this.$t('NEW_CONVERSATION.FORM.GO_TO_CONVERSATION'),
        };
        this.showAlert(
          this.$t('NEW_CONVERSATION.FORM.SUCCESS_MESSAGE'),
          action
        );
      } catch (error) {
        console.error(error)
        if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data);
        } else {
          this.showAlert(this.$t('NEW_CONVERSATION.FORM.ERROR_MESSAGE'));
        }
      }
    },

    toggleWaTemplate(val) {
      this.whatsappTemplateSelected = val;
    },
    async onSendWhatsAppReply(messagePayload) {
      const payload = this.prepareWhatsAppMessagePayload(messagePayload);
      await this.createConversation(payload);
    },
    normalisedContact() {
      const {phone_number, name, email} = this.contactInner
      return {phone_number: this.israelPhone2e164(phone_number), name, email}
    },

    toggleCannedMenu(value) {
      //this.showCannedMenu = value; not implemented here
    },
    toggleMessageSignature() {
      this.updateUISettings({
        send_with_signature: !this.sendWithSignature,
      });
      this.setSignature();
    },
    setSignature() {
      if (this.messageSignature) {
        if (this.isSignatureEnabledForInbox) {
          this.message = appendSignature(this.message, this.signatureToApply);
        } else {
          this.message = removeSignature(this.message, this.signatureToApply);
        }
      }
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
    onSelectContact(contact) {
      this.contactInner.phone_number = contact.phone_number;
      this.contactInner.name = contact.name;
    },
    onSelectContactEmail(contact) {
      this.contactInner.email = contact.email;
      this.contactInner.name = contact.name;
    },
    setPhoneNumber(value) {
      this.contactInner.phone_number = value;
    },
    setEmail(value) {
      this.contactInner.email = value;
    },
  },
};
</script>

<style scoped lang="scss">
.conversation--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);
}

.canned-response {
  position: relative;
}

.input-group-label {
  font-size: var(--font-size-small);
}

.contact-input {
  display: flex;
  align-items: center;
  height: 3.9rem;
  background: var(--color-background-light);

  border: 1px solid var(--color-border);
  padding: var(--space-smaller) var(--space-small);
  border-radius: var(--border-radius-small);

  .contact-name {
    margin: 0;
    margin-left: var(--space-small);
  }
}

.message-input {
  min-height: 8rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}
.row.gutter-small {
  gap: var(--space-small);
}

::v-deep .mention--box {
  left: 0;
  margin: auto;
  right: 0;
  top: unset;
  height: fit-content;
}

.file-uploads {
  @apply text-start;
}
.multiselect-wrap--small.has-multi-select-error {
  ::v-deep {
    .multiselect__tags {
      @apply border-red-500;
    }
  }
}
</style>
