function strikethroughPlugin(md) {
  function strikethroughReplace(state, silent) {
    if (silent) { return false; }
    const delim = '~';
    const token = state.src;
    let pos = state.pos;

    if (token[pos] === delim) {
      let count = 1;
      const max = state.posMax;
      while (++pos < max) {
        if (token[pos] === delim) {
          count++;
          break;
        }
      }

      if (count === 2) {

        const tokenContent = token.slice(state.pos + 1, pos);
        const strongToken = state.push('s_open', 's', 1);
        strongToken.markup = delim;

        const strongContentToken = state.push('text', '', 0);
        strongContentToken.content = tokenContent;

        const strongCloseToken = state.push('s_close', 's', -1);
        strongCloseToken.markup = delim;

        state.pos = pos + 1;

        return true;
      }
    }

    return false;
  }

  md.inline.ruler.push('strikethrough', strikethroughReplace);
}

export default strikethroughPlugin;
