<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <ninja-keys
    ref="ninjakeys"
    :no-auto-load-md-icons="true"
    hideBreadcrumbs
    :placeholder="placeholder"
    @selected="onSelected"
  >
<!--    d99d hid because not translated-->
    <div slot="footer" style="height: 1rem"></div>
  </ninja-keys>
</template>

<script>
import 'ninja-keys';
import conversationHotKeysMixin from './conversationHotKeys';
import goToCommandHotKeys from './goToCommandHotKeys';
import appearanceHotKeys from './appearanceHotKeys';
import agentMixin from 'dashboard/mixins/agentMixin';
import conversationLabelMixin from 'dashboard/mixins/conversation/labelMixin';
import conversationTeamMixin from 'dashboard/mixins/conversation/teamMixin';
import adminMixin from 'dashboard/mixins/isAdmin';
import { GENERAL_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  mixins: [
    adminMixin,
    agentMixin,
    conversationHotKeysMixin,
    conversationLabelMixin,
    conversationTeamMixin,
    appearanceHotKeys,
    goToCommandHotKeys,
  ],
  computed: {
    placeholder() {
      return this.$t('COMMAND_BAR.SEARCH_PLACEHOLDER');
    },
    accountId() {
      return this.$store.getters.getCurrentAccountId;
    },
    routeName() {
      return this.$route.name;
    },
    hotKeys() {
      return [
        ...this.conversationHotKeys,
        ...this.goToCommandHotKeys,
        //...this.goToAppearanceHotKeys,
      ];
    },
  },
  watch: {
    routeName() {
      this.setCommandbarData();
    },
  },
  mounted() {
    this.setCommandbarData();
    this.setStyleInShadow();
  },
  methods: {
    setCommandbarData() {
      this.$refs.ninjakeys.data = this.hotKeys;
    },
    // d99d
    setStyleInShadow() {
      const styleElement = document.createElement('style');
      styleElement.textContent = `
        .modal {
          text-align: start !important;
        }

        .group-header {
          margin-inline-start: 1rem !important;
        }
      `;
      this.$refs.ninjakeys.shadowRoot.appendChild(styleElement);
    },
    // .
    onSelected(item) {
      const { detail: { action: { title = null, section = null } = {} } = {} } =
        item;
      this.$track(GENERAL_EVENTS.COMMAND_BAR, {
        section,
        action: title,
      });
      this.setCommandbarData();
    },
  },
};
</script>

<style lang="scss">
ninja-keys {
  --ninja-accent-color: var(--w-500);
  --ninja-font-family: 'Open Sans', Roboto, 'Helvetice Neue', Tahoma, Arial,
    sans-serif;
  z-index: 9999;
}

// Wrapped with body.dark to avoid overriding the default theme
// If OS is in dark theme and app is in light mode, It will prevent showing dark theme in command bar
body.dark {
  ninja-keys {
    --ninja-overflow-background: rgba(26, 29, 30, 0.5);
    --ninja-modal-background: #151718;
    --ninja-secondary-background-color: #26292b;
    --ninja-selected-background: #26292b;
    --ninja-footer-background: #2b2f31;
    --ninja-text-color: #f8faf9;
    --ninja-icon-color: #f8faf9;
    --ninja-secondary-text-color: #c2c9c6;
  }
}
</style>
