<template>
  <woot-loading-state style="padding: var(--space-smaller)" v-if="this.flags.isResultFetching"/>
  <div v-else >
      <div :name="'t' + this.campaign.id" style="display: flex; align-items: end">
          (
          <span>{{ $t("CAMPAIGN.MESSAGE_STATUS.SENT") }}: </span>
          <h5>{{`${this.campaignResult.sent || 0}`}}</h5>
          ,
          <span class="status-label">{{$t("CAMPAIGN.MESSAGE_STATUS.DELIVERED")}}: </span>
          <h5>{{`${this.campaignResult.delivered || 0}`}}</h5>
          <span class="status-label">{{$t("CAMPAIGN.MESSAGE_STATUS.READ")}}: </span>
          <h5>{{`${this.campaignResult.read || 0}`}}</h5>
          ) /
          <span class="status-label">{{$t("CAMPAIGN.DETAILS.TOTAL_AUDIENCE")}}: </span>
          <h5>{{`${this.campaignResult.total || 0}`}}</h5>

          <template v-if="this.campaignResult.next_run">
            <span style="margin-inline-start: 3rem">{{$t("CAMPAIGN.DETAILS.NEXT_RUN")}}: </span>
            <span dir="ltr">{{this.messageStampFull(this.campaignResult.next_run)}}</span>
          </template>
      </div>
      <ve-table :columns="columns" :table-data="tableData" />
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import { VeTable } from 'vue-easytable';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from 'dashboard/mixins/time';
import Label from 'dashboard/components/ui/Label';

export default {
  name: "OnlineWaMassCampaignDetails",
  components: {
    VeTable,
  },
  mixins: [messageFormatterMixin, timeMixin],
  props: {
    campaign: {
      type: Object,
    }
  },
  data() {
    return {
      columns: [
        { field: "contact_phone", key: "phone", title: this.$t("CONTACT_FORM.FORM.PHONE_NUMBER.LABEL"), align: "center",
          renderBodyCell: ({ row }) => {
            return <span dir="ltr">{row.contact_phone}</span>;
          },
        },
        { field: "contact_name", key: "name", title: this.$t("CONTACT_FORM.FORM.NAME.LABEL"), align: "center" },
        { field: "message", key: "message", title: this.$t("CAMPAIGN.LIST.TABLE_HEADER.MESSAGE"), align: "auto" },
        { field: "message_status", key: "message_status", title: this.$t("CAMPAIGN.LIST.TABLE_HEADER.STATUS"), align: "center",
          renderBodyCell: ({ row }) => {
            let labelText = '---'
            let colorScheme = ''
            switch (row.message_status) {
              case 'sent':
                labelText = this.$t('CAMPAIGN.MESSAGE_STATUS.SENT');
                colorScheme = '';
                break;
              case 'delivered':
                labelText = this.$t('CAMPAIGN.MESSAGE_STATUS.DELIVERED');
                colorScheme = 'primary';
                break;
              case 'read':
                labelText = this.$t('CAMPAIGN.MESSAGE_STATUS.READ');
                colorScheme = 'success';
                break;
              case 'failed':
                labelText = this.$t('CAMPAIGN.MESSAGE_STATUS.FAILED');
                colorScheme = 'alert';
                break;
            }
            if (colorScheme)
              return <Label title={labelText} colorScheme={colorScheme} />;
            else
              return <span>{labelText}</span>
          },
        },
        { field: "message_created_at", key: "message_created_at", title: this.$t("CAMPAIGN.DETAILS.DISPATCH_TIME"), align: "center",
          renderBodyCell: ({ row }) => {
            return <span dir="ltr">{row.message_created_at
              ? this.messageStampFull(new Date(row.message_created_at), 'dd/MM/yy HH:mm:ss')
              : '---'}</span>;
          },
        }
      ],
    };
  },
  mounted() {
    this.$store.dispatch('campaigns/getResult', this.campaign.id);
  },
  computed: {
    ...mapGetters({
      getCampaign: 'campaigns/getCampaign',
      flags: 'campaigns/getUIFlags'
    }),
    campaignResult() {
      return (this.getCampaign(this.campaign.id) || {}).result || {}
    },
    tableData() {
      return this.campaignResult && this.campaignResult.rows || []
    }
  },
}
</script>

<style scoped>
.status-label {
    margin-inline-start: var(--space-small);
}

::v-deep .ve-table-header-th {
    text-align: start;
}

</style>
