<template>
      <div v-if="uiFlags.isFetching" class="loader">
          <spinner />
      </div>
    <div v-else class="column content-box changelog-wrapper overflow-auto" v-html="content"/>
</template>

<script>

import {mapGetters} from "vuex";
import Spinner from 'shared/components/Spinner.vue';

export default {
  components: {
    Spinner
  },
  props: {
    descriptionKey: undefined
  },
  mounted() {
    this.$store.dispatch('online99Extra/show', this.descriptionKey)
  },
  watch: {
    descriptionKey() {
      this.refresh()
    },
  },
  computed: {
    ...mapGetters({
      getDescription: 'online99Extra/getDescription',
      uiFlags: 'online99Extra/getUIFlags',
    }),
    content() {
      return this.getDescription(this.descriptionKey)
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch('online99Extra/show', this.descriptionKey)
    },
  }
}
</script>

<style lang="scss" scoped>
.changelog-wrapper {
  font-size: medium;
  width: 100%;
  padding: var(--space-normal) var(--space-large);
}

.loader {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  font-size: var(--font-size-large);
  justify-content: center;

  .spinner:before {
    height: 2rem;
    width: 2rem;
    border-left-color: #52acff;
    border-bottom-color: #52acff;
  }
}

</style>
