<template>
  <div>
    <div v-if="toEmails && !showFwd">
      <div class="input-group small" :class="{ error: $v.toEmailsVal.$error }">
        <label class="input-group-label">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.TO') }}
        </label>
        <div class="input-group-field">
          <woot-input
            v-model.trim="$v.toEmailsVal.$model"
            type="text"
            :class="{ error: $v.toEmailsVal.$error }"
            :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER')"
            @blur="onBlur"
          />
        </div>
      </div>
    </div>
    <!--    d99d-->
    <div v-if="showFwd && fwdEnabled" class="input-group-wrap">
      <div class="input-group small" :class="{ error: $v.fwdEmailsVal.$error }">
        <label class="input-group-label">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.FWD.LABEL') }}
        </label>
        <div class="input-group-field">
          <contact-input-search
            multiple
            skip-selected
            :email="$v.fwdEmailsVal.$model"
            field="email"
            :placeholder="
              $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.FWD.PLACEHOLDER')
            "
            @on-select-contact="onSelect('fwdEmailsVal', $event)"
            @input="$v.fwdEmailsVal.$model = $event"
          />
        </div>
      </div>
      <span v-if="$v.fwdEmailsVal.$error" class="message">
        {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.FWD.ERROR') }}
      </span>
    </div>
    <!-- CC -->
    <div class="input-group-wrap">
      <div class="input-group small" :class="{ error: $v.ccEmailsVal.$error }">
        <label class="input-group-label">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.LABEL') }}
        </label>
        <div class="input-group-field">
          <contact-input-search
            multiple
            skip-selected
            :email="$v.ccEmailsVal.$model"
            field="email"
            :placeholder="$t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.PLACEHOLDER')"
            @on-select-contact="onSelect('ccEmailsVal', $event)"
            @input="$v.ccEmailsVal.$model = $event"
          />
        </div>
        <woot-button
          v-if="!showBcc"
          variant="clear"
          size="small"
          @click="handleAddBcc"
        >
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.ADD_BCC') }}
        </woot-button>
        <woot-button
          v-if="!showFwd && fwdEnabled"
          variant="clear"
          size="small"
          @click="handleSetFwd"
        >
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.SET_FWD') }}
        </woot-button>
      </div>
      <span v-if="$v.ccEmailsVal.$error" class="message">
        {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.CC.ERROR') }}
      </span>
    </div>
    <!-- BCC -->
    <div v-if="showBcc" class="input-group-wrap">
      <div class="input-group small" :class="{ error: $v.bccEmailsVal.$error }">
        <label class="input-group-label">
          {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.LABEL') }}
        </label>
        <div class="input-group-field">
          <contact-input-search
            multiple
            :email="$v.bccEmailsVal.$model"
            field="email"
            :placeholder="
              $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.PLACEHOLDER')
            "
            skip-selected
            @on-select-contact="onSelect('bccEmailsVal', $event)"
            @input="$v.bccEmailsVal.$model = $event"
          />
        </div>
      </div>
      <span v-if="$v.bccEmailsVal.$error" class="message">
        {{ $t('CONVERSATION.REPLYBOX.EMAIL_HEAD.BCC.ERROR') }}
      </span>
    </div>
  </div>
</template>

<script>
import { validEmailsByComma } from './helpers/emailHeadHelper';
import ContactInputSearch from 'dashboard/modules/contact/components/ContactInputSearch.vue';
export default {
  components: {
    ContactInputSearch,
  },
  props: {
    ccEmails: {
      type: String,
      default: '',
    },
    bccEmails: {
      type: String,
      default: '',
    },
    // d99d
    fwdEmails: {
      type: String,
      default: '',
    },
    fwdEnabled: {
      type: Boolean,
      default: false,
    },
    // .
    toEmails: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showBcc: false,
      showFwd: false, // d99d
      ccEmailsVal: '',
      bccEmailsVal: '',
      fwdEmailsVal: '', // d99d
      toEmailsVal: '',
    };
  },
  watch: {
    bccEmails(newVal) {
      if (newVal !== this.bccEmailsVal) {
        this.bccEmailsVal = newVal;
      }
    },
    // d99d
    fwdEmails(newVal) {
      if (newVal !== this.fwdEmailsVal) {
        this.fwdEmailsVal = newVal;
      }
    },
    // .
    ccEmails(newVal) {
      if (newVal !== this.ccEmailsVal) {
        this.ccEmailsVal = newVal;
      }
    },
    toEmails(newVal) {
      if (newVal !== this.toEmailsVal) {
        this.toEmailsVal = newVal;
      }
    },
  },
  mounted() {
    this.ccEmailsVal = this.ccEmails;
    this.bccEmailsVal = this.bccEmails;
    this.fwdEmailsVal = this.fwdEmails; // d99d
    this.toEmailsVal = this.toEmails;
  },
  validations: {
    ccEmailsVal: {
      hasValidEmails(value) {
        return validEmailsByComma(value);
      },
    },
    bccEmailsVal: {
      hasValidEmails(value) {
        return validEmailsByComma(value);
      },
    },
    // d99d
    fwdEmailsVal: {
      hasValidEmails(value) {
        return validEmailsByComma(value);
      },
    },
    // .
    toEmailsVal: {
      hasValidEmails(value) {
        return validEmailsByComma(value);
      },
    },
  },
  methods: {
    onSelect(field, { email }) {
      const oldVal = this.$v[field].$model.replaceAll(',', ' ').split(' ');
      if (!validEmailsByComma(oldVal.slice(-1)[0])) oldVal.pop();
      const newVal = [oldVal.filter(val => val), email.replaceAll(',', ' ')]
        .flat()
        .filter(val => val)
        .concat([' ']) // add comma after last selected contact
        .join(', ');
      this.$v[field].$model = newVal;
    },
    handleAddBcc() {
      this.showBcc = true;
    },
    // d99d
    handleSetFwd() {
      this.showFwd = true;
    },
    // .
    onBlur() {
      this.$v.$touch();
      this.$emit('update:bccEmails', this.bccEmailsVal);
      this.$emit('update:fwdEmails', this.fwdEmailsVal);
      this.$emit('update:ccEmails', this.ccEmailsVal);
      this.$emit('update:toEmails', this.toEmailsVal);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group-wrap .message {
  @apply text-sm text-red-500 dark:text-red-500;
}
.input-group {
  @apply border-b border-solid border-slate-75 dark:border-slate-700 my-1;

  .input-group-label {
    @apply border-transparent bg-transparent text-xs font-semibold pl-0;
  }
  .input-group-field::v-deep input {
    @apply mb-0 border-transparent;
  }
}

.input-group.error {
  @apply border-b-red-500 dark:border-b-red-500;
  .input-group-label {
    @apply text-red-500 dark:text-red-500;
  }
}
</style>
