<template>
  <div class="privateFilesWrap">
    <div class="card">
      <h6 class="text-center">{{ $t('FILES.FORM.FIELD.FILE.LABEL') }}</h6>
      <form class="file-upload-form" @submit.prevent="handleSubmit">
        <div class="uploderWrap">
          <file-upload
            ref="upload"
            :size="4096 * 4096"
            :accept="allowedFileTypes"
            :multiple="false"
            :drop="true"
            :drop-directory="false"
            :data="{
              direct_upload_url: '/rails/active_storage/direct_uploads',
              direct_upload: true,
            }"
            @input-file="onFileUpload"
          >
            <div v-if="thumb">
              <img v-if="isTypeImage()" class="uploadPreview" :src="thumb" />
              <span v-else class="attachment-thumb"> <fluent-icon icon="document" class="file--icon" size="32" /> </span>
              <br />
              <span class="fileTitle" v-if="thumb">{{ attachedFile.name }}</span>
            </div>
            <woot-button
              v-if="!thumb"
              class-names="button--upload"
              icon="attach"
              emoji="📎"
              color-scheme="secondary"
              variant="smooth"
              size="large"
            />
          </file-upload>
        </div>
        <label>
          {{ $t('FILES.FORM.FIELD.COMMENT.LABEL') }}
          <input
            v-model.trim="comment"
            type="text"
            :placeholder="$t('FILES.FORM.FIELD.COMMENT.PLACEHOLDER')"
          />
        </label>
        <woot-submit-button :button-text="$t('FILES.FORM.BUTTON.ADD')" :disabled="!attachedFile" />
      </form>

      <transition name="modal-fade">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="modal-mask">
          <fluent-icon icon="cloud-backup" />
          <h4 class="page-sub-title">
            {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
          </h4>
        </div>
      </transition>
    </div>
    <div v-if="files.length" class="card fileCommentWrap">
      <div class="header">
        <div class="meta">
          <div class="row">
            <div class="columnWrap medium-2" v-for="file in files">
              <span class="createdAt">{{ file.created_at_exact }}</span>
              <all-bubble
                  :file="file"
              />
              <woot-button
                class="removeFileButton"
                color-scheme="alert"
                variant="smooth"
                size="tiny"
                type="button"
                @click="handleRemove(file)"
              >
                X
              </woot-button>
              <span v-if="file.comment" class="comment">{{ file.comment }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';
import {
  ALLOWED_FILE_TYPES,
  MAXIMUM_FILE_UPLOAD_SIZE,
} from 'shared/constants/contact';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import alertMixin from 'shared/mixins/alertMixin';
import AllBubble from '../../components/AllBubble';

export default {
  name: 'PrivateFilesOnContactPage',
  components: {
    FileUpload,
    AllBubble,
  },
  mixins: [alertMixin],
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      comment: '',
      attachedFile: null,
      thumb: '',
      files: [],
    };
  },
  computed: {
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
  },
  mounted() {
    this.fetchFiles();
  },
  methods: {
    async fetchFiles() {
      const { contactId: id } = this;
      this.files = await this.$store.dispatch('contacts/fetchFiles', { id });
    },
    onFileUpload(file) {
      this.onIndirectFileUpload(file);
    },
    onIndirectFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('FILES.ALERT.ERROR.FILE_SIZE', {
            size: MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ file }) {
      const reader = new FileReader();
      const prepareFile = file.file;
      reader.readAsDataURL(prepareFile);
      reader.onloadend = () => {
        this.thumb = reader.result;
        this.attachedFile = prepareFile;
      };
    },
    handleSubmit() {
      this.onSubmit();
      this.attachedFile = null;
      this.thumb = '';
      this.comment = '';
    },
    async handleRemove(file) {
      try {
        this.files = await this.$store.dispatch('contacts/removeFile', {
          id: this.contactId,
          fileId: file.id,
        });
        this.showAlert(this.$t('FILES.ALERT.SUCCESS.DELETE'));
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    async onSubmit() {
      try {
        this.files = await this.$store.dispatch('contacts/addFile', {
          id: this.contactId,
          isFormData: true,
          file: this.attachedFile,
          comment: this.comment,
          additional_attributes: { social_profiles: {} },
        });
        this.showAlert(this.$t('FILES.ALERT.SUCCESS.ADD'));
      } catch (error) {
        this.showAlert(error.message);
      }
    },
    isTypeImage() {
      const type = this.attachedFile.content_type || this.attachedFile.type;
      return type.includes('image');
    },
  },
};
</script>

<style lang="scss">
.privateFilesWrap {
  .fileCommentWrap {
    .comment {
      font-size: 13px;
      padding-top: 5px;
    }
    .row .columnWrap {
      position: relative;
    }
    .meta {
      .text-block-title {
        color: black;
      }

      h5 {
        font-size: 10px;
      }
    }

    .comment,
    .createdAt {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    .file--icon, .download {
      color: var(--w-400);
    }

    .createdAt {
      padding-bottom: 8px;
    }
    .removeFileButton {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 8px;
    }
    .message-text__wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      max-height: 100px;
      margin: 10px;

      video,
      img {
        width: 100px;
      }
    }
  }

  .file-upload-form {
    width: 100%;

    .uploderWrap {
      text-align: center;

      .uploadPreview {
        width: 100px;
      }
      .file-uploads label {
        cursor: pointer;
      }

      .fileTitle {
        font-size: 13px;
      }
      .file--icon {
        color: var(--w-400);
      }
    }
  }

  .modal-container {
    img {
      width: 500px !important;
    }
    video {
      height: 100% !important;
      width: 100% !important;
    }

    .modal--close {
      z-index: 2;
    }
  }
}
</style>
