<template>
  <woot-modal :show="show" :on-close="closeModal">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('INTEGRATION_SETTINGS.OPEN_AI.CTA_MODAL.TITLE')"
      />

      <form class="mx-0 flex flex-wrap w-full" @submit.prevent="submit">
        <dynamic-content
          description-key="chatgpt_usage_description"
        />
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button
            type="submit"
            :is-loading="isSubmitting"
            :is-disabled="uiFlags.isFetching"
            class="button nice"
          >
            {{ $t('INTEGRATION_APPS.CONNECT.BUTTON_TEXT') }}
          </woot-button>
          <woot-button class="button clear" @click.prevent="onDismiss">
            {{ $t('INTEGRATION_SETTINGS.DASHBOARD_APPS.CREATE.FORM_CANCEL') }}
          </woot-button>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import AccountAPI from '../../../api/account';
import DynamicContent from "../../../routes/dashboard/dynamicContent/Index.vue";
import {mapGetters} from "vuex";

export default {
  mixins: [alertMixin],
  components: {
    DynamicContent
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubmitting: false,
      content: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'online99Extra/getUIFlags',
    }),
  },
  mounted() {
  },
  methods: {
    onDismiss() {
      this.showAlert(
        this.$t('INTEGRATION_SETTINGS.OPEN_AI.CTA_MODAL.DISMISS_MESSAGE')
      );
      this.closeModal()
    },
    closeModal() {
      this.$emit('close');
    },
    async submit() {
      this.isSubmitting = true;
      try {
        await this.finishOpenAI()
        this.closeModal();
      } catch (err) {
      } finally {
        this.isSubmitting = false;
      }
    },
    async finishOpenAI() {
      let alertMessage
      const payload = {
        app_id: 'openai',
        settings: {
          api_key: this.value || undefined, // d99d added undefined
        },
      };
      try {
        await this.$store.dispatch('integrations/createHook', payload);
        alertMessage = this.$t(
          'INTEGRATION_SETTINGS.OPEN_AI.CTA_MODAL.SUCCESS_MESSAGE'
        );
      } catch (error) {
        console.error(error)
        const errorMessage = error?.response?.data?.message;
        alertMessage =
          errorMessage || this.$t('INTEGRATION_APPS.ADD.API.ERROR_MESSAGE');
        throw error
      } finally {
        this.showAlert(alertMessage);
      }
    },
  },
};
</script>
