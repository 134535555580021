import DescriptionApi from '../../api/accountExtra';
import Vue from "vue";

const state = {
  descriptions: {},
  uiFlags: {
    isFetching: false,
  },
  updateAt: 0
};

export const getters = {
  getDescription: $state => key => {
    return $state.descriptions[key];
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  show: async ({ commit }, id) => {
    if (state.descriptions[id] && Date.now() - state.updateAt < 6*3600000) return;

    commit('SET_DESCRIPTION_UI_FLAG', { isFetching: true });
    try {
      const response = await DescriptionApi.show(id);
      commit('SET_DESCRIPTION', {id, data: response.data});
    } catch (error) {
      console.error('online99Extra/show', error)
    }
    commit('SET_DESCRIPTION_UI_FLAG', {isFetching: false});
  },
};

export const mutations = {
  ['SET_DESCRIPTION_UI_FLAG']($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  ['SET_DESCRIPTION']($state, {id, data}) {
    Vue.set($state.descriptions, id, data)
    $state.updateAt = Date.now()
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
