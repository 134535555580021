<template>
  <div>
    <page-header
      class="mb-6"
      :header-title="$t('EXPORT_WIZARD.STEP_1.TITLE')"
      :header-content="$t('EXPORT_WIZARD.STEP_1.DESC')"
    />
    <div>
      <input-radio-list
        name="widget-screen"
        :items="categories"
        @change="onChangeCategory"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from '../../../routes/dashboard/settings/SettingsSubPageHeader.vue';
import InputRadioList from '../../ui/InputRadioList.vue';

export default {
  components: {
    InputRadioList,
    PageHeader,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onChangeCategory(category) {
      this.$emit('onChangeCategory', category.key);
    },
  },
};
</script>

<style scoped lang="scss">

</style>
