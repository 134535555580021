<template>
  <section class="w-full">
    <!--        <h4 v-if="!loading && showModal && !inboxes.length" class="error-message">-->
    <!--            {{$t('NEW_CONVERSATION.FORM.INBOX.ERROR')}}-->
    <!--        </h4>-->
    <empty-state v-if="loading"/>
    <woot-modal v-if="showModal && !loading"
                :class="{'no-close-button': isSingleWindow}"
                :show.sync="show"
                :size="!contactPhone ? 'modal--large' : undefined"
                :on-close="onCancel">
      <div class="column">
        <woot-modal-header
          v-if="contactPhone"
          :header-title="$t('NEW_CONVERSATION.TITLE')"
          :header-content="$t('NEW_CONVERSATION.DESC')"
        />
        <woot-modal-header
          v-else
          :header-title="$t('CONTACT_PANEL.NEW_MESSAGE')"
        />
        <new-contact-and-conversation-form
          :contact="contact"
          :is-draft="isDraft"
          :single-inbox="inbox"
          :is-single-window="isSingleWindow"
          :on-submit="onSubmit"
          @cancel="onCancel"
        />
      </div>
    </woot-modal>
  </section>
</template>

<script>
import NewContactAndConversationForm from "./NewContactAndConversationForm";
import ConversationApi from "../../api/conversations";
import ContactAPI from "../../api/contacts";
import {mapGetters} from "vuex";
import alertMixin from "../../../shared/mixins/alertMixin";
import contactMixin from "../../mixins/contactMixin";
import EmptyState from "../../components/widgets/conversation/EmptyState/EmptyState.vue";
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  mixins: [alertMixin, contactMixin],
  components: {
    EmptyState,
    NewContactAndConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    contactPhone: {
      type: [String],
      default: undefined,
    },
    contactName: {
      type: [String],
      default: undefined,
    },
    onSuccess: {
      type: Function,
      default: undefined,
    },
    inbox: {
      type: Object,
      default: undefined,
    },
    isSingleWindow: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      localLoading: false,
      showModal: false,
      // normalizedPhone: undefined,
      draftPhone: '',
      draftEmail: '',
      draftName: '',
    }
  },
  async created() {
    // this.normalizedPhone = this.israelPhone2e164(this.contactPhone)?.replace(/\+/, '');
    if (this.normalizedPhone && !this.isDraft) {
      this.localLoading = true
      const conversationId = await this.getConversationId(this.normalizedPhone)
      this.localLoading = false
      if (conversationId) this.$router.replace({
        name: 'contact_conversation',
        params: {conversation_id: conversationId}
      })
      if (conversationId === 0) this.showModal = true
    } else {
      this.showModal = true
    }
  },
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
      inboxUiFlags: 'inboxes/getUIFlags',
    }),
    name() {
      return this.contactName || this.draftName;
    },
    phone() {
      return this.contactPhone || this.draftPhone;
    },
    normalizedPhone() {
      return this.israelPhone2e164(this.phone)?.replace(/\+/, '');
    },
    contact() {
      return {phone_number: this.normalizedPhone && `+${this.normalizedPhone}`, email: this.draftEmail, name: this.name}
    },
    loading() {
      return this.localLoading || this.inboxUiFlags.isFetching
    },
    isDraft() {
      return !!this.draftPhone || !!this.draftEmail;
    },
  },
  mounted() {
    bus.$on(BUS_EVENTS.START_NEW_CONVERSATION, this.openConversationModalWith);
  },
  destroyed() {
    bus.$off(BUS_EVENTS.START_NEW_CONVERSATION, this.openConversationModalWith);
  },
  methods: {
    onCancel() {
      this.$emit("update:show", false)
      if (this.isSingleWindow) {
        //if (window.history.length) this.$router.go(-1)
      }
      this.draftPhone = '';
      this.draftEmail = '';
      this.draftName = '';
    },
    async onSubmit(payload) {
      const data = await this.$store.dispatch(
        'contactConversations/create', payload);
      if (this.isSingleWindow) {
        this.$router.replace({name: 'contact_conversation', params: {conversation_id: data.id}})
      } else {
        this.onCancel()
      }
      return data
    },
    async getConversationId(phone) {
      if (!phone) return undefined
      const queryPayload = {
        "payload": [
          {
            "attribute_key": "phone_number",
            "filter_operator": "equal_to",
            values: [phone],
            "attribute_model": "standard"
          }
        ]
      }
      try {
        const {
          data: {payload},
        } = await ContactAPI.filterWithConversations(queryPayload);
        if (payload && payload.length) {
          const messages = payload
            .flatMap(x=>x.conversations.flatMap(c=>(c.messages || []).map(m=>({created_at: m.created_at, conversation_id: c.id}))))
            .sort((a,b) => b.created_at - a.created_at)
          return messages.length && messages[0].conversation_id //let's take the last active conversation
        }
        return 0;
      } catch (err) {
        this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
      }
      return undefined
    },
    openConversationModalWith({ phoneNumber, email, name }) {
      this.draftPhone = phoneNumber;
      this.draftEmail = email;
      this.draftName = name;
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss">
.no-close-button {
  .modal--close {
    display: none;
  }
}

.error-message {
  display: flex;
  color: red;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.modal--large {
  width: 72rem;
}
</style>
