<template>
  <div class="panelFilesWrapper row">
    <div v-for="file in files" class="medium-6 columns">
      <all-bubble :file="file" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AllBubble from '../../../components/AllBubble.vue';

export default {
  components: {
    AllBubble,
  },
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      hasImageError: false,
      files: [],
    };
  },
  computed: {
    ...mapGetters({
      getSelectedChat: 'getSelectedChat',
    }),
  },
  watch: {
    conversationId() {
      this.fetchFileMessages();
    },
  },
  mounted() {
    this.fetchFileMessages();
  },
  methods: {
    onImageLoadError() {
      this.hasImageError = true;
    },
    async fetchFileMessages() {
      this.files = await this.$store.dispatch(
        'fetchFileMessages',
        this.conversationId
      );
    },
  }
};
</script>
<style lang="scss">
.panelFilesWrapper {
  .file--icon {
    color: var(--w-400);
  }
  .meta {
    .text-block-title {
      color: black;
    }

    h5 {
      font-size: 10px;
    }
    .download {
      color: var(--w-400);
    }
  }
  .modal-container {
    img {
      height: 100% !important;
    }
    video {
      height: 100% !important;
      width: 100% !important;
    }

    .modal--close {
      z-index: 2;
    }
  }
  .location .meta{
    width: 100px;
  }
  .message-text__wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #cbe6ff;

    video,
    img {
      height: 100px;
      padding: 10px;
    }
  }
}
</style>
