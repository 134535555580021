import {frontendURL} from "../../../../helper/URLHelper";

const campaigns = accountId => ({
  parentNav: 'campaigns',
  routes: ['settings_account_campaigns', 'one_off', 'online_wa_mass'],
  menuItems: [
    {
      key: 'waMassCampaigns',
      icon: 'ion-social-whatsapp',
      label: 'ONLINE_WA_MASS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/campaigns/online_wa_mass`),
      toStateName: 'online_wa_mass',
    },
  ],
});

export default campaigns;
