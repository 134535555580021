<template>
  <div class="ez-conversation-filters">
    <page-header
      class="mb-6"
      :header-title="$t('EXPORT_WIZARD.STEP_2.CONVERSATIONS.TITLE')"
      :header-content="$t('EXPORT_WIZARD.STEP_2.CONVERSATIONS.DESC')"
    />
    <conversation-advanced-filter
      ref="conversationAdvancedFilter"
      :initial-filter-types="advancedFilterTypes"
      :initial-applied-filters="appliedFilter"
      :skip-header="true"
      :skip-buttons="true"
      :skip-show-filters="skipShowFilters"
      @onFiltersChange="onFiltersChange"
    />
  </div>
</template>

<script>
import ConversationAdvancedFilter from '../conversation/ConversationAdvancedFilter.vue';
import advancedFilterTypes from '../conversation/advancedFilterItems';
import filterMixin from 'shared/mixins/filterMixin';
import wootConstants from 'dashboard/constants/globals';
import PageHeader from '../../../routes/dashboard/settings/SettingsSubPageHeader.vue';

export default {
  components: {
    PageHeader,
    ConversationAdvancedFilter,
  },
  mixins: [filterMixin],
  props: {
    filters: { type: Array, default: () => [] },
    skipShowFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeStatus: wootConstants.STATUS_TYPE.ACTIVE,
      advancedFilterTypes: advancedFilterTypes.map(filter => ({
        ...filter,
        attributeName: this.$t(`FILTER.ATTRIBUTES.${filter.attributeI18nKey}`),
      })),
      appliedFilter: this.filters || [],
    };
  },
  mounted() {
    if (!this.appliedFilter.length) {
      this.initializeExistingFilterToModal();
    }
  },
  methods: {
    onFiltersChange(filters) {
      this.$emit('onFiltersChange', filters);
    },
    isFiltersValid() {
      return this.$refs.conversationAdvancedFilter.isFiltersValid();
    },
  },
};
</script>

<style lang="scss">
.ez-conversation-filters {
  .conv-advanced-filter > div {
    padding: 0;
    @apply mb-4;
  }
}
</style>
