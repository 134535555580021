<template>
  <div
    class="menu-with-submenu min-width-calc w-full p-1 flex items-center h-7 rounded-md relative bg-white dark:bg-slate-700 justify-between hover:bg-woot-75 cursor-pointer dark:hover:bg-slate-800"
    :class="!subMenuAvailable ? 'opacity-50 cursor-not-allowed' : ''"
    ref="menuItem"
  >
    <div class="flex items-center">
      <fluent-icon :icon="option.icon" size="14" class="menu-icon" />
      <p class="my-0 mx-2 text-xs">{{ option.label }}</p>
    </div>
    <fluent-icon :icon="chevronDirection" size="12" />
    <div
      v-if="subMenuAvailable"
      ref="submenu"
      class="submenu bg-white dark:bg-slate-700 p-1 shadow-lg rounded-md absolute ltr:left-full rtl:right-full min-h-min max-h-[15rem] overflow-y-auto overflow-x-hidden cursor-pointer"
      :class="{'top-0': !isOffscreen, 'bottom-0': isOffscreen, 'hidden': !isVisible}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => {},
    },
    subMenuAvailable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOffscreen: false,
      isVisible: true,
    };
  },
  computed: {
    isRTL() {
      return this.$root.$i18n.locale === 'he';
    },
    chevronDirection() {
      return this.isRTL ? 'chevron-left' : 'chevron-right';
    },
  },
  mounted() {
    this.checkPosition();
  },
  methods: {
    checkPosition() {
      this.$nextTick(() => {
        const menuItem = this.$refs.menuItem;
        const submenu = this.$refs.submenu;
        if (!menuItem || !submenu) return;

        const menuItemRect = menuItem.getBoundingClientRect();
        const submenuRect = submenu.getBoundingClientRect();
        this.isVisible = false;
        const combinedBottom = menuItemRect.top + menuItemRect.height + submenuRect.height;
        const isOffscreen = combinedBottom > window.innerHeight;

        if (isOffscreen !== this.isOffscreen) {
          this.isOffscreen = isOffscreen;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.menu-with-submenu {
  min-width: calc(6.25rem * 2);

  &:hover {
    .submenu {
      @apply block;
    }
  }
}
</style>
