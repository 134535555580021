import { frontendURL } from '../../../helper/URLHelper';
import account from './account/account.routes';
import agent from './agents/agent.routes';
import role from './roles/roles.routes';
import agentBot from './agentBots/agentBot.routes';
import attributes from './attributes/attributes.routes';
import automation from './automation/automation.routes';
import auditlogs from './auditlogs/audit.routes';
import billing from './billing/billing.routes';
import campaigns from './campaigns/campaigns.routes';
import canned from './canned/canned.routes';
import inbox from './inbox/inbox.routes';
import integrationapps from './integrationapps/integrations.routes';
import integrations from './integrations/integrations.routes';
import labels from './labels/labels.routes';
import macros from './macros/macros.routes';
import profile from './profile/profile.routes';
import reports from './reports/reports.routes';
import store from '../../../store';
import sla from './sla/sla.routes';
import onlinewaSettings from './onlinewasettings/onlinewasettings.routes';
import teams from './teams/teams.routes';
import { getSidebarItems } from '../../../components/layout/config/default-sidebar';

function blockedByPlatform (route) {
  const accountId = store.getters.getCurrentAccountId;
  const { features } = store.getters.getCurrentUser;
  const settingItems = getSidebarItems(accountId).secondaryMenu.find(item => item.parentNav === 'settings').menuItems;
  const routeItem = settingItems.find(item => item.toState.includes(`settings/${route}`) );
  return features && routeItem && features[routeItem.toStateName] === false;
}

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings'),
      name: 'settings_home',
      roles: ['administrator', 'agent'],
      redirect: () => {
        if (store.getters.getCurrentRole === 'administrator') {
          let settingsPath = 'accounts/:accountId/settings/general';
          if (store.getters.getCurrentFeatures?.general_settings_index === false) { // d99d
            settingsPath = 'accounts/:accountId/settings/agents';
          }
          if (store.getters.getCurrentFeatures?.agent_list === false) { // d99d
            settingsPath = 'accounts/:accountId/settings/teams';
          }
          return frontendURL(settingsPath);
        }
        const permissions = store.getters.getInboxPermissions;
        const routeMap = {
          'Account settings': 'general',
          Agents: 'agents',
          Roles: 'roles',
          Teams: 'teams',
          Inbox: 'inboxes',
          Labels: 'labels',
          'Custom attributes': 'custom-attributes',
          Automations: 'automation',
          Macros: 'macros',
          'Canned Responses': 'canned-response',
          'Audit Logs': 'audit-log',
          Sla: 'sla',
        };
        let route = 'general';

        let accountId = store.getters.getCurrentAccountId;
        let currentAccount = store.getters['accounts/getAccount'](accountId);
        let newRulesSystemEnabled = currentAccount?.features?.new_rules_system;
        if (!newRulesSystemEnabled) {
          route = 'macros';
        }
        // Iterate over routeMap entries in order
        Object.entries(routeMap).some(([key, value]) => {
          const permission = permissions.find(p => p.name === key && p.can_read);
          if (permission && !blockedByPlatform(value)) {
            route = value;
            return true; // Exit the loop
          }
          return false; // Continue to the next entry
        });
        return frontendURL(`accounts/:accountId/settings/${route}`);
      },
    },
    ...account.routes,
    ...agent.routes,
    ...role.routes,
    ...agentBot.routes,
    ...attributes.routes,
    ...automation.routes,
    ...auditlogs.routes,
    ...billing.routes,
    ...campaigns.routes,
    ...canned.routes,
    ...inbox.routes,
    //...integrationapps.routes,
    //...integrations.routes,
    ...labels.routes,
    ...macros.routes,
    ...profile.routes,
    ...reports.routes,
    ...sla.routes,
    ...teams.routes,
    ...onlinewaSettings.routes,
  ],
};
