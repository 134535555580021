import ar from './locale/ar.json';
import en from './locale/en.json';
import es from './locale/es.json';
import he from './locale/he.json';

export default {
  ar,
  en,
  es,
  he,
};
