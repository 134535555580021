import mila from 'markdown-it-link-attributes';
import mentionPlugin from './markdownIt/link';
import customizeBoldPlugin from './markdownIt/customizeBold';
import strikethroughPlugin from "./markdownIt/strikethroughPlugin";

const options = {
  html: false,
  xhtmlOut: true,
  breaks: true,
  langPrefix: 'language-',
  linkify: true,
  typographer: true,
  quotes: '\u201c\u201d\u2018\u2019',
  maxNesting: 20,
};

const attributes = {
  class: 'link',
  rel: 'noreferrer noopener nofollow',
  target: '_blank',
};

const md = require('markdown-it')(options)
  .use(mentionPlugin)
  .use(mila, {
    attrs: attributes,
  });

const mdW = require('markdown-it')(options)
  .use(mentionPlugin)
  .use(customizeBoldPlugin)
  .use(strikethroughPlugin)
  .use(mila, {
    attrs: attributes,
  });

const TWITTER_USERNAME_REGEX = /(^|[^@\w])@(\w{1,15})\b/g;
const TWITTER_USERNAME_REPLACEMENT = '$1[@$2](http://twitter.com/$2)';
const TWITTER_HASH_REGEX = /(^|\s)#(\w+)/g;
const TWITTER_HASH_REPLACEMENT = '$1[#$2](https://twitter.com/hashtag/$2)';

// skip phone numbers that are part of a URL
const PHONE_NUMBER_REGEX = /(?<!\b(?:https?|ftp):\/\/[^\s]*)(?<!\d)(\+?\d{1,3}[ -]?\(?\d{2,3}\)?[ -]?\d{2,5}[ -]?\d{2,5}[ -]?\d{2,5}\b)/gm;
const PHONE_NUMBER_REPLACEMENT = (match) => {
  // Remove spaces and dashes for the tel: link
  const telLink = match.replace(/[ -]/g, '').replace('+', '');
  return `[${match}](tel:${telLink})`;
};

const EMAIL_REGEX = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;
const EMAIL_REPLACEMENT = match => {
  return `[${match}](mailto:${match})`;
};

class MessageFormatter {
  constructor(
    message,
    isATweet = false,
    isAPrivateNote = false,
    isAWhatsAppChannel = false
  ) {
    this.message = message || '';
    this.isAPrivateNote = isAPrivateNote;
    this.isATweet = isATweet;
    this.isAWhatsAppChannel = isAWhatsAppChannel;
  }

  formatMessage() {
    let updatedMessage = this.message;
    if (!this.isATweet && !this.isAPrivateNote) {
      updatedMessage = updatedMessage.replace(
        TWITTER_USERNAME_REGEX,
        TWITTER_USERNAME_REPLACEMENT
      );
      updatedMessage = updatedMessage.replace(
        TWITTER_HASH_REGEX,
        TWITTER_HASH_REPLACEMENT
      );
    }
    updatedMessage = updatedMessage.replaceAll(
      PHONE_NUMBER_REGEX,
      PHONE_NUMBER_REPLACEMENT
    );
    updatedMessage = updatedMessage.replaceAll(EMAIL_REGEX, EMAIL_REPLACEMENT);
    return this.isAWhatsAppChannel && !this.isAPrivateNote
      ? mdW.renderInline(updatedMessage)
      : md.render(updatedMessage);
  }

  get formattedMessage() {
    return this.formatMessage();
  }

  get plainText() {
    const strippedOutHtml = new DOMParser().parseFromString(
      this.formattedMessage,
      'text/html'
    );
    return strippedOutHtml.body.textContent || '';
  }
}

export default MessageFormatter;
