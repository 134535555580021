/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import WorkflowView from './WorkflowView';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/builder'),
    name: 'builder_home',
    component: WorkflowView,
    roles: ['administrator'],
    props: route => {
      return { accountId: route.params.accountId };
    },
  },
]
