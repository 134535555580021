import ApiClient, {Utility} from './ApiClient';

const buildCreatePayload = (data) => {
  let payload;
  const {file, ...item} = data
  if (file) {
    data = item;
    payload = new FormData();
    payload.append('campaign[file]', file);
    Utility.convertModelToFormData(data, payload, 'campaign')
  } else {
    payload = data;
    if (file === null) payload.file = null //explicit deletion
  }
  return payload;
};

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  create(data) {
    return super.create(buildCreatePayload(data));
  }

  update(id, data) {
    return super.update(id, buildCreatePayload(data));
  }

  getResult(id) {
    return axios.get(`${this.url}/${id}/result`);
  }
}

export default new CampaignsAPI();
