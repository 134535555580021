<template>
  <div class="row app-wrapper dashboard--blank">
    <section class="app-content columns">
      <router-view></router-view>
    </section>
  </div>
</template>

<script>


export default {
  components: {
  },
  computed: {
    currentRoute() {
      return ' ';
    },
  },
  mounted() {
    this.$store.dispatch('inboxes/get');
  },

};
</script>
