<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <page-header
        :header-title="$t('INBOX_MGMT.ADD.TIKTOK_CHANNEL.TITLE')"
        :header-content="$t('INBOX_MGMT.ADD.TIKTOK_CHANNEL.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="authenticateWithTikTok()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.TIKTOK_CHANNEL.FIELD.CHANNEL_NAME.LABEL') }}
          <input
              v-model.trim="channelName"
              type="text"
              :placeholder="
              $t('INBOX_MGMT.ADD.TIKTOK_CHANNEL.FIELD.CHANNEL_NAME.PLACEHOLDER')
            "
              @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
              $t('INBOX_MGMT.ADD.TIKTOK_CHANNEL.FIELD.ERROR')
            }}</span>
        </label>
      </div>

      <div class="w-full">
        <woot-submit-button
            :button-text="$t('INBOX_MGMT.ADD.TIKTOK_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import TiktokClient from 'dashboard/api/channel/tiktokClient';
import PageHeader from "../../SettingsSubPageHeader.vue";
import { required } from 'vuelidate/lib/validators';

export default {
  components: {PageHeader},
  data() {
    return {
      channelName: '',
    };
  },
  computed: {
  },
  validations: {
    channelName: { required },
  },
  methods: {
    async authenticateWithTikTok() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      const response = await TiktokClient.generateAuthorization({ channel_name: this.channelName });
      const {
        data: { url },
      } = response;

      window.location.href = url;
    },
  }
};
</script>
