<template>
  <div class="wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]">
    <settings-sub-page-header
      :header-title="$t('INBOX_MGMT.ADD.MICROSOFT.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.MICROSOFT.DESCRIPTION')"
    />
    <form
      class="microsoft--sign-in-form"
      @submit.prevent="requestAuthorization"
    >
      <woot-input
        v-show="!isApi"
        v-model.trim="email"
        type="text"
        :placeholder="$t('INBOX_MGMT.ADD.MICROSOFT.EMAIL_PLACEHOLDER')"
        @blur="$v.email.$touch"
      />
<!--      d99d-->
      <div v-if="false" class="enter-to-send--checkbox text-end" style="margin-top: -0.5rem;">
        <input
          id="withConsent"
          v-model="withConsent"
          type="checkbox"
        />
        <label for="withConsent">
          {{ $t('INBOX_MGMT.ADD.MICROSOFT.WITH_CONSENT_LABEL') }}
        </label>
      </div>
<!--      .-->
      <woot-submit-button
        icon="brand-twitter"
        button-text="Sign in with Microsoft"
        type="submit"
        :loading="isRequestingAuthorization"
      />
    </form>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import microsoftClient from 'dashboard/api/channel/microsoftClient';
import SettingsSubPageHeader from '../../../SettingsSubPageHeader.vue';
import {email, requiredIf} from 'vuelidate/lib/validators';

export default {
  components: { SettingsSubPageHeader },
  props: { // d99d
    isApi: false,
  },
  mixins: [alertMixin],
  data() {
    return { email: '', isRequestingAuthorization: false, withConsent: false, /* d99d */ };
  },
  validations: {
    email: {required: requiredIf(function() {return !this.isApi}), email},
  },
  methods: {

    async requestAuthorization() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.isRequestingAuthorization = true;
        const response = await microsoftClient.generateAuthorization({
          email: this.email,
          prompt: this.withConsent ? 'consent' : undefined, // d99d
          is_api: this.isApi, // d99d
        });
        const {
          data: { url },
        } = response;
        window.location.href = url;
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.MICROSOFT.ERROR_MESSAGE'));
      } finally {
        this.isRequestingAuthorization = false;
      }
    },
  },
};
</script>

<style scoped>
.microsoft--sign-in-form {
  @apply mt-6;
}
</style>
