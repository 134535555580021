import ApiClient from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  get(params) {
    return axios.get(this.url, { params });
  }
}

export default new Agents();
