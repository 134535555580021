<template>
  <form class="conversation--form" @submit.prevent="onFormSubmit">

    <div>

      <div class="row">
        <div class="columns">
          <label :class="{ error: $v.message.$error }">
            <div style="display: flex; justify-content: space-between;">
            {{ $t('NEW_CONVERSATION.FORM.MESSAGE.LABEL') }}
              <input-radio-group
                :items="destinationTypes"
                :action="handleDestinationTypesChange"
              />
            </div>
            <textarea
              v-model="message"
              class="message-input"
              type="text"
              :placeholder="$t('NEW_CONVERSATION.FORM.MESSAGE.PLACEHOLDER')"
              @input="$v.message.$touch"
            />
            <span v-if="$v.message.$error" class="message">
              {{ $t('NEW_CONVERSATION.FORM.MESSAGE.ERROR') }}
            </span>
          </label>
        </div>
      </div>
      <div class="row" :class="{ error: $v.attachedFiles.$error }">
        <file-upload v-if="!attachedFiles.length"
          name="post-file-upload"
          ref="upload-new_-conv"
          :size="4096 * 4096"
          accept="image/*,"
          :drop="false"
          :drop-directory="false"
          @input-file="onFileUpload"
        >
          <woot-button
            class-names="button--upload"
            color-scheme="secondary"
            variant="smooth"
            size="small"
          >{{$t('FACEBOOK2.FB_POST.ADD_PHOTO')}}</woot-button>
        </file-upload>
        <div v-if="attachedFiles.length" class="attachment-preview-box">
          <attachments-preview
            :attachments="attachedFiles"
            :remove-attachment="removeAttachment"
          />
        </div>
        <span v-if="$v.attachedFiles.$error" class="message" style="margin-top: 0">
              {{ $t('CONVERSATION.REPLYBOX.FILE_IS_REQUIRED') }}
        </span>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button clear" @click.prevent="onCancel">
        {{ $t('NEW_CONVERSATION.FORM.CANCEL') }}
      </button>
      <woot-button type="submit" :is-loading="isSubmitting">
        {{ $t('FACEBOOK2.FB_POST.FORM_SUBMIT') }}
      </woot-button>
    </div>
  </form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import inboxMixin from "shared/mixins/inboxMixin";
import agentMixin from "dashboard/mixins/agentMixin";
import contactMixin from "../../mixins/contactMixin";
import {checkFileSizeLimit} from "../../../shared/helpers/FileHelper";
import {MAXIMUM_FILE_UPLOAD_SIZE} from "../../../shared/constants/messages";
import AttachmentsPreview from "components/widgets/AttachmentsPreview.vue";
import FileUpload from "vue-upload-component";
import InputRadioGroup from "../dashboard/settings/inbox/components/InputRadioGroup.vue";

export default {
  components: {
    AttachmentsPreview, FileUpload, InputRadioGroup
  },
  mixins: [alertMixin, inboxMixin, agentMixin, contactMixin],
  props: {
    inbox: {
      type: Object,
      default: undefined,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: '',
      attachedFiles: [],
      isInstagram: false,
      destinationTypes: [
        {id: 'facebook', title: this.$t('FACEBOOK2.FB_POST.FACEBOOK_LABEL'), checked: true},
        {id: 'instagram', title: this.$t('FACEBOOK2.FB_POST.INSTAGRAM_LABEL'), checked: false},
      ],
    };
  },
  validations: {
    message: {
      required: function (v) { return this.attachedFiles.length || v.length}
    },
    attachedFiles: {
      required: function(v) { return !this.isInstagram || v.length }
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onFormSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const [attachment] = this.attachedFiles;
      const file = attachment && (attachment.resource.file || attachment.blobSignedId)

      this.$emit('submit', {
        inbox_id: this.inbox.id,
        text: this.message,
        is_instagram: this.isInstagram,
        file,
      });

    },
    onFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ blob, file }) {
      this.attachedFiles = []; //d99d

      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    handleDestinationTypesChange(type) {
      this.isInstagram = type.id === 'instagram'
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .attachment-preview-box {
  padding: 0;
  background: transparent;

  .preview-item__wrap {
    margin-top: 0;
    .preview-item {
      margin-top: 0;
    }
  }
}
</style>
