import Facebook from './channels/Facebook';
import Facebook2 from './channels/Facebook2'; // d99d
import Website from './channels/Website';
import Twitter from './channels/Twitter';
import Api from './channels/Api';
import ApiWhatsappSelect from './channels/ApiWhatsappSelect';
import ApiSms from './channels/ApiSms.vue';
import Email from './channels/Email';
import Sms from './channels/Sms';
import Whatsapp from './channels/Whatsapp';
import Line from './channels/Line';
import Telegram from './channels/Telegram';
import Tiktok from './channels/Tiktok';

const channelViewList = {
  facebook: Facebook,
  facebook2: Facebook2, // d99d
  website: Website,
  twitter: Twitter,
  api: Api,
  email: Email,
  sms: Sms,
  whatsapp: Whatsapp,
  line: Line,
  telegram: Telegram,
  api_whatsapp_select: ApiWhatsappSelect,
  api_sms: ApiSms,
  tiktok: Tiktok,
};

const channelPropList = {
  email: { title: 'INBOX_MGMT.ADD.EMAIL_CHANNEL.TITLE' },
  sms: { title: 'INBOX_MGMT.ADD.SMS.TITLE' },
  api_whatsapp_select: { title: 'INBOX_MGMT.ADD.WHATSAPP.TITLE', subTitle: 'All API versions' },
  api_sms: { title: 'INBOX_MGMT.ADD.SMS.TITLE' },
};

export default {
  create() {
    return {
      props: {
        channel_name: {
          type: String,
          required: true,
        },
      },
      name: 'new-channel-view',
      render(h) {
        return h(channelViewList[this.channel_name] || null, {
          props: channelPropList[this.channel_name]
        });
      },
    };
  },
};
