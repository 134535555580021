import ApiClient, {Utility} from './ApiClient';


export default new class extends ApiClient {
  constructor() {
    super('online_fb', { accountScoped: true });
  }

  async createPost(data) {
    try {
      const {file, ...item} = data
      if (file) {
        const payload = new FormData();
        payload.append('file', file);
        Utility.convertModelToFormData(item, payload)
        return await axios.post(`${this.url}/create_photo_post`, payload, {headers: { "Content-Type": "multipart/form-data" }});
      } else {
        return await axios.post(`${this.url}/create_text_post`, data);
      }
    } catch (error) {
      throw new Error(error?.response?.data?.error_type || error?.message)
    }
  }

  async getFbCommentInfo(data) {
    return await axios.get(`${this.url}/comment_enables`, {params: data});
  }

  createLike(data) { return axios.post(`${this.url}/likes`, data) }

  deleteLike(data) { return axios.delete(`${this.url}/likes`, {params: data}) }

  setHide(data) { return axios.post(`${this.url}/is_hidden`, data) }

  sendPrivateReply(data) { return axios.post(`${this.url}/private_reply`, data) }


  //-----not needed----//

  get() {
    throw 'not implemented'
  }

  show() {
    throw 'not implemented'
  }

  create() {
    throw 'not implemented'
  }

  update() {
    throw 'not implemented'
  }

  delete() {
    throw 'not implemented'
  }
}
