import en from './locale/en';
import he from './locale/he';
import ar from './locale/ar';
import es from './locale/es';

export default {
  en,
  he,
  ar,
  es
};
