/* global axios */
import ApiClient from './ApiClient';

const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class ReportsAPI extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v2' });
  }

  getReports({
    fileType,
    metric,
    from,
    to,
    type = 'account',
    id,
    groupBy,
    businessHours,
  }) {
    const options = {
      params: {
        fileType,
        metric,
        since: from,
        until: to,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}`, options);
  }

  // eslint-disable-next-line default-param-last
  getSummary(
    since,
    until,
    type = 'account',
    id,
    groupBy,
    businessHours,
    fileType = 'csv'
  ) {
    const options = {
      params: {
        fileType,
        since,
        until,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/summary`, options);
  }

  getConversationMetric(type = 'account', page = 1, fileType = 'csv') {
    const options = {
      params: {
        fileType,
        type,
        page,
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/conversations`, options);
  }

  getAgentReports({
    fileType,
    from: since,
    to: until,
    businessHours,
    ...rest
  }) {
    const options = {
      params: {
        fileType,
        since,
        until,
        business_hours: businessHours,
        ...rest,
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/agents`, options);
  }

  getConversationReports({
    from: since,
    to: until,
    businessHours,
    fileType,
    ...rest
  }) {
    const options = {
      params: {
        fileType,
        since,
        until,
        business_hours: businessHours,
        ...rest,
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/conversation_report`, options);
  }

  getConversationTrafficCSV(fileType = 'csv') {
    const options = {
      params: { timezone_offset: getTimeOffset(), fileType },
    };

    if (fileType === 'xlsx') {
      options.responseType = 'blob';
    }
    return axios.get(`${this.url}/conversation_traffic`, options);
  }

  getLabelReports({ fileType, from: since, to: until, businessHours }) {
    const options = {
      params: {
        fileType,
        since,
        until,
        business_hours: businessHours,
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/labels`, options);
  }

  getInboxReports({
    fileType,
    from: since,
    to: until,
    businessHours,
    ...rest
  }) {
    const options = {
      params: {
        fileType,
        since,
        until,
        business_hours: businessHours,
        ...rest,
      },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/inboxes`, options);
  }

  getSlaReports({ fileType, from: since, to: until, businessHours, ...rest }) {
    const options = {
      params: {
        fileType,
        since,
        until,
        timezone_offset: getTimeOffset(),
        ...rest,
      },
    };

    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/sla_events`, options);
  }

  getTeamReports({ fileType, from: since, to: until, businessHours }) {
    const options = {
      params: { fileType, since, until, business_hours: businessHours },
    };
    if (fileType === 'xlsx') options.responseType = 'blob';
    return axios.get(`${this.url}/teams`, options);
  }
}

export default new ReportsAPI();
