<!-- eslint-disable vue/no-mutating-props -->
<template>
  <woot-modal :show.sync="show" :on-close="onCancel" class="outbound-visible">
    <div class="h-auto overflow-auto flex flex-col">
      <woot-modal-header
        :header-title="$t('EMAIL_TRANSCRIPT.TITLE')"
        :header-content="$t('EMAIL_TRANSCRIPT.DESC')"
      />
      <form class="w-full" @submit.prevent="onSubmit">
        <div class="row">
          <div class="medium-12 large-6 column">
            <label>
              {{ $t('PLATFORM.FORM.CHAT_PERIOD.LABEL') }}
            </label>
            <multiselect
                v-model="conversationPeriodType"
                :options="conversationPeriodTypes"
                :allow-empty="false"
                :show-labels="false"
                label="name"
                track-by="id"
            />
          </div>
          <div v-if="conversationPeriodType && conversationPeriodType.id === 'custom'"
               class="small-12 medium-12 large-6 column date-picker">
            <label>
              {{ $t('PLATFORM.FORM.DATE_FROM.LABEL') }}
              <date-picker
                  type="datetime"
                  :confirm="true"
                  :clearable="false"
                  :editable="false"
                  :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                  :class="{ error: $v.conversationPeriodFrom.$error }"
                  :value="conversationPeriodFrom"
                  @change="onChangeConversationPeriodFrom"
              />
              <span v-if="$v.conversationPeriodFrom.$error" class="editor-warning__message">
                {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.ERROR') }}
            </span>
            </label>
          </div>
          <div v-if="metaPeriodData.count >= 0" class="large-12 column">
            <div v-if="metaPeriodData.count > 0" class="row message-preview">
              <span style="margin-inline-end: 4px">{{ $t('PLATFORM.FORM.CHAT_PERIOD.PREVIEW.FROM_MESSAGE') }}:</span>
              <div class="" style="max-width: 60%; min-width: 6rem">
                        <span class="message--wrapper" v-tooltip.top="`${metaPeriodData.first.content}\n${messageStampFull(metaPeriodData.first.created_at)}`" >
                          {{metaPeriodData.first.content}}
                        </span>
              </div>
              <div v-if="metaPeriodData.count > 1">
                {{ $t('PLATFORM.FORM.CHAT_PERIOD.PREVIEW.TOTAL_MESSAGES', {count: metaPeriodData.count}) }}
              </div>


            </div>
            <div v-else class="message-preview" style="background-color: #f18080">
              {{ $t('PLATFORM.FORM.CHAT_PERIOD.PREVIEW.NO_MESSAGES') }}
            </div>

          </div>
        </div>
        <div class="w-full">
          <div v-if="currentChat.meta.sender && currentChat.meta.sender.email">
            <input
              id="contact"
              v-model="selectedType"
              type="radio"
              name="selectedType"
              value="contact"
            />
            <label for="contact">{{
              $t('EMAIL_TRANSCRIPT.FORM.SEND_TO_CONTACT')
            }}</label>
          </div>
          <div v-if="currentChat.meta.assignee">
            <input
              id="assignee"
              v-model="selectedType"
              type="radio"
              name="selectedType"
              value="assignee"
            />
            <label for="assignee">{{
              $t('EMAIL_TRANSCRIPT.FORM.SEND_TO_AGENT')
            }}</label>
          </div>
          <div>
            <input
              id="other_email_address"
              v-model="selectedType"
              type="radio"
              name="selectedType"
              value="other_email_address"
            />
            <label for="other_email_address">{{
              $t('EMAIL_TRANSCRIPT.FORM.SEND_TO_OTHER_EMAIL_ADDRESS')
            }}</label>
          </div>
          <div v-if="sentToOtherEmailAddress" class="w-[50%] mt-1">
            <label :class="{ error: $v.email.$error }">
              <input
                v-model.trim="email"
                type="text"
                :placeholder="$t('EMAIL_TRANSCRIPT.FORM.EMAIL.PLACEHOLDER')"
                @input="$v.email.$touch"
              />
              <span v-if="$v.email.$error" class="message">
                {{ $t('EMAIL_TRANSCRIPT.FORM.EMAIL.ERROR') }}
              </span>
            </label>
          </div>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-submit-button
            :button-text="$t('EMAIL_TRANSCRIPT.SUBMIT')"
            :disabled="!isFormValid"
          />
          <button class="button clear" @click.prevent="onCancel">
            {{ $t('EMAIL_TRANSCRIPT.CANCEL') }}
          </button>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import OnlineWaAPI from 'dashboard/api/onlinewa';
import DatePicker from 'vue2-datepicker';
import timeMixin from 'dashboard/mixins/time';

export default {
  mixins: [alertMixin, timeMixin],
  components: {
    DatePicker
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      email: '',
      selectedType: '',
      isSubmitting: false,
      metaPeriodData: {},
      conversationPeriodType: undefined,
      conversationPeriodFrom: new Date(),
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(4),
    },
    conversationPeriodFrom: {
    },
  },
  watch: {
    conversationPeriodType() {
      this.fetchMetaPeriodData()
    },
    conversationPeriodFrom() {
      this.fetchMetaPeriodData()
    },
  },
  mounted() {
    this.conversationPeriodType = this.conversationPeriodTypes[0];
  },
  computed: {
    conversationPeriodTypes() {
      return this.$t('PLATFORM.FORM.CHAT_PERIOD.TYPES')
    },
    conversationPeriodData() {
      return this.conversationPeriodType ? {
        type_id: this.conversationPeriodType?.id,
        from: this.conversationPeriodType?.id === 'custom' ? Math.floor(this.conversationPeriodFrom?.getTime()/1000) : undefined
      } : undefined
    },
    sentToOtherEmailAddress() {
      return this.selectedType === 'other_email_address';
    },
    isFormValid() {
      if (!this.metaPeriodData.count > 0) return false;

      if (this.selectedType) {
        if (this.sentToOtherEmailAddress) {
          return !!this.email && !this.$v.email.$error;
        }
        return true;
      }
      return false;
    },
    selectedEmailAddress() {
      const { meta } = this.currentChat;
      switch (this.selectedType) {
        case 'contact':
          return meta.sender.email;
        case 'assignee':
          return meta.assignee.email;
        case 'other_email_address':
          return this.email;
        default:
          return '';
      }
    },
  },
  methods: {
    async init() {
      this.selectedClient = undefined
      this.conversationPeriodType = undefined
      if (this.viewType === 'chat') this.conversationPeriodType = this.conversationPeriodTypes.find(x=>x.id === 'new')
    },
    onChangeConversationPeriodFrom(value) {
      this.conversationPeriodFrom = value
    },
    async fetchMetaPeriodData() {
      this.metaPeriodData = {}
      if (this.conversationPeriodType) {
        try {
          this.metaPeriodData = (await this.$store.dispatch('sendEmailTranscript', {
            conversationId: this.currentChat.id,
            period: this.conversationPeriodData,
            meta: true
          }))?.data || {}
        } catch (e) {
          this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
          console.error('chatbox_add-only-meta', e)
        }
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
    async onSubmit() {
      this.isSubmitting = false;
      try {
        await this.$store.dispatch('sendEmailTranscript', {
          email: this.selectedEmailAddress,
          conversationId: this.currentChat.id,
          period: this.conversationPeriodData,
        });
        this.showAlert(this.$t('EMAIL_TRANSCRIPT.SEND_EMAIL_SUCCESS'));
        this.onCancel();
      } catch (error) {
        this.showAlert(this.$t('EMAIL_TRANSCRIPT.SEND_EMAIL_ERROR'));
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  overflow: visible;
}

::v-deep input:disabled {
  border: unset;
}

.link-color--display {
  //border-radius: var(--border-radius-normal);
  height: var(--space-slab);
  width: var(--space-slab) !important;
  transform: rotate(45deg);
}

.footer {
  //margin-top: var(--space-medium);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;

}

.date-picker {
  margin-left: 0;
}

.message-preview {
  background: #e6e8ea;
  order-radius: var(--border-radius-normal);
  padding: 0.5rem;
  color: #444;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.message--wrapper {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: italic;
  font-weight: bold;
  width: 99%;
}
</style>
