<template>
  <div>
    <label>
      <span v-if="label">{{ label }}</span>
    </label>
    <img v-if="src" style="height: 80px" :src="src" />
    <div v-if="src && deleteImage" class="image-delete-btn">
      <woot-button
        color-scheme="alert"
        variant="hollow"
        size="tiny"
        @click="onDelete"
        >{{
          this.deleteTitle || $t('INBOX_MGMT.DELETE.BUTTON_TEXT')
        }}</woot-button
      >
    </div>
    <label>
      <input
        id="file"
        ref="file"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        @change="handleImageUpload"
      />
      <slot></slot>
    </label>
  </div>

</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    deleteImage: {
      type: Boolean,
      default: false,
    },
    deleteTitle: {
      type: String,
      default: '',
    },
  },
  watch: {},
  methods: {
    handleImageUpload(event) {
      const [file] = event.target.files;

      this.$emit('change', {
        file,
        url: URL.createObjectURL(file),
      });
    },
    onDelete() {
      this.$refs.file.value = null;
      this.$emit('onDelete');
    },
  },
};
</script>

<style lang="scss" scoped>
.image-delete-btn {
  margin-top: var(--space-smaller);
  margin-bottom: var(--space-smaller);
}
</style>
