<template>
  <div>
    <private-message
      v-for="message in privateMessages"
      :key="message.id"
      :inbox-name="message.inbox_name"
      :user="message.sender"
      :message="message.content"
      :created-at="message.created_at"
      :attachments="message.attachments"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrivateMessage from './components/PrivateMessage.vue';

export default {
  components: {
    PrivateMessage,
  },
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ privateMessages: 'contacts/getPrivateMessages' }),
  },
  mounted() {
    this.fetchPrivateMessages();
  },
  methods: {
    fetchPrivateMessages() {
      const { contactId: id } = this;
      this.$store.dispatch('contacts/privateMessages', { id });
    },
  },
};
</script>

<style lang="scss">
.private-message-wrap {
  .file--icon {
    color: var(--w-400);
  }
  .meta {
    .text-block-title {
      color: black;
    }

    h5 {
      font-size: 10px;
    }
    .download {
      color: var(--w-400);
    }
  }
  .modal-container {
    img {
      width: 500px !important;
    }
    video {
      height: 100% !important;
      width: 100% !important;
    }

    .modal--close {
      z-index: 2;
    }
  }
  .location .meta,
  .video {
    width: 100px;
  }
  .message-text__wrap {
    position: relative;
    max-height: 100px;
    margin: 10px;

    video,
    img {
      width: 100px;
    }
  }
}
</style>
