<template>
  <div class="medium-12 columns">
    <textarea
      v-model="processedString"
      rows="4"
      readonly
      class="template-input"
    />
    <div class="template__variables-container">
      <p v-if="variables" class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
      </p>
      <div
        v-for="(variable, key) in processedParams"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParams[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
      <div v-if="withWebsiteVariable" class="template__variable-item">
        <span class="variable-label">
          {{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_WEBSITE') }}
          {{ websiteVariable2 ? '#1' : '' }}
        </span>
        <woot-input
          v-model="websiteVariable"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
      <div v-if="withWebsiteVariable2" class="template__variable-item">
        <span class="variable-label">
          {{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_WEBSITE') }} #2
        </span>
        <woot-input
          v-model="websiteVariable2"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
      <div v-if="template.allowedAttachment" class="attachment-wrapper">
        <file-upload
          name="templates-file-upload"
          ref="upload-new_-conv"
          :size="4096 * 4096"
          :accept="template.allowedAttachment"
          :drop="false"
          :drop-directory="false"
          @input-file="onFileUpload"
        >
          <woot-button
            class-names="button--upload"
            :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            icon="ion-android-attach"
            emoji="📎"
            color-scheme="secondary"
            variant="smooth"
            size="small"
          />
        </file-upload>
        <bafi-file-attacher style="margin-inline-start: 1rem"
          :file-kinds-accepted-to-upload="template.allowedAttachment"
          :on-file-download="onFileUpload"/>
        <div v-if="hasAttachments || fileUploadProgresses.length" class="attachment-preview-box">
          <attachments-preview
            :progresses="fileUploadProgresses"
            :attachments="attachedFiles"
            :remove-attachment="removeAttachment"
          />
        </div>
      </div>
      <p v-if="$v.processedString.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.TEXT_SIZE_ERROR_MESSAGE', { waMaxSizeText, now: processedString.length }) }}
      </p>
      <p v-if="$v.$dirty && $v.$invalid && !$v.processedString.$invalid" class="error">
        {{ $v.processedParams.$invalid || $v.websiteVariable.$invalid ? $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') : $t('CONVERSATION.REPLYBOX.FILE_IS_REQUIRED')  }}
      </p>
    </div>
    <footer>
      <woot-button variant="smooth" @click.prevent="$emit('resetTemplate')">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>
      <woot-button :isDisabled="$v.processedString.$invalid" @click.prevent="sendMessage" :is-loading="$store.getters['contactConversations/getUIFlags'].isCreating">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL') }}
      </woot-button>
    </footer>
  </div>
</template>

<script>
import {checkFileSizeLimit} from "../../../../../shared/helpers/FileHelper";

const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};
const nonEmpty = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key].replace(/\s/g, '').length > 0);
};
import {requiredIf} from 'vuelidate/lib/validators';
import {DirectUpload} from "activestorage";
import BafiFileAttacher from "../../online99_platform/BafiFileAttacher";
import AttachmentsPreview from "../../AttachmentsPreview.vue";
import FileUpload from "vue-upload-component";
import alertMixin from 'shared/mixins/alertMixin';
import {mapGetters} from "vuex";
import { allowedSize } from '../../../../helper/fileAttached';
import { waMaxSizeText } from '../../../../helper/maxSizes';

export default {
  components: {BafiFileAttacher, AttachmentsPreview, FileUpload},
  mixins: [alertMixin],
  props: {
    template: {
      type: Object,
      default: () => {},
    },
    fileUrl: {
      type: String,
      default: undefined
    }
  },
  validations: {
    processedString: {
      maxLength: function() {
        const processedString = this.processedString;
        return processedString.length <= waMaxSizeText;
      },
    },
    processedParams: {
      requiredIfKeysPresent: requiredIf('variables'),
      allKeysRequired,
      nonEmpty,
    },
    websiteVariable: {
      requiredIfPresented: requiredIf(function () {
        return this.withWebsiteVariable
      }),
    },
    websiteVariable2: {
      requiredIfPresented: requiredIf(function () {
        return this.withWebsiteVariable2;
      }),
    },
    attachedFiles: {
      requiredIfPresented: function (value) {
        return !this.template.allowedAttachment || !!value.length
      },
    },
  },
  data() {
    return {
      processedParams: {},
      websiteVariable: undefined,
      websiteVariable2: undefined,
      attachedFiles: [],
      fileUploadProgresses: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    variables() {
      const variables = this.templateString.match(/{{([^}]+)}}/g);
      return variables;
    },
    templateString() {
      return this.template.text;
    },
    processedString() {
      return this.templateString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
    withWebsiteVariable() {
      return this.template.callToActionWebsiteType === 2;
    },
    withWebsiteVariable2() {
      return this.template.callToActionWebsiteType2 === 2;
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    waMaxSizeText() {
      return waMaxSizeText;
    },
  },
  mounted() {
    this.generateVariables();
  },
  methods: {
    sendMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      const [attachment] = this.attachedFiles;
      const file = attachment && (attachment.resource.file || attachment.blobSignedId)
      const payload = {
        message: this.processedString,
        private: false,
        file: file || (!attachment && this.fileUrl ? null : undefined), //null - remove
        contentAttributes: {
          template_info: {
            ...this.template,
            parameters: Object.entries(this.processedParams).map(x => {
              return {key: x[0], value: x[1]}
            }),
            websiteVariable: (this.withWebsiteVariable || undefined) && (this.websiteVariable || ''),
            websiteVariable2: (this.withWebsiteVariable2 || undefined) && (this.websiteVariable2 || '')
          }
        },
      };
      this.$emit('sendMessage', payload);
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    generateVariables() {
      const matchedVariables = this.templateString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this.processedParams = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
    },
    //attachments
    initAttachment() {
      if (this.fileUrl) {
        this.attachedFiles = [this.urlToAttachment(this.fileUrl)]
      }
    },
    onFileUpload(file) {
      if (true) { //todo
        this.onDirectFileUpload(file);
      } else {
        this.onIndirectFileUpload(file);
      }
    },
    onDirectFileUpload(file) {
      if (!file) {
        return;
      }
      const MAXIMUM_FILE_UPLOAD_SIZE = allowedSize(file);
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        const upload = new DirectUpload(
          file.file,
          `/api/v1/accounts/${this.accountId}/direct_uploads`,
          {
            directUploadWillCreateBlobWithXHR: xhr => {
              xhr.setRequestHeader(
                'api_access_token',
                this.currentUser.access_token
              );
            },

            directUploadWillStoreFileWithXHR: xhr => {
              xhr.upload.addEventListener("progress",
                event => {
                  this.fileUploadProgresses = [{id: 1, name: file.filename || file.name, value: event.loaded / event.total}]
                });

            }
          }
        );

        upload.create((error, blob) => {
          this.fileUploadProgresses = []
          if (error) {
            this.showAlert(error);
          } else {
            this.attachFile({ file, blob });
          }
        });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    onIndirectFileUpload(file) {
      if (!file) {
        return;
      }
      const MAXIMUM_FILE_UPLOAD_SIZE = allowedSize(file);
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ blob, file }) {
      this.attachedFiles = []; //d99d

      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    urlToAttachment(x) {
      return (typeof x === 'string') ?
        {
          resource: {
            file: undefined,
            name: decodeURI(x.substr(x.lastIndexOf('/') + 1)),
            type: ['jpg','jpeg','gif','bmp','png','tiff'].includes(x.substr(x.lastIndexOf('.') + 1))
              ? ['image'] : [],
            size:null
          },
          thumb: x,
          url: x
        } : x
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-wrapper {
  display: flex;
  margin-top: var(--space-normal);
  margin-bottom: var(--space-one);
}

::v-deep .file-uploads {
  label {
    cursor: pointer;
  }
  &:hover .button {
    background: var(--s-100);
  }
}

::v-deep .attachment-preview-box {
  padding: 0 var(--space-normal);
  background: transparent;

  .preview-item__wrap {
    margin-top: 0;
    .preview-item {
      margin-top: 0;
    }
  }
}
</style>

<style scoped lang="scss">
.template__variables-container {
  @apply p-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .label {
    @apply text-xs;
  }

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}

footer {
  @apply flex justify-end;

  button {
    @apply ml-2.5;
  }
}
.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
.template-input {
  @apply bg-slate-25 dark:bg-slate-900 text-slate-700 dark:text-slate-100;
}

</style>
