<template>
  <div v-if="isShown" class="reply--wrapper" :class="wrapClass">
    <div style="width: 100%">
      <div class="reply-body--wrapper" :class="{'incoming-bg': isIncoming}">
        <span class="vert-line"></span>
        <div class="reply-body">
          <div class="reply-content">
            <div class="reply-author">{{ referralName }}</div>
            <div class="reply-author referral-title">{{ referralTitle }}</div>
            <div dir="auto" class="reply-text">
              <letter class="text-content" :html="referralBody" />
            </div>
            <div>
              <letter class="text-content" :html="referralLink" />
            </div>
            <div>
              <letter class="text-content" :html="referralId" />
            </div>
            <div class="mb-1">
              <bubble-image
                v-if="imageUrl && !hasImageError"
                :url="imageUrl"
                @error="onImageLoadError"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MESSAGE_TYPE } from 'shared/constants/messages';
import Letter from 'vue-letter';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import BubbleImage from './Image.vue';

export default {
  name: "Referral",
  components: { Letter, BubbleImage },
  mixins: [messageFormatterMixin],
  props: {
    referralData: {},
    data: {},
  },
  data() {
    return {
      hasImageError: false,
    };
  },
  computed: {
    isShown() {
      return !!this.referralData
    },
    wrapClass() {
      return {
        'incoming-schema': this.referralIsIncoming,
        'outgoing-schema': !this.referralIsIncoming,
      }
    },
    referralName() {
      return this.referralData.ref_type === 'post'
        ? this.$t('CONVERSATION.REFERRAL_MESSAGE.TYPE.POST')
        : this.$t('CONVERSATION.REFERRAL_MESSAGE.TYPE.AD');
    },
    referralTitle() {
      return this.referralData.ref_title;
    },
    referralBody() {
      const text = this.$t('CONVERSATION.REFERRAL_MESSAGE.TITLE.BODY') + ': ' + this.referralData.ref_body;
      return this.prepareText(text);
    },
    referralLink() {
      const text = this.$t('CONVERSATION.REFERRAL_MESSAGE.TITLE.LINK') + ': ' + this.referralData.ref_link;
      return this.prepareText(text);
    },
    referralId() {
      const text = this.$t('CONVERSATION.REFERRAL_MESSAGE.TITLE.ID') + ': ' + this.referralData.ref_id;
      return this.prepareText(text);
    },
    referralIsIncoming() {
      return this.referralData.message_type === MESSAGE_TYPE.INCOMING;
    },
    isIncoming() {
      return this.data?.message_type === MESSAGE_TYPE.INCOMING;
    },
    imageUrl() {
      return this.referralData.ref_image_url || this.referralData.ref_image;
    },
  },
  methods: {
    prepareText(text) {
      return (
        this.formatMessage(
          text,
          this.isATweet,
          this.data.private,
          this.isAWhatsAppChannel,
        )
      );
    },
    onImageLoadError() {
      this.hasImageError = true;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables.scss';

div[dir=ltr] .reply--wrapper {
  margin: 0 -8px 6px -10px;
}
div[dir=rtl] .reply--wrapper {
  margin: 0 -10px 6px -8px;
}

.reply-body--wrapper {
  background-color: #0071d9;
  border-radius: 7.5px;
  position: relative;
  display: flex;
  overflow: hidden;

  &.incoming-bg {
    background-color: #edf6ff;
  }
}

.vert-line {
  flex: none;
  width: 4px;
}

div[dir=ltr] .reply-body {
  padding: 4px 12px 8px 8px;
}

div[dir=rtl] .reply-body {
  padding: 4px 8px 8px 12px;
}

.reply-body {
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 42px;
  overflow: hidden;
}

.reply-content {
  flex-grow: 1;
  overflow: hidden;
}

.reply-author {
  display: inline-flex;
  width: 100%;
  font-size: $font-size-small;
  font-weight: 700;
  line-height: 22px;
}

.reply-text {
  display: -webkit-box;
  overflow: hidden;
  font-size: $font-size-mini;
  line-height: 20px;
  color: var(--quoted-message-text);
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.outgoing-schema {
  .vert-line {
    background-color: #53bdeb;
  }

  .reply-author {
    color: #53bdeb;
  }

  .referral-title {
    color: black;
  }
}

.incoming-schema {
  .vert-line {
    background-color: #42b94c;
  }
  .reply-author {
    color: #42b94c;
  }
}
</style>
