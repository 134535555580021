<template>
    <div v-if="isAvailable">
        <link rel="stylesheet"href="/integrations/fontawesome/css/fontawesome.min.css">
        <link rel="stylesheet"href="/integrations/fontawesome/css/regular.min.css">
        <woot-button
                :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
                icon="attach"
                emoji="📎"
                color-scheme="secondary"
                variant="smooth"
                size="small"
                @click.prevent="onOpen"
        >{{$t('PLATFORM.BAFI_ATTACH_BUTTON_CATION')}}</woot-button>
    <woot-modal :show.sync="isOpenModal" :on-close="onCancel">
        <div class="column">
            <woot-modal-header
                    :header-title="$t('PLATFORM.BAFI_ATTACH_FILE')"
                    header-content=""
            />
            <form @submit.prevent="onSubmit" style="min-height: 30rem; position: relative">
                <div class="row">
                    <div class="small-12 medium-12 large-12 column">
                        <bafi-client-select
                                v-model="selectedClient"
                                :current-chat="currentChat"
                                :is-init.sync="isLoading"
                                :validation-error="$v.selectedClient.$error"/>
                    </div>
                    <div class="large-12 column" :class="{ error: $v.selectedFileFolder.$error }">
                        <label> {{ $t('PLATFORM.FORM.FILE_FOLDER.LABEL') }}</label>
                        <multiselect
                                v-model="selectedCat"
                                :options="fileFolders"
                                :allow-empty="true"
                                :loading="isFileFolderFetching"
                                open-direction="below"
                                :searchable="false"
                                :show-labels="false"
                                :show-no-results="false"
                                :placeholder="$t('PLATFORM.FORM.FILE_FOLDER.PLACEHOLDER')"
                                :disabled="!selectedClient || isFileFolderFetching"
                        >
                        </multiselect>
                        <span v-if="$v.selectedFileFolder.$error" class="message">{{ $t('WA_SETTINGS.TEMPLATES.ERROR_REQUIRED') }}</span>
                    </div>
                    <div class="large-12 column" :class="{ error: $v.selectedFile.$error }">
                        <label> {{ $t('PLATFORM.FORM.FILE.LABEL') }}</label>
                        <multiselect
                                v-model="selectedFile"
                                :options="files"
                                :allow-empty="false"
                                :loading="isFileFetching"
                                :show-labels="false"
                                :show-no-results="false"
                                open-direction="below"
                                label="file_name"
                                track-by="id"
                                :placeholder="$t('PLATFORM.FORM.FILE.PLACEHOLDER')"
                                :disabled="!selectedClient || isFileFetching"
                        >
                            <template slot="option" slot-scope="{ option }">
                                <div style="position: relative;">
                                <i class="fas"
                                   :class="option.icon && option.icon.icon || 'fa-file'"
                                   :style="{color: option.icon && option.icon.color || '#35495e'}"></i>
                                    <div style="position: absolute; top: 0; left: 2rem; right: 2rem;">{{option.file_name}}</div>
                                </div>
                            </template>
                        </multiselect>
                        <span v-if="$v.selectedFileFolder.$error" class="message">{{ $t('WA_SETTINGS.TEMPLATES.ERROR_REQUIRED') }}</span>
                    </div>
                </div>

                <div v-if="isLoading" class="dd-loader">
                    <spinner />
                </div>
                <div class="footer" style="margin-top: 3rem">
                    <woot-button variant="clear" @click.prevent="onCancel">
                        {{ $t('MERGE_CONTACTS.FORM.CANCEL') }}
                    </woot-button>
                    <woot-button type="submit" :is-loading="isSubmitting">
                        {{ $t('PLATFORM.FORM.ATTACH') }}
                    </woot-button>
                </div>
            </form>
        </div>
    </woot-modal>
    </div>
</template>

<script>

import ConversationToRemoteForm from "./ConversationToRemoteForm";
import alertMixin from "shared/mixins/alertMixin";
import OnlineWaAPI from 'dashboard/api/onlinewa'
import {required} from "vuelidate/lib/validators";
import BafiClientSelect from "./components/BafiClientSelect";
import {mapGetters} from "vuex";
import Spinner from 'shared/components/Spinner.vue';
import {isFileExtAllowed} from "../../../helper/commons";

export default {
  mixins: [alertMixin],
  components: {
    BafiClientSelect,
    ConversationToRemoteForm,
    Spinner
  },
  props: {
    fileKindsAcceptedToUpload: {
      type: String,
      default: undefined
    },
    onFileDownload: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isAvailable: online99Constants.platform_AttachFileFeature,
      isOpenModal: false,
      selectedClient: undefined,
      selectedCat: undefined,
      selectedFile: undefined,
      isSubmitting: false,
      fileFolders: [],
      files: [],
      isLoading: false,
      isClientSearching: false,
      isFileFolderFetching: false,
      isFileFetching: false,
    }
  },
  validations: {
    selectedClient: {
      required,
    },
    selectedFileFolder: {
    },
    selectedFile: {
      required,
    },
  },
  mounted() {
    //this.init()
  },
  computed: {
    ...mapGetters({currentChat: 'getSelectedChat'})
  },
  watch: {
    currentChat() {
      this.init()
    },
    selectedClient() {
      this.selectedCat = undefined
      this.selectedFile = undefined
      this.fetchFileFolders()
      this.fetchFiles()
    },
    selectedCat(val) {
      if (this.selectedFile?.file_cat !== val) {
        this.selectedFile = undefined
        this.files = [];
      }
      this.fetchFiles()
    },
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const fileName = this.selectedFile.file_name;

      const payload = {
        client_id: this.selectedClient.id,
        file_id: this.selectedFile.id
      }

      this.isSubmitting = true;

      try {
        const resp = await OnlineWaAPI.sendPlatformPsRequest('file_content', {...payload, options: {responseType:"blob"}});
        //let filename = decodeURI(resp.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/"/g, ''));

        const blob = resp.data;
        if (!blob) throw 'empty files_content reply...'
        const file = new File([blob], fileName, {type: blob?.type})

        //file.file = file; //trick
        this.onFileDownload({file, type: file.type, filename: file.name, size: file.size});
        this.onCancel();
      } catch (err) {
        console.error(err)
        this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
      } finally {
        this.isSubmitting = false;
      }
    },
    onOpen() {
      this.isOpenModal = true;
      this.init();
    },
    onCancel() {
      this.isOpenModal = false;
    },
    async init() {
      this.selectedClient = undefined
      this.selectedCat = undefined
      this.selectedFile = undefined
      this.$nextTick(() => { this.$v.$reset() })
    },
    async fetchFileFolders() {
      this.fileFolders = []
      this.isFileFolderFetching = true
      if (this.selectedClient) {
        try {
          const {data} = await OnlineWaAPI.sendPlatformPsRequest('file_folders', {client_id: this.selectedClient.id}) || {data: []}
          this.fileFolders = ['', ...data];
        } catch (e) {
          this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
          console.error('fileFolders', e)
        }
      }
      this.isFileFolderFetching = false
    },
    async fetchFiles() {
      if (this.selectedClient) {
        this.isFileFetching = true
        const payload = {
          client_id: this.selectedClient.id,
          file_cat: this.selectedCat || undefined,
        }
        try {
          const {data} = await OnlineWaAPI.sendPlatformPsRequest('files', payload) || {data: []}
          this.files = data?.map(x=>({...x, $isDisabled: !isFileExtAllowed(x.file_name, this.fileKindsAcceptedToUpload)}));
        } catch (e) {
          this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
          console.error('files', e)
        }
        this.isFileFetching = false
      } else {
        this.files = []
      }
    },
    tt(key, defaultValue) {
      return this.$te(key) ? this.$t(key) : defaultValue
    },
  },

}
</script>

<style lang="scss" scoped>

::v-deep {
  .modal-container {
    overflow: visible;
  }
}

</style>
