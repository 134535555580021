import { frontendURL } from '../../helper/URLHelper';
import BlankDashboard from "./BlankDashboard";
import NewContactAndConversation from "./NewContactAndConversation";
import ConversationView from "../dashboard/conversation/ConversationView.vue";

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/s'),
      component: BlankDashboard,
      children: [
        {
          path: 'conversations/:conversation_id',
          name: 'contact_conversation',
          roles: ['administrator', 'agent'],
          component: ConversationView,
          props: route => {
            return { conversationId: route.params.conversation_id, singleMode: true };
          },
        },
        {
          path: 'phone_contacts/:contact_phone/conversations',
          name: 'new_contact_conversation',
          roles: ['administrator', 'agent'],
          component: NewContactAndConversation,
          props: route => {
            return { contactPhone: route.params.contact_phone, contactName: route.query.contact_name };
          },
        },
      ],
    },
  ],
};
