<template>
  <banner
      v-if="reauthorizeTiktok"
      color-scheme="alert"
      :banner-message="$t('INBOX_MGMT.TIKTOK_REAUTHORIZE.BANNER.TEXT')"
      has-action-button
      :actionButtonLabel="reauthorizeTiktok.name"
      @click="onUpdateInbox"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import Banner from 'dashboard/components/ui/Banner';

export default {
  name: 'ReauthorizeInboxBanner',
  components: { Banner },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      tiktokInboxes: 'inboxes/getTiktokInboxes',
    }),
    reauthorizeTiktok() {
      const tiktoks = this.reauthorizeTiktoks();
      return tiktoks.length ? tiktoks[0] : null;
    }
  },
  watch: {
    currentUser() {
      this.setPasswordSnackbar();
    },
  },
  methods: {
    reauthorizeTiktoks() {
      return this.tiktokInboxes.filter(inbox => {
        return this.tokenIsExpired(inbox.token_expires);
      });
    },
    tokenIsExpired(tokenDate) {
      const unixDateNow = Math.floor(Date.now() / 1000);
      return unixDateNow > (tokenDate - 604800); //604800 one week
    },
    onUpdateInbox() {
      if (!this.reauthorizeTiktok) return;

      this.$router.push({
        name: 'settings_inbox_show',
        params: { inboxId: this.reauthorizeTiktok.id }
      });
    },
  },
};
</script>
