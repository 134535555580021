<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      v-if="hasCreatePermission('Campaigns')"
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ buttonText }}
    </woot-button>
    <campaign />
    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup" class="campaign-edit--modal"
                :close-on-backdrop-click="false" :close-on-escape="false">
      <edit-online-wa-mass-campaign v-if="isOnlineWaMassType" @on-close="hideAddPopup" />
      <add-campaign v-else @on-close="hideAddPopup" />
    </woot-modal>
  </div>
</template>

<script>
import campaignMixin from 'shared/mixins/campaignMixin';
import Campaign from './Campaign.vue';
import AddCampaign from './AddCampaign.vue';
import EditOnlineWaMassCampaign from "./wamass/EditOnlineWaMassCampaign";
import localeMixin from "../../../../../shared/mixins/localeMixin";
import hasPermissionMixin from 'dashboard/mixins/hasPermission';

export default {
  components: {
    EditOnlineWaMassCampaign,
    Campaign,
    AddCampaign,
  },
  mixins: [campaignMixin, localeMixin, hasPermissionMixin],
  data() {
    return { showAddPopup: false };
  },
  computed: {
    buttonText() {
      if (this.isOngoingType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONGOING');
      }
      if (this.isOnlineWaMassType) {
        return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONLINE_WA_MASS');
      }
      return this.$t('CAMPAIGN.HEADER_BTN_TXT.ONE_OFF');
    },
  },
  mounted() {
    this.$store.dispatch('campaigns/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>
