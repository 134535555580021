<template>
  <div
    v-if="!provider"
    class="wizard-body small-12 medium-9 columns height-auto"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.EMAIL_PROVIDER.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.EMAIL_PROVIDER.DESCRIPTION')"
    />
    <div class="row channel-list">
      <channel-selector
        v-for="emailProvider in emailProviderList"
        :key="emailProvider.key"
        :title="emailProvider.title"
        :src="emailProvider.src"
        @click="() => onClick(emailProvider.key)"
      />
    </div>
  </div>
  <microsoft v-else-if="provider === 'microsoft'" />
  <microsoft v-else-if="provider === 'microsoft_api'" :is-api="true" />
  <gmail v-else-if="provider === 'gmail'" />
  <forward-to-option v-else-if="provider === 'other_provider'" />
</template>
<script>
import ForwardToOption from './emailChannels/ForwardToOption.vue';
import Microsoft from './emailChannels/Microsoft.vue';
import Gmail from './emailChannels/Gmail.vue'; // d99d
import { mapGetters } from 'vuex';
import ChannelSelector from 'dashboard/components/ChannelSelector.vue';
import PageHeader from '../../SettingsSubPageHeader.vue';

export default {
  components: {
    ChannelSelector,
    ForwardToOption,
    Microsoft,
    Gmail, // d99d
    PageHeader,
  },
  data() {
    return { provider: '' };
  },
  computed: {
    ...mapGetters({ globalConfig: 'globalConfig/get' }),
    emailProviderList() {
      return [
        // { d99d commented out
        //   title: this.$t('INBOX_MGMT.EMAIL_PROVIDERS.MICROSOFT'),
        //   isEnabled: !!this.globalConfig.azureAppId,
        //   key: 'microsoft',
        //   src: '/assets/images/dashboard/channels/microsoft.png',
        // },
        { // d99d
          title: this.$t('INBOX_MGMT.EMAIL_PROVIDERS.MICROSOFT_API'),
          isEnabled: !!this.globalConfig.azureAppId,
          key: 'microsoft_api',
          src: '/assets/images/dashboard/channels/microsoft.png',
        },
        { // d99d
          title: this.$t('INBOX_MGMT.EMAIL_PROVIDERS.GMAIL'),
          isEnabled: !!this.globalConfig.gmailAppId,
          key: 'gmail',
          src: '/assets/images/dashboard/channels/gmail.png',
        },
        {
          title: this.$t('INBOX_MGMT.EMAIL_PROVIDERS.OTHER_PROVIDERS'),
          isEnabled: true,
          key: 'other_provider',
          src: '/assets/images/dashboard/channels/email.png',
        },
      ].filter(provider => provider.isEnabled);
    },
  },
  methods: {
    onClick(provider) {
      this.provider = provider;
    },
  },
};
</script>

<style scoped>
.channel-list {
  margin-top: var(--space-medium);
}
</style>
