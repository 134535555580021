<template>
  <li class="mt-1 export-wizard-nav-item">
    <a
      @click.prevent="toggleExportModat"
      class="rounded-lg leading-4 font-medium flex items-center p-2 m-0 text-sm text-slate-700 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-800 hover:text-slate-700 dark:hover:text-slate-100"
    >
      <fluent-icon
        icon="arrow-download"
        class="min-w-[1rem] mr-1.5 rtl:mr-0 rtl:ml-1.5"
        size="14"
      />
      {{ $t('EXPORT_WIZARD.TITLE') }}
    </a>
    <woot-modal
      :show.sync="showWizard"
      :on-close="closeWizard"
      size="modal-big"
    >
      <export-wizard v-if="showWizard" />
    </woot-modal>
  </li>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import ExportWizard from 'dashboard/components/widgets/ExportWizard/Index.vue';

export default {
  components: { ExportWizard },
  mixins: [clickaway],
  data() {
    return {
      showWizard: false,
    };
  },
  methods: {
    toggleExportModat() {
      this.showWizard = !this.showWizard;
    },
    closeWizard() {
      this.showWizard = false;
    },
  },
};
</script>

<style scoped lang="scss">
.export-wizard-nav-item {
  @apply relative flex;
}
</style>
<style lang="scss">
.export-wizard-nav-item .modal-container {
  @apply max-h-[90%];
}
</style>
