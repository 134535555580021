<template>
  <div class="row content-box full-height" style="align-content: start;">
    <div class="row">
      <div class="medium-6 columns">
        <h3>{{ $t('INTEGRATION_SETTINGS.MOBILE_APP.TITLE') }}</h3>
        <h5>{{ $t('INTEGRATION_SETTINGS.MOBILE_APP.SUBTITLE') }}</h5>
      </div>
    </div>
    <div class="row" style="margin-top: 5rem;">
      <a href="https://apps.apple.com/app/id1598783695" target="_blank" rel="noopener noreferrer" class="small-2 columns">
        <img src="https://d33wubrfki0l68.cloudfront.net/814d5384ef54c6996e1ebb76c103acd448eaf018/a45fc/images/home/mobile-apps/app-store.png" alt="Apple app store" class="w-full h-auto" data-v-1d09e88b="">
      </a>
      <a href="https://play.google.com/store/apps/details?id=co.il.digital99.chat" target="_blank" rel="noopener noreferrer" class="small-2 columns">
        <img src="https://d33wubrfki0l68.cloudfront.net/fb32fa6315ef7b4721fce6f662fdf10f62a3a2a5/05af7/images/home/mobile-apps/play-store.png" alt="Google play store" class="w-full h-auto" data-v-1d09e88b="">
      </a>
    </div>

  </div>
</template>

<script>
export default {
  name: "MobileApp"
}
</script>

<style scoped>

</style>
