<script setup>
import { format, fromUnixTime } from 'date-fns';

defineProps({
  label: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
});
const formatDate = timestamp =>
  format(fromUnixTime(timestamp), 'MMM dd, yyyy, hh:mm a');

/**
 * Formats the SLA time in a human-readable format.
 * @param {number} seconds - The time in seconds.
 * @returns {string} A formatted string representing the time.
 */
const formatSLATime = seconds => {
  const units = {
    y: 31536000, // 60 * 60 * 24 * 365
    mo: 2592000, // 60 * 60 * 24 * 30
    d: 86400, // 60 * 60 * 24
    h: 3600, // 60 * 60
    m: 60,
  };

  if (seconds < 60) {
    return '1m';
  }

  // we will only show two parts, two max granularity's, h-m, y-d, d-h, m, but no seconds
  const parts = [];

  Object.keys(units).forEach(unit => {
    const value = Math.floor(seconds / units[unit]);
    if (seconds < 60 && parts.length > 0) return;
    if (parts.length === 2) return;
    if (value > 0) {
      parts.push(value + unit);
      seconds -= value * units[unit];
    }
  });

  return parts.join(' ');
};
</script>
<template>
  <div class="flex justify-between w-full">
    <span
      class="text-sm sticky top-0 h-fit font-normal tracking-[-0.6%] min-w-[140px] truncate text-slate-600 dark:text-slate-200"
    >
      {{ label }}
    </span>
    <div class="flex flex-col w-full gap-2">
      <span
        v-for="item in items"
        :key="item.id"
        class="text-sm font-normal text-slate-900 dark:text-slate-25 text-right tabular-nums"
      >
        <span class="flex justify-between">
          {{ formatDate(item.created_at) }}
          <span v-if="item.delay_duration && item.delay_duration > 0" class="ms-1 whitespace-nowrap">
            ({{ formatSLATime(item.delay_duration) }}
            {{ $t('SLA.EVENTS.OVERDUE') }})
          </span>
        </span>
      </span>
      <slot name="showMore" />
    </div>
  </div>
</template>
