<template>
  <div
    class="fixed outline-none z-[9999] cursor-pointer"
    :style="style"
    tabindex="0"
    @blur="$emit('close')"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      left: this.x,
      top: this.y,
      show: false,
      menuHeight: 50,
    };
  },
  computed: {
    isRTL() {
      return this.$root.$i18n.locale === 'he';
    },
    style() {
      let top = this.top;
      if (this.top + this.menuHeight > window.innerHeight) {
        top = window.innerHeight - this.menuHeight - 10
      }
      return {
        top: top + 'px',
        left: this.left + 'px',
      };
    },
  },
  mounted() {
    this.updateStyle();
    this.$nextTick(() => this.$el.focus());
  },
  methods: {
    updateStyle() {
      this.$nextTick(() => {
        if (this.$el) {
          const rect = this.$el.getBoundingClientRect();
          this.menuHeight = rect.height;
          if (this.isRTL) {
            this.left = this.x - rect.width;
          }
        }
      });
    },
  },
};
</script>
