var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col md:flex-row justify-between items-start md:items-center"},[_c('div',{staticClass:"flex items-center justify-start m-0 mx-4 flex-1"},[_c('img',{staticClass:"p-2 h-16 w-16 mr-4",attrs:{"src":'/dashboard/images/integrations/' + _vm.integrationLogo}}),_vm._v(" "),_c('div',[_c('h3',{staticClass:"text-xl text-slate-800 dark:text-slate-100"},[_vm._v("\n        "+_vm._s(_vm.integrationName)+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.useInstallationName(
            _vm.integrationDescription,
            _vm.globalConfig.installationName
          ))+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-center items-center mb-0 w-[15%]"},[_c('router-link',{attrs:{"to":_vm.frontendURL(
          `accounts/${_vm.accountId}/${_vm.integrationPath || 'settings/integrations'}/` + _vm.integrationId
        )}},[(_vm.integrationEnabled)?_c('div',[(_vm.integrationAction === 'disconnect')?_c('div',[_c('div',{on:{"click":_vm.openDeletePopup}},[_c('woot-submit-button',{attrs:{"button-text":_vm.actionButtonText ||
                _vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT'),"button-class":"smooth alert"}})],1)]):_c('div',[_c('button',{staticClass:"button nice",attrs:{"disabled":_vm.disabled}},[_vm._v("\n            "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.WEBHOOK.CONFIGURE'))+"\n          ")])])]):_vm._e()]),_vm._v(" "),(!_vm.integrationEnabled)?_c('div',[_c('a',{staticClass:"button success nice",attrs:{"href":_vm.integrationAction}},[_vm._v("\n        "+_vm._s(_vm.$t('INTEGRATION_SETTINGS.CONNECT.BUTTON_TEXT'))+"\n      ")])]):_vm._e()],1),_vm._v(" "),_c('woot-delete-modal',{attrs:{"show":_vm.showDeleteConfirmationPopup,"on-close":_vm.closeDeletePopup,"on-confirm":_vm.confirmDeletion,"title":_vm.deleteConfirmationText.title ||
      _vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE'),"message":_vm.deleteConfirmationText.message ||
      _vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE'),"confirm-text":_vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES'),"reject-text":_vm.$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO')},on:{"update:show":function($event){_vm.showDeleteConfirmationPopup=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }