import { render, staticRenderFns } from "./Microsoft.vue?vue&type=template&id=cc06d794&scoped=true&"
import script from "./Microsoft.vue?vue&type=script&lang=js&"
export * from "./Microsoft.vue?vue&type=script&lang=js&"
import style0 from "./Microsoft.vue?vue&type=style&index=0&id=cc06d794&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc06d794",
  null
  
)

export default component.exports