export default {
  computed: {
    smsPackageOptions() {
      return [
        {
          title: this.$t('INBOX_MGMT.ADD.SMS.PACKAGE.OPTIONS.LIGHT'),
          value: 1,
        },
        {
          title: this.$t('INBOX_MGMT.ADD.SMS.PACKAGE.OPTIONS.PREMIUM'),
          value: 2,
        },
        {
          title: this.$t('INBOX_MGMT.ADD.SMS.PACKAGE.OPTIONS.ENTERPRISE'),
          value: 3,
        },
      ];
    },
  },
};
