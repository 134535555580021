<template>
  <banner
      v-if="showPasswordSnackbar"
      color-scheme="alert"
      :banner-message="$t('PROFILE_SETTINGS.CHANGE_PASSWORD_ALERT.BODY')"
      has-action-button
      :actionButtonLabel="$t('PROFILE_SETTINGS.CHANGE_PASSWORD_ALERT.CHANGE_PASSWORD')"
      has-close-button
      :close-button-text="$t('PROFILE_SETTINGS.CHANGE_PASSWORD_ALERT.CANCEL')"
      @close="hidePasswordSnackbar"
      @click="onChangePassword"
  />
</template>
<script>
import { mapGetters } from 'vuex';
import Banner from 'dashboard/components/ui/Banner';

export default {
  components: { Banner },
  data() {
    return {
      showPasswordSnackbar: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    })
  },
  watch: {
    currentUser() {
      this.setPasswordSnackbar();
    },
  },
  methods: {
    setPasswordSnackbar() {
      if (this.currentUser.sso_auth || !this.currentUser.password_attr || this.currentUser.password_attr.reset) return;
      if (!this.currentUser.password_attr.pass_expired) {
        this.setPasswordNotice({});
        return;
      }

      const data = { notice_count: this.currentUser.password_attr?.notice_count, show: true }
      if (data.notice_count === 0) return;

      if (!this.passwordNotice() || (this.passwordNotice().notice_count !== data.notice_count)) {
        this.setPasswordNotice(data);
        this.showPasswordSnackbar = true;
        return;
      }
      if (this.passwordNotice().show) this.showPasswordSnackbar = true;
    },
    hidePasswordSnackbar() {
      let data = this.passwordNotice();
      data.show = false;
      this.setPasswordNotice(data);
      this.showPasswordSnackbar = false;
    },
    setPasswordNotice(data) {
      localStorage.setItem('password_notice', JSON.stringify(data));
    },
    passwordNotice() {
      const passwordNotice = localStorage.getItem('password_notice');
      return JSON.parse(passwordNotice);
    },
    onChangePassword() {
      this.$router.push({
        name: 'profile_settings_index',
        hash: '#changePassword',
      });
    },
  },
};
</script>
