import OnlineFbAPI from '../../../../api/onlinefb';

export default {
  async getCommentInfo(_, payloacd) {
    try {
      const {data} = await OnlineFbAPI.getFbCommentInfo(payloacd);
      return data;
    } catch (error) {
      console.error('getCommentInfo', error)
      return {}
    }
  },

  createLike(_, payload) { return OnlineFbAPI.createLike(payload)},
  deleteLike(_, payload) { return OnlineFbAPI.deleteLike(payload)},

  hide(_, payload) { return OnlineFbAPI.setHide({...payload, value: true}) },
  unhide(_, payload) { return OnlineFbAPI.setHide({...payload, value: false}) },
};
