<template>
  <div class="ez-contact-filters">
    <page-header
      class="mb-6"
      :header-title="$t('EXPORT_WIZARD.STEP_2.CONTACTS.TITLE')"
      :header-content="$t('EXPORT_WIZARD.STEP_2.CONTACTS.DESC')"
    />
    <contacts-advanced-filters
      ref="contactsAdvancedFilters"
      :initial-filter-types="contactFilterItems"
      :initial-applied-filters="appliedFilter"
      :skip-header="true"
      :skip-buttons="true"
      @onFiltersChange="onFiltersChange"
    />
  </div>
</template>

<script>
import ContactsAdvancedFilters from '../../../routes/dashboard/contacts/components/ContactsAdvancedFilters.vue';
import contactFilterItems from '../../../routes/dashboard/contacts/contactFilterItems';
import PageHeader from '../../../routes/dashboard/settings/SettingsSubPageHeader.vue';

export default {
  components: {
    PageHeader,
    ContactsAdvancedFilters,
  },
  props: {
    filters: { type: Array, default: () => [] },
  },
  data() {
    return {
      contactFilterItems: contactFilterItems.map(filter => ({
        ...filter,
        attributeName: this.$t(
          `CONTACTS_FILTER.ATTRIBUTES.${filter.attributeI18nKey}`
        ),
      })),
      appliedFilter: this.filters.length && this.filters || [
        {
          attribute_key: 'phone_number',
          attribute_model: 'standard',
          filter_operator: 'is_present',
          values: [],
          custom_attribute_type: '',
          inputType: 'plain_text',
          query_operator: 'or',
        },
        {
          attribute_key: 'email',
          filter_operator: 'is_present',
          values: [],
          custom_attribute_type: '',
          inputType: 'plain_text',
          query_operator: 'and',
        },
      ],
    };
  },
  methods: {
    onFiltersChange(filters) {
      this.$emit('onFiltersChange', filters);
    },
    isFiltersValid() {
      return this.$refs.contactsAdvancedFilters.isFiltersValid();
    },
  },
};
</script>


<style lang="scss">
.ez-contact-filters {
  .contact-advanced-filters > div {
    padding: 0;
    @apply mb-4;
  }
}
</style>
