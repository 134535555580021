<template>
    <div :class="{ error: validationError}">
        <label class="typo__label">
            {{$t('PLATFORM.FORM.CLIENT.LABEL')}}
        </label>
        <multiselect
                v-model="selectedClient"
                :options="clients"
                :searchable="true"
                :allow-empty="false"
                :internal-search="false"
                :loading="isClientSearching"
                :show-labels="false"
                track-by="id"
                :options-limit="100"
                :show-no-results="false"
                :custom-label="getClientOptionLabel"
                :placeholder="$t('PLATFORM.FORM.CLIENT.PLACEHOLDER')"
                @search-change="searchClientChange"
                @close="stopSearch"
                :disabled="isInit"
        >
            <template slot="noOptions">{{$t('PLATFORM.FORM.CLIENT.NO_RESULT')}}</template>
        </multiselect>
        <span v-if="validationError" class="message">{{ $t('WA_SETTINGS.TEMPLATES.ERROR_REQUIRED') }}</span>
    </div>

</template>

<script>

import alertMixin from "shared/mixins/alertMixin";
import OnlineWaAPI from 'dashboard/api/onlinewa'

export default {
  mixins: [alertMixin],
  props: {
    value: {
      type: Object
    },
    currentChat: {
      type: Object,
      default: () => ({}),
    },
    validationError: {
      type: Boolean
    },
    isInit: Boolean,
  },
  data() {
    return {
      selectedClient: undefined,
      clients: [],
      isClientSearching: false,
    }
  },
  mounted() {
    this.selectedClient = this.value;
    this.init()
  },
  computed: {
    simplifiedPhone() {
      return this.phoneNumber?.replace(/^\+9725/, '05')
    },
    phoneNumber() {
      return this.currentChat?.meta?.sender?.phone_number
    },
  },
  watch: {
    currentChat() {
      this.init()
    },
    selectedClient(val) {
      if (val?.id !== this.value?.id) {
        this.$emit('input', this.selectedClient)
      }
    },
  },
  methods: {
    getClientOptionLabel ({ name, id_card_number }) {
      return name
    },
    searchClientChange(query) {
      if (this.typingTimer) {
        clearTimeout(this.typingTimer);
      }

      this.typingTimer = setTimeout(async () => {
        this.isClientSearching = true
        const typingTimer = this.typingTimer
        try {
          if (query && query.length >= 3) {
            const clients = (await OnlineWaAPI.sendPlatformPsRequest('search', {search: query}))?.data || []
            if (typingTimer === this.typingTimer) this.clients = clients //outdated data, waits newer request
          } else {
            this.clients = this.selectedClient ? [this.selectedClient] : []
          }
        } catch (e) {
          this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
          console.error('search', e)
        }
        this.isClientSearching = false
      }, 600);

    },
    stopSearch() {
      this.isClientSearching = false
      if (this.typingTimer) {
        clearTimeout(this.typingTimer);
      }
    },
    async init() {
      this.$emit('update:is-init', true)
      this.selectedClient = undefined
      this.clients = []
      //this.isClientSearching = true
      try {
        if (this.phoneNumber) {
          this.clients = (await OnlineWaAPI.sendPlatformPsRequest('search', {search_phone: this.simplifiedPhone}))?.data || []
          if (this.clients?.length === 1) this.selectedClient = this.clients[0]
        }
      } catch (e) {
        this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
        console.error('search', e)
      }

      //this.isClientSearching = false
      this.$emit('update:is-init', false)
    },
    tt(key, defaultValue) {
      return this.$te(key) ? this.$t(key) : defaultValue
    },
  },

}
</script>

