import { frontendURL } from '../../../../helper/URLHelper';
import store from 'dashboard/store';
const contacts = accountId => ({
  parentNav: 'contacts',
  routes: [
    'contacts_dashboard',
    'contact_profile_dashboard',
    'contacts_segments_dashboard',
    'contacts_labels_dashboard',
  ],
  menuItems: [
    {
      icon: 'contact-card-group',
      label: 'ALL_CONTACTS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/contacts`),
      toStateName: 'contacts_dashboard',
      onClick: () => {
        store.commit('contacts/SET_SEARCH_QUERY', '');
        return store.dispatch('contacts/get', {
          sortAttr: '-last_activity_at',
        });
      },
    },
  ],
});

export default contacts;
