<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.SMS.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.SMS.DESC')"
    />
    <form class="row" @submit.prevent="createChannel()">
      <div class="medium-8 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.SMS.PACKAGE.LABEL') }}
        </label>
        <select v-model="smsPackage">
          <option
            v-for="option in smsPackageOptions"
            :key="option.value"
            :value="option.value"
          >{{ option.title }}</option>
        </select>
        <p v-if="smsPackage" class="help-text">{{ message }}</p>
      </div>
      <div class="medium-8 columns">
        <label :class="{ error: $v.channelName.$error }">
          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
          <input
            v-model.trim="channelName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
            "
            @blur="$v.channelName.$touch"
          />
          <span v-if="$v.channelName.$error" class="message">{{
            $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>
      <div class="medium-8 columns">
        <label :class="{ error: $v.phoneNumber.$error }">
          {{ $t('INBOX_MGMT.ADD.SMS.PHONE_NUMBER.LABEL') }}
          <select v-model="phoneNumber" @blur="$v.phoneNumber.$touch">
            <option value="">{{ $t(`INBOX_MGMT.ADD.SMS.PHONE_NUMBER.PLACEHOLDER${phoneNumberOptions && phoneNumberOptions.length ? '' : '_EMPTY'}`) }}</option>
            <option v-for="option in phoneNumberOptions" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
          <span v-if="$v.phoneNumber.$error" class="message">{{
            $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
          }}</span>
        </label>
      </div>
<!--      <div class="medium-8 columns">-->
<!--        <label :class="{ error: $v.webhookUrl.$error }">-->
<!--          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.LABEL') }}-->
<!--          <input-->
<!--            v-model.trim="webhookUrl"-->
<!--            type="text"-->
<!--            :placeholder="$t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.PLACEHOLDER')"-->
<!--            @blur="$v.webhookUrl.$touch"-->
<!--          />-->
<!--        </label>-->
<!--        <p class="help-text">-->
<!--          {{ $t('INBOX_MGMT.ADD.API_CHANNEL.WEBHOOK_URL.SUBTITLE') }}-->
<!--        </p>-->
<!--      </div>-->

      <div class="medium-12 columns">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.SMS.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
const DEFAULT_SMS_PACKAGE_VALUE = 1;

import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import { LIMIT_MESSAGE } from 'shared/constants/inbox';
import smsPackageOptionsMixin from 'shared/mixins/inboxSmsPackageOptionsMixin';

const shouldBeWebhookUrl = (value = '') =>
  value ? value.startsWith('http') : true;

export default {
  name: 'ApiSms',
  components: {
    PageHeader,
  },
  mixins: [alertMixin, smsPackageOptionsMixin],
  props: {
    title: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    params: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      channelName: '',
      webhookUrl: '',
      smsPackage: DEFAULT_SMS_PACKAGE_VALUE,
      phoneNumber: '',
      phoneNumberOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    message() {
      return this.$t('INBOX_MGMT.ADD.SMS.PACKAGE.LIMIT_MESSAGE', {
        limit: LIMIT_MESSAGE[this.smsPackage],
      });
    },
  },
  validations: {
    channelName: { required },
    phoneNumber: { required },
    webhookUrl: { shouldBeWebhookUrl },
  },
  mounted() {
    this.fetchPhones();
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
          name: this.channelName,
          channel: {
            type: 'sms',
            webhook_url: this.webhookUrl,
            phone_number: this.phoneNumber,
            provider: '99digital',
            sms_package: this.smsPackage,
            ...this.params || {},
          },
        });

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: apiChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
      }
    },
    async fetchPhones() {
      this.phoneNumberOptions = await this.$store.dispatch('inboxes/getPhones');
    },
  },
};
</script>
