/* global axios */
import ApiClient, {Utility} from './ApiClient';

export const buildCreatePayload = (data) => {
  let payload;
  const {file, ...message} = data.message || {}
  if (file) {
    data.message = message;
    payload = new FormData();
    payload.append('message[attachments][]', file);
    Utility.convertModelToFormData(data, payload,'', 'conversation')
  } else {
    payload = data;
  }
  return payload;
};

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create(data) {
    return axios.post(this.url, buildCreatePayload(data));
  }

  getLabels(conversationID) {
    return axios.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID, labels) {
    return axios.post(`${this.url}/${conversationID}/labels`, { labels });
  }
}

export default new ConversationApi();
