import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUserRole: 'getCurrentRole',
      currentChat: 'getSelectedChat',
    }),
    currentInboxId() {
      return this.$route.params.inbox_id;
    },
    permissions() {
      return this.$store.getters['getInboxPermissions'];
    },
    isAdmin() {
      return this.currentUserRole === 'administrator';
    },
  },
  methods: {
    hasInboxPermission(permissionName, inboxId = null) {
      inboxId = inboxId || this.currentChat?.inbox_id || this.currentInboxId || this.inboxId;
      if (!inboxId) {
        return true;
      }
      const isAdmin = this.currentUserRole === 'administrator';
      const permission = this.$store.getters['getInboxPermissions']?.find(
        p => p.name === permissionName
      );
      if (
        !isAdmin &&
        permission &&
        !permission.ids.includes(parseInt(inboxId))
      ) {
        return false;
      }
      return true;
    },
    hasCreatePermission(permissionController) {
      if (this.isAdmin || !this.permissions) {
        return true;
      }
      const permission = this.permissions.find(
        p => p.name === permissionController
      );
      if (!permission || !permission.can_create) {
        return false;
      }
      return true;
    },
    hasDeletePermission(permissionController) {
      if (this.isAdmin || !this.permissions) {
        return true;
      }
      const permission = this.permissions.find(
        p => p.name === permissionController
      );
      if (!permission || !permission.can_delete) {
        return false;
      }
      return true;
    },
    hasEditPermission(permissionController) {
      if (this.isAdmin || !this.permissions) {
        return true;
      }
      const permission = this.permissions.find(
        p => p.name === permissionController
      );
      if (!permission || !permission.can_update) {
        return false;
      }
      return true;
    },
    hasReadPermission(permissionController) {
      if (this.isAdmin || !this.permissions) {
        return true;
      }
      const permission = this.permissions.find(
        p => p.name === permissionController
      );
      if (permission && !permission.can_read) {
        return false;
      }
      return true;
    },
    permittedActions(permissionController) {
      const actions = [];
      if (this.hasCreatePermission(permissionController)) {
        actions.push('create');
      }
      if (this.hasReadPermission(permissionController)) {
        actions.push('read');
      }
      if (this.hasEditPermission(permissionController)) {
        actions.push('edit');
      }
      if (this.hasDeletePermission(permissionController)) {
        actions.push('delete');
      }
      return actions;
    },
  },
};
