var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-text--metadata"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.getStatusTimes,
      html: true,
    }),expression:"{\n      content: getStatusTimes,\n      html: true,\n    }"}],staticClass:"time",class:{
      'cursor-pointer': _vm.historyPresent,
      'has-status-icon':
        _vm.showSentIndicator || _vm.showDeliveredIndicator || _vm.showReadIndicator,
      [`status-${_vm.statusOutgoing}`]: !!_vm.statusOutgoing, // d99d
    },attrs:{"dir":"ltr"}},[_vm._v("\n    "+_vm._s(_vm.readableTime)+"\n  ")]),_vm._v(" "),(_vm.externalError)?_c('span',{staticClass:"read-indicator-wrap"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.externalError),expression:"externalError",modifiers:{"top-start":true}}],staticClass:"action--icon",attrs:{"icon":"error-circle","size":"14"}})],1):_vm._e(),_vm._v(" "),(_vm.showReadIndicator || (_vm.isOutgoing && _vm.isA99digitalSmsInbox))?_c('span',{staticClass:"read-indicator-wrap"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.$t('CHAT_LIST.MESSAGE_READ')),expression:"$t('CHAT_LIST.MESSAGE_READ')",modifiers:{"top-start":true}}],staticClass:"action--icon read-tick read-indicator",attrs:{"icon":"checkmark-double","size":"14"}})],1):(_vm.showDeliveredIndicator)?_c('span',{staticClass:"read-indicator-wrap"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.$t('CHAT_LIST.DELIVERED')),expression:"$t('CHAT_LIST.DELIVERED')",modifiers:{"top-start":true}}],staticClass:"action--icon read-tick",attrs:{"icon":"checkmark-double","size":"14"}})],1):(_vm.showSentIndicator)?_c('span',{staticClass:"read-indicator-wrap"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.$t('CHAT_LIST.SENT')),expression:"$t('CHAT_LIST.SENT')",modifiers:{"top-start":true}}],staticClass:"action--icon read-tick",attrs:{"icon":"checkmark","size":"14"}})],1):_vm._e(),_vm._v(" "),(_vm.isEmail)?_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.$t('CHAT_LIST.RECEIVED_VIA_EMAIL')),expression:"$t('CHAT_LIST.RECEIVED_VIA_EMAIL')",modifiers:{"top-start":true}}],staticClass:"action--icon",attrs:{"icon":"mail","size":"16"}}):_vm._e(),_vm._v(" "),(_vm.delayHours)?_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
      _vm.$t('CONVERSATION.REPLYBOX.SENDING_BY', {
        date: _vm.messageStampFull(_vm.createdAt + _vm.delayHours * 3600 + 350),
      })
    ),expression:"\n      $t('CONVERSATION.REPLYBOX.SENDING_BY', {\n        date: messageStampFull(createdAt + delayHours * 3600 + 350),\n      })\n    ",modifiers:{"top":true}}],staticClass:"action--icon time clock",attrs:{"icon":"ion-clock"}}):_vm._e(),_vm._v(" "),(_vm.isPrivate)?_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.$t('CONVERSATION.VISIBLE_TO_AGENTS')),expression:"$t('CONVERSATION.VISIBLE_TO_AGENTS')",modifiers:{"top-start":true}}],staticClass:"action--icon lock--icon--private",attrs:{"icon":"lock-closed","size":"16"},on:{"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}}):_vm._e(),_vm._v(" "),(_vm.isATweet && (_vm.isOutgoing || _vm.isIncoming) && _vm.linkToTweet)?_c('a',{attrs:{"href":_vm.linkToTweet,"target":"_blank","rel":"noopener noreferrer nofollow"}},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(_vm.$t('CHAT_LIST.VIEW_TWEET_IN_TWITTER')),expression:"$t('CHAT_LIST.VIEW_TWEET_IN_TWITTER')",modifiers:{"top-start":true}}],staticClass:"cursor-pointer action--icon",attrs:{"icon":"open","size":"16"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }