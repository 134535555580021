<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="pageTitle"
      :header-warn="$t('CAMPAIGN.ADD.WARNING')"
    />
    <form class="row" @submit.prevent="saveCampaign">
      <div class="medium-12 columns">
        <woot-input
          v-model="title"
          :label="$t('CAMPAIGN.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="$v.title.$error ? $t('CAMPAIGN.ADD.FORM.TITLE.ERROR') : ''"
          :placeholder="$t('CAMPAIGN.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />
        <label :class="{ error: $v.selectedInboxId.$error }">
            {{ $t('CAMPAIGN.ADD.FORM.INBOX.LABEL') }}
            <select v-model="selectedInboxId" @change="onChangeInbox($event)">
                <option v-for="item in inboxes" :key="item.name" :value="item.id">
                    {{ item.name }}
                </option>
            </select>
            <span v-if="$v.selectedInboxId.$error" class="message">
          {{ $t('CAMPAIGN.ADD.FORM.INBOX.ERROR') }}
        </span>
        </label>
        <div v-if="dailyLimit" class="pt-4 pb-4">
          <span class="callout alert">{{ $t('WHATSAPP_TEMPLATES.MODAL.DAILY_LIMIT_MESSAGE', { dailyLimit }) }}</span>
        </div>
        <template v-if="inbox.id">
            <label>
                {{ isOnlineWaInboxV2 ? $t('CONVERSATION.REPLYBOX.TEMPLATE_LABEL') : $t('CAMPAIGN.ADD.FORM.CONTENT_LABEL') }}
                <online-wa-template-selector
                        :inbox-id = "inbox.id"
                        :on-change = "onTemplateChange"
                        :show-file-upload="true"
                        :template-variables-array="audienceView.params"
                        :template-text="message"
                        :use-template-selection="isOnlineWaInboxV23"
                        :file-url = "fileUrl"
                        :class="{ editor_warning: $v.message.$error || $v.messagePayload.$error }"
                        @input="$v.message.$touch"
                        ref="templateSelector"
                />
                <span v-if="$v.message.$error" class="editor-warning__message">
                    {{ $t('CAMPAIGN.ADD.FORM.MESSAGE.ERROR') }}
                </span>
                <span v-if="$v.messagePayload && $v.messagePayload.$error" class="editor-warning__message">
                    {{ this.messsagePayloadError() }}
                </span>
            </label>
        </template>

        <div class="audience-table-wrap" :class="{ error: $v.audience.$error }">
          <label>
              {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.LABEL') }}
              <woot-button v-if="audienceView.tableData.length > 0"
                      v-tooltip="$t('IMPORT_AUDIENCE.BUTTON_LABEL')"
                      name="import-button"
                      variant="link"
                      color-scheme="info"
                      icon="ion-android-upload"
                      @click.prevent="onToggleImport"
              >
              </woot-button>
              <span v-if="$v.audience.$error" class="editor-warning__message">
                  {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR') }}
              </span>
              <span v-if="this.badPhones.length" class="editor-warning__message not-critical">
                  {{ $t('CAMPAIGN.ADD.FORM.AUDIENCE.ERROR_PHONE', {
                numbers: this.formatBigArrays(this.badPhones, 7), sourceLines: this.formatBigArrays(this.badPhones.map(x=>x+1), 30)
              }) }}
              </span>
          </label>
          <ve-table
                  ref="audienceTableRef"
                  :max-height="200"
                  :columns="audienceView.tableColumns"
                  :table-data="audienceView.tableData"
                  :columnHiddenOption="columnHiddenOption"
                  :border-around="true"
                  :border-x="true"
                  :border-y="true"
          />
          <div v-show="audienceView.tableData.length === 0" class="empty-audience-data">
              <woot-button
                      name="import-button"
                      variant="clear"
                      color-scheme="info"
                      icon="ion-android-upload"
                      @click.prevent="onToggleImport"
              >
                  {{ $t('IMPORT_AUDIENCE.DESC') }}
              </woot-button>
          </div>
        </div>

        <label>
          {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.LABEL') }}
          <woot-date-time-picker
            :value="scheduledAt"
            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
            :placeholder="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.PLACEHOLDER')"
            :minute-step="10"
            @change="onChangeSchedule"
            :class="{ error: $v.scheduledAt.$error }"
          />
          <span v-if="$v.scheduledAt.$error" class="editor-warning__message">
                {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.ERROR') }}
            </span>
        </label>

        <woot-input v-if="isOnlineWaInboxV1"
            v-model="msgDelay"
            :label="$t('CAMPAIGN.ADD.FORM.MSG_DELAY.LABEL')"
            type="number"
            :class="{ error: $v.msgDelay.$error }"
            :error="
          $v.msgDelay.$error
            ? $t('CAMPAIGN.ADD.FORM.MSG_DELAY.ERROR', {delay: MIN_DELAY})
            : ''
        "
            :placeholder="$t('CAMPAIGN.ADD.FORM.MSG_DELAY.PLACEHOLDER')"
            @blur="$v.msgDelay.$touch"
        />
        <label v-if="isOnlineWaInboxV1">
          <input
            v-model="skipNightTime"
            type="checkbox"
            value="skipNightTime"
            name="triggerOnlyDuringBusinessHours"
          />
          {{ $t('CAMPAIGN.ADD.FORM.SKIP_NIGHT_TIME', {start: START_NIGHT, end: END_NIGHT}) }}
        </label>
        <label>
            <input v-model="resolveNew" type="checkbox" />
            {{ $t('CAMPAIGN.ADD.FORM.MARK_NEW_CHAT_RESOLVED') }}
        </label>
        <label>
          <input
            v-model="enabled"
            type="checkbox"
            value="enabled"
            name="enabled"
          />
          {{ $t('CAMPAIGN.ADD.FORM.ENABLED') }}
        </label>
      </div>
      <div class="modal-footer">
        <woot-button type="submit" :is-loading="uiFlags.isCreating" :is-disabled="isUpdateDisabled">
          {{ $t('CAMPAIGN.EDIT.UPDATE_BUTTON_TEXT') }}
        </woot-button>
        <woot-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>

    <import-online-wa-mass-audience v-if="showImportModal"
                                    :names="audienceFields.map(x=>x.field)"
                                    :on-result="onImportResult"
                                    :on-close="onToggleImport" />
      <woot-delete-modal
              class="update-confirm-wrapper"
              :show.sync="showUpdateConfirmationPopup"
              :on-close="closeUpdatePopup"
              :on-confirm="saveCampaignInner"
              :title="$t('CAMPAIGN.UPDATE_WITH_WARNINGS.CONFIRM.TITLE')"
              :message="$t('CAMPAIGN.UPDATE_WITH_WARNINGS.CONFIRM.MESSAGE')"
              :confirm-text="$t('CAMPAIGN.UPDATE_WITH_WARNINGS.CONFIRM.YES')"
              :reject-text="$t('CAMPAIGN.UPDATE_WITH_WARNINGS.CONFIRM.NO')"
              :is-loading="isUpdateDisabled"
      />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url, minLength } from 'vuelidate/lib/validators';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor';
import alertMixin from 'shared/mixins/alertMixin';
import campaignMixin from 'shared/mixins/campaignMixin';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import { VeTable } from 'vue-easytable';
import TextArea from "../../../../../../widget/components/Form/TextArea";
import inboxMixin from "shared/mixins/inboxMixin";
import ImportOnlineWaMassAudience from "./ImportOnlineWaMassAudience";
import OnlineWaTemplateSelector
  from "../../../../../components/widgets/conversation/watemplates/OnlineWaTemplateSelector";
import {isPhoneE164} from "shared/helpers/Validators";

export default {
  components: {
    OnlineWaTemplateSelector,
    ImportOnlineWaMassAudience,
    TextArea,
    WootDateTimePicker,
    WootMessageEditor,
    VeTable
  },
  mixins: [alertMixin, campaignMixin, inboxMixin],
  props: {
    selectedCampaign: {
      type: Object,
      default: null,
    },
  },
  data() {
    const defSch = new Date()
    defSch.setHours(defSch.getHours() + 1)
    defSch.setSeconds(0)
    defSch.setMinutes(Math.round(defSch.getMinutes()/10)*10)

    return {
      title: '',
      message: '',
      selectedSender: '',
      selectedInboxId: null,
      msgDelay: undefined,
      skipNightTime: false,
      resolveNew: true,
      scheduledAt: defSch,
      show: true,
      enabled: true,
      audience: null,
      showImportModal: false,
      audienceFields: [
        {field: "phone", key:"phone", title: "Phone", width: "13rem",
          renderBodyCell: ({ row }) => <span class={!row.phoneIsValid && 'not-critical editor-warning__message'} dir="ltr">{row.phone}</span>

        },
        {field: "name", key:"name", title: "Name", width: "20%"},
        {field: "param1", key:"param1", title: "{{1}}"},
        {field: "param2", key:"param2", title: "{{2}}"},
        {field: "param3", key:"param3", title: "{{3}}"},
        {field: "param4", key:"param4", title: "{{4}}"},
        {field: "param5", key:"param5", title: "{{5}}"},
        {field: "websiteVariable", key:"websiteVariable", title: "{{website}}"},
      ],
      columnHiddenOption: {
        defaultHiddenColumnKeys: ["websiteVariable"],
      },
      messagePayload: undefined,
      templateInfo: undefined,
      file: undefined,
      fileUrl: undefined,
      badPhones: [],
      MIN_DELAY: 10,
      START_NIGHT: '21:00',
      END_NIGHT: '07:00',
      showUpdateConfirmationPopup: false,
      isUpdateDisabled: false,
    };
  },
  validations: {
    message: {
      required,
    },
    title: {
      required,
    },
    messagePayload: {
      validator: function (value) {
        return !this.messsagePayloadError()
      }
    },
    msgDelay: {
      validator: function(value) {
        return !this.isOnlineWaInboxV1 || (!!value && !!value.trim() && parseInt(value, 10) >= this.MIN_DELAY)
      }
    },
    selectedInboxId: {
      required,
    },
    audience: {
      required,
    },
    // audiencePhone: {
    //   validator: function () {
    //     const value = this.audience
    //     if (!value || !value.length) return true
    //
    //     this.badPhones = value.reduce((a,x,i)=> {
    //       if (!x || !x.contact || !x.contact.phone || !isPhoneE164(x.contact.phone)) a.push(i+1)
    //       return a
    //     }, [])
    //     return !this.badPhones.length
    //   },
    // },
    scheduledAt: {
      required
    }
  },
  watch: {
    audience() {
      this.badPhones = this.audience ? this.audience.reduce((a,x,i)=> {
        if (!x || !x.contact || !x.contact.phone || !isPhoneE164(x.contact.phone)) a.push(i+1)
        return a
      }, []) : []
    }
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      inboxes: 'inboxes/getOnlineWaAllVersionsInboxes',
      getInbox: 'inboxes/getInbox',
      templatesList: 'wa_templates/getTemplates',
    }),
    dailyLimit() {
      const templates = this.templatesList(this.selectedInboxId);
      return templates.length && templates[0].dailyLimit
        ? templates[0].dailyLimit
        : false;
    },
    inbox() {
      return this.getInbox(this.selectedInboxId) || {}
    },
    audienceView() {
      let result = {
        tableColumns:[],
        tableData: [],
        params:[]
      }
      if (this.audience && this.audience.length > 0) {
        result = {
          tableColumns:[
            {
              field: "",
              key: "index",
              title: "#",
              width: 50,
              align: "center",
              renderBodyCell: ({ rowIndex }) => rowIndex + 1
              ,
            },
            ...this.audienceFields
          ],
          tableData: this.audience.map((x,i)=>{
            return {
              phone: x.contact && x.contact.phone,
              name: x.contact && x.contact.name,
              param1: x.params && x.params['1'],
              param2: x.params && x.params['2'],
              param3: x.params && x.params['3'],
              param4: x.params && x.params['4'],
              param5: x.params && x.params['5'],
              websiteVariable: x.websiteVariable && x.websiteVariable,
              phoneIsValid: !this.badPhones.includes(i+1)
            }
          }),
          params: this.audience.map(x => {return {...x.params, websiteVariable: x.websiteVariable}})
        }
      }

      return result
    },
    pageTitle() {
      return this.selectedCampaign ? `${this.$t('CAMPAIGN.EDIT.TITLE')} - ${
        this.selectedCampaign.title
      }` : this.$t('CAMPAIGN.ADD.TITLE');
    },
  },
  mounted() {
    this.setFormValues();
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    onChangeInbox() {
    },
    onChangeSchedule(value) {
      this.scheduledAt = value;
    },
    onToggleImport() {
      this.showImportModal = !this.showImportModal;
    },
    onImportResult(data) {
      this.audience = data.filter(x=>!!x.phone).map(x => {
        let {phone, name, websiteVariable, ...p} = x
        phone = phone && phone.toString().replace(/^((?:0?5)|(?:9725))(\d{8})$/, '+9725$2')
        return {
          contact: {phone, name},
          params: {'1': p.param1, '2': p.param2, '3': p.param3, '4': p.param4,'5': p.param5},
          websiteVariable,
        }
      })
    },
    setFormValues() {
      if (this.selectedCampaign) {
        const {
          title,
          message,
          enabled,
          scheduled_at,
          inbox: {id: inboxId},
          trigger_rules: {msg_delay, skip_night_time, resolve_new},
          audience,
          file_url
        } = this.selectedCampaign;
        this.title = title || this.title;
        this.message = message;
        this.msgDelay = msg_delay;
        this.selectedInboxId = inboxId;
        this.audience = audience;
        this.scheduledAt = scheduled_at && new Date(scheduled_at);
        this.skipNightTime = skip_night_time;
        this.resolveNew = resolve_new;
        this.enabled = enabled;
        this.fileUrl = file_url
      }
    },
    getCampaignDetails() {
      const audience = this.messagePayload && this.messagePayload.length === this.audience.length ?
        this.audience.map((x,i) => {
          return {...x, message: this.messagePayload[i] }
        }) : this.audience
      let campaignDetails = {
        campaign_type: this.campaignType,
        title: this.title,
        message: this.message,
        inbox_id: this.selectedInboxId,
        enabled: this.enabled,
        scheduled_at: this.scheduledAt,
        audience: audience,
        trigger_rules: {
          skip_night_time: this.skipNightTime && this.isOnlineWaInboxV1,
          resolve_new: this.resolveNew || undefined,
          msg_delay: this.msgDelay,
        },
        file: this.file,
      };
      return campaignDetails;
    },
    messsagePayloadError() {
      return !this.$refs.templateSelector || this.$refs.templateSelector.error()

    },
    saveCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.badPhones.length) {
        this.openUpdatePopup()
      } else {
        this.saveCampaignInner()
      }

    },
    async saveCampaignInner() {
      if (this.selectedCampaign && this.selectedCampaign.id) {
        try {
          await this.$store.dispatch('campaigns/update', {
            id: this.selectedCampaign.id,
            ...this.getCampaignDetails()
          });
          this.showAlert(this.$t('CAMPAIGN.EDIT.API.SUCCESS_MESSAGE'));
          this.onClose();
        } catch (error) {
          this.showAlert(this.$t('CAMPAIGN.EDIT.API.ERROR_MESSAGE'));
        }
      } else {
        try {
          this.isUpdateDisabled = true;
          const campaignDetails = this.getCampaignDetails();
          await this.$store.dispatch('campaigns/create', campaignDetails);
          this.showAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
          this.onClose();
        } catch (error) {
          this.isUpdateDisabled = false;
          const errorMessage =
            error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
          this.showAlert(errorMessage);
        }
      }
    },
    onTemplateChange({templateInfo, message, file, messagePayloadArray, options}) {
      this.messagePayload = messagePayloadArray
      this.templateInfo = templateInfo
      this.file = file
      this.message = message
      this.$refs.audienceTableRef?.showColumnsByKeys &&
        this.$refs.audienceTableRef[options?.withWebsiteVariable ? 'showColumnsByKeys' : 'hideColumnsByKeys'](["websiteVariable"])
    },
    openUpdatePopup() {
      this.showUpdateConfirmationPopup = true;
    },
    closeUpdatePopup() {
      this.showUpdateConfirmationPopup = false;
    },
    formatBigArrays(arr, limit) {
      return arr && arr.length > limit ? arr.slice(0,limit).join(',') + '...' : arr.join(',')
    }
  },
};
</script>
<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.audience-table {
  max-height: 170px;
}

.empty-audience-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}


element.style {
  text-align: center;
}

::v-deep .date-picker input {
  padding-right: var(--space-large);
}
.audience-table-wrap::v-deep {
   margin-bottom: 16px;
  .ve-table {

    .ve-table-container table.ve-table-content {
      thead.ve-table-header tr.ve-table-header-tr {
        height: 0;
        th.ve-table-header-th {
          padding: var(--space-micro) var(--space-micro);
        }
      }
      tbody.ve-table-body tr.ve-table-body-tr {
        height: 0;
        td.ve-table-body-td {
          padding: var(--space-micro) var(--space-micro);
        }
      }
    }
  }
}

.editor-warning__message.not-critical {
  color: var(--y-900);
}

::v-deep .update-confirm-wrapper {
  .modal-container {
      width: 60rem
  }
  .alert {
    background-color: $warning-color;
  }
}

</style>
