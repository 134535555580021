<template>
  <div class="medium-12 columns">
    <div class="templates__list-search">
      <fluent-icon icon="search" class="search-icon" size="16" />
      <input
        ref="search"
        v-model="query"
        type="search"
        :placeholder="$t('WHATSAPP_TEMPLATES.PICKER.SEARCH_PLACEHOLDER')"
        class="templates__search-input"
      />
    </div>
    <div class="medium-3">
      <label class="templates__list-category" for="categoryTemplate">
        {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.CATEGORY') }}:
        <select v-model="templateCategory" class="templates__search-selector">
          <option
            v-for="category in categories"
            :key="category.value"
            :value="category.value"
          >
            {{ category.label }}
          </option>
        </select>
      </label>
    </div>
    <div
      v-if="templateUiFlags.isFetching && !filteredTemplateMessages.length"
      class="text-center"
    >
      <span class="spinner" />
    </div>
    <div v-else class="template__list-container">
      <div v-for="(template, i) in filteredTemplateMessages" :key="template.id">
        <button
          class="template__list-item"
          @click.prevent="$emit('onSelect', template)"
        >
          <div>
            <div class="flex-between">
              <p class="label-title">
                <fluent-icon
                  v-if="!!getHeaderType(template)"
                  :icon="getHeaderType(template)"
                  style="vertical-align: bottom"
                />
                {{ template.name }}
              </p>
              <div>
                <span class="label-lang label">
                  {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.LANGUAGE') }} :
                  {{ template.language }}
                </span>
                <br />
                <span class="label-lang label mt-1">
                  {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.CATEGORY') }} :
                  {{
                    template.category === 1
                      ? $t('WHATSAPP_TEMPLATES.PICKER.CATEGORY.SERVICE')
                      : $t('WHATSAPP_TEMPLATES.PICKER.CATEGORY.MARKETING')
                  }}
                </span>
              </div>
            </div>
            <div>
              <p class="strong">
                {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.TEMPLATE_BODY') }}
              </p>
              <p class="label-body">{{ getTemplatebody(template) }}</p>
            </div>
            <div
              v-if="getHeaderType(template) !== 'text'"
              class="label-category"
            >
              <p class="strong">
                {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.HEADER_TYPE') }}
              </p>
              <p>{{ getHeaderTypeText(template) }}</p>
            </div>
          </div>
        </button>
        <hr v-if="i != filteredTemplateMessages.length - 1" :key="`hr-${i}`" />
      </div>
      <div v-if="!filteredTemplateMessages.length">
        <p>
          {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES_FOUND') }}
          <strong>{{ query }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      query: '',
      templateCategory: null,
    };
  },
  computed: {
    categories() {
      return [
        {
          label: this.$t('WHATSAPP_TEMPLATES.PICKER.CATEGORY.ALL'),
          value: null,
        },
        {
          label: this.$t('WHATSAPP_TEMPLATES.PICKER.CATEGORY.SERVICE'),
          value: 1,
        },
        {
          label: this.$t('WHATSAPP_TEMPLATES.PICKER.CATEGORY.MARKETING'),
          value: 2,
        },
      ];
    },
    ...mapGetters({
      templatesList: 'wa_templates/getTemplates',
      templateUiFlags: 'wa_templates/getUIFlags',
    }),
    whatsAppTemplateMessages() {
      return this.templatesList(this.inboxId);
    },
    filteredTemplateMessages() {
      let result = this.whatsAppTemplateMessages.filter(
        template =>
          template.name.toLowerCase().includes(this.query.toLowerCase()) ||
          template.text.toLowerCase().includes(this.query.toLowerCase())
      );
      if (this.templateCategory) {
        result = result.filter(
          ({ category }) => category == this.templateCategory
        );
      }
      return result;
    },
  },
  watch: {
    inboxId(val) {
      this.refreshTemplateList();
    },
  },
  mounted() {
    this.refreshTemplateList();
  },
  methods: {
    getTemplatebody(template) {
      return template.text;
    },
    getHeaderType(template) {
      switch (parseInt(template.headerType)) {
        case 1:
          return 'text';
        case 3:
          return 'image';
        case 4:
          return 'video';
        case 5:
          return 'document';
      }
      return null;
    },
    getHeaderTypeText(template) {
      let result = this.getHeaderType(template);
      return result
        ? this.$t(
            `WHATSAPP_TEMPLATES.PICKER.TYPES.${result.toUpperCase()}`
          ).toUpperCase()
        : '---';
    },
    refreshTemplateList() {
      this.$store.dispatch('wa_templates/get', this.inboxId);
    },
  },
};
</script>

<style scoped lang="scss">
.flex-between {
  @apply flex justify-between mb-2.5;
}

.templates__list-search {
  @apply items-center flex bg-slate-25 dark:bg-slate-900 mb-2.5 py-0 px-2.5 rounded-md border border-solid border-slate-100 dark:border-slate-700;

  .search-icon {
    @apply text-slate-400 dark:text-slate-300;
  }

  .templates__search-input {
    @apply bg-transparent border-0 text-xs h-auto m-0;
  }
}
.template__list-container {
  @apply bg-slate-25 dark:bg-slate-900 rounded-md max-h-[18.75rem] overflow-y-auto p-2.5;

  .template__list-item {
    @apply rounded-lg cursor-pointer block p-2.5 text-left w-full hover:bg-woot-50 dark:hover:bg-slate-800;

    .label-title {
      @apply text-sm;
    }

    .label-category {
      @apply mt-5;

      span {
        @apply text-sm font-semibold;
      }
    }

    //.label-body { // d99d remarked from original
    //  font-family: monospace;
    //}
  }
}

.strong {
  @apply text-xs font-semibold;
}

hr {
  @apply border-b border-solid border-slate-100 dark:border-slate-700 my-2.5 mx-auto max-w-[95%];
}
.templates__list-category {
  @apply items-center flex bg-slate-25 dark:bg-slate-900 mb-2.5 py-0 px-2.5 rounded-md border border-solid border-slate-100 dark:border-slate-700;

  .templates__search-selector {
    @apply bg-transparent border-0 text-xs h-auto m-0;
  }
}
</style>
