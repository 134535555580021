<template>
  <div class="flex flex-col h-full items-start md:items-center">
    <div class="flex flex-col text-center items-center justify-start m-0 mx-4 flex-1">
      <img
        :src="getIntegrationLogo"
        class="p-2 h-16 w-16"
      />
      <div>
        <h3 class="text-xl text-slate-800 dark:text-slate-100">
          {{ getIntegrationName }}
        </h3>
        <p>
          {{
            useInstallationName(
              integrationDescription,
              globalConfig.installationName
            )
          }}
        </p>
      </div>
    </div>
    <div v-if="integrationId === 'openai'">
      <div v-if="hasConnectedHooks" class="flex justify-center items-center mb-0">
        <woot-button
          v-if="hasConnectedHooks"
          icon="edit"
          color-scheme="secondary"
          @click.prevent = "onSettings" class="margin-horizontal-2">
          {{ $t('COMMAND_BAR.SECTIONS.SETTINGS') }}
        </woot-button>
        <div v-if="hasConnectedHooks && hasDeletePermission('ChatGPT')">
          <div @click="openDeletePopupApp">
            <woot-button class="nice alert">
              {{ $t('INTEGRATION_APPS.DISCONNECT.BUTTON_TEXT') }}
            </woot-button>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-row justify-center items-center mb-0">
        <woot-button
          v-if="hasCreatePermission('ChatGPT')"
          color-scheme="success"
          @click.prevent="toggleChatGptEnableModal"
        >
          {{ $t('INTEGRATION_APPS.CONNECT.BUTTON_TEXT') }}
        </woot-button>
      </div>
    </div>
    <div v-else class="flex justify-center items-center mb-0 w-[15%]">
      <router-link
        :to="
          frontendURL(
            `accounts/${accountId}/${integrationPath || 'settings/integrations'}/` + integrationId
          )
        "
      >
        <div v-if="integrationEnabled">
          <div v-if="integrationAction === 'disconnect'">
            <div @click="openDeletePopup">
              <woot-submit-button
                :button-text="
                  actionButtonText ||
                    $t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.BUTTON_TEXT')
                "
                button-class="smooth alert"
              />
            </div>
          </div>
          <div v-else>
            <button :disabled="disabled" class="button nice">
              {{ $t('INTEGRATION_SETTINGS.WEBHOOK.CONFIGURE') }}
            </button>
          </div>
        </div>
      </router-link>
      <div v-if="!integrationEnabled">
        <a :href="integrationAction" class="button success nice">
          {{ $t('INTEGRATION_SETTINGS.CONNECT.BUTTON_TEXT') }}
        </a>
      </div>
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="
        deleteConfirmationTextApp.title || deleteConfirmationText.title ||
          $t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.TITLE')
      "
      :message="
        deleteConfirmationTextApp.message || deleteConfirmationText.message ||
          $t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.MESSAGE')
      "
      :confirm-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.YES')"
      :reject-text="$t('INTEGRATION_SETTINGS.WEBHOOK.DELETE.CONFIRM.NO')"
    />

    <chat-gpt-enable-modal
      v-if="showChatGptEnableModal"
      :show="showChatGptEnableModal"
      @close="toggleChatGptEnableModal"
    />
    <woot-modal
      :show.sync="showDAssistanceSettings"
      :on-close="toggleDAssistanceShow"
    >
      <woot-modal-header
        :header-title="$t('D_ASSISTANCE.SETTINGS.TITLE')"
        :header-content="$t('D_ASSISTANCE.SETTINGS.NOTE')"
      />
      <digital99-assistance-setting
        v-if="showDAssistanceSettings"
        @cancel="toggleDAssistanceShow"
      />
    </woot-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { frontendURL } from '../../../../helper/URLHelper';
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import integration from "./Integration.vue";
import hookMixin from "../integrationapps/hookMixin";
import ChatGptEnableModal from "../../../../components/widgets/digital99Assistance/ChatGptEnableModal.vue";
import Digital99AssistanceSetting
  from "../../../../components/widgets/digital99Assistance/Digital99AssistanceSetting.vue";
import hasPermissionMixin from 'dashboard/mixins/hasPermission';


export default {
  components: {Digital99AssistanceSetting, ChatGptEnableModal},
  mixins: [integration, hookMixin, hasPermissionMixin],
  props: {
    integration: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showDAssistanceSettings: false,
      showChatGptEnableModal: false,
      deleteConfirmationTextApp: { type: Object, default: () => ({}) },
    }
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    account() {
      return this.getAccount(this.accountId);
    },
    iPracticomFeatures() {
      return this.account.platform_features?.iPracticom;
    },
    oneCityFeatures() {
      return this.account.platform_features?.one_city;
    },
    getIntegrationName() {
      let oldSubStr1 = '99 דיגיטל';
      let oldSubStr2 = '99digital';
      let newSubStr = null;
      if (this.iPracticomFeatures) {
        newSubStr = 'iPracticom';
      } else if (this.oneCityFeatures) {
        newSubStr = 'One City';
      }
      if (newSubStr) {
        return this.integrationName.replace(
          new RegExp(`${oldSubStr1}|${oldSubStr2}`, 'gi'),
          newSubStr
        );
      }
      return this.integrationName;
    },
    getIntegrationLogo() {
      if (this.integrationId === 'openai' && this.iPracticomFeatures) {
        return '/dashboard/images/integrations/ipracticom.jpg';
      }
      return '/dashboard/images/integrations/' + this.integrationLogo;
    },
  },
  methods: {
    toggleChatGptEnableModal() {
      this.showChatGptEnableModal = !this.showChatGptEnableModal
    },
    onSettings() {
      if (this.integrationId === 'openai') {
        this.showDAssistanceSettings = true
      }
    },
    toggleDAssistanceShow() {
      this.showDAssistanceSettings = !this.showDAssistanceSettings
    },
    openDeletePopupApp() {
      this.deleteConfirmationTextApp.title = this.$t('INTEGRATION_APPS.DELETE.TITLE.ACCOUNT')
      this.deleteConfirmationTextApp.message = this.$t('INTEGRATION_APPS.DELETE.MESSAGE.ACCOUNT')
      this.showDeleteConfirmationPopup = true;
    },

    confirmDeletion() {
      if (this.integration.isApp) return this.deleteApp()

      this.closeDeletePopup();
      this.deleteIntegration(this.deleteIntegration);
      this.$router.push({name: 'settings_integrations'});
    },

    async deleteApp() {
      try {
        const selectedHook = this.integration.hooks[0]
        await this.$store.dispatch('integrations/deleteHook', {
          hookId: selectedHook.id,
          appId: selectedHook.app_id,
        });
        this.alertMessage = this.$t(
          'INTEGRATION_APPS.DELETE.API.SUCCESS_MESSAGE'
        );
        this.closeDeletePopup();
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        this.alertMessage =
          errorMessage || this.$t('INTEGRATION_APPS.DELETE.API.ERROR_MESSAGE');
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
  },
};
</script>
