<template>
  <form class="flex flex-col w-full" @submit.prevent="onSubmit">
    <div class="w-full">
      <label :class="{ error: $v.name.$error }">
        {{ $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.NAME.LABEL') }}
        <input
          v-model.trim="name"
          type="text"
          :placeholder="
            $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.NAME.PLACEHOLDER')
          "
          @input="$v.name.$touch"
        />
        <span v-if="$v.name.$error" class="message">
          {{
            $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.NAME.ERROR', { maxSize: 120 })
          }}
        </span>
      </label>
      <label :class="{ error: $v.url.$error }">
        {{ $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.END_POINT.LABEL') }}
        <input
          v-model.trim="url"
          type="text"
          name="url"
          :placeholder="webhookURLInputPlaceholder"
          @input="$v.url.$touch"
        />
        <span v-if="$v.url.$error" class="message">
          {{ $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.END_POINT.ERROR') }}
        </span>
      </label>
      <div class="multiselect-wrap--small">
        <label>
          {{
            $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.INBOXES', { maxSize: 120 })
          }}
          <multiselect
            v-model="inboxes"
            :options="inboxOptions"
            :multiple="true"
            track-by="id"
            label="name"
            placeholder="Inboxes"
          />
        </label>
      </div>
      <label :class="{ error: $v.url.$error }" class="mb-2">
        {{ $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.SUBSCRIPTIONS.LABEL') }}
      </label>
      <div v-for="event in supportedWebhookEvents" :key="event">
        <input
          :id="event"
          v-model="subscriptions"
          type="checkbox"
          :value="event"
          name="subscriptions"
          class="checkbox"
        />
        <span class="text-sm">
          {{ `${getEventLabel(event)} (${event})` }}
        </span>
      </div>
    </div>

    <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
      <div class="w-full">
        <woot-button
          type="submit"
          :disabled="$v.$invalid || isSubmitting"
          :is-loading="isSubmitting"
        >
          {{ submitLabel }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="$emit('cancel')">
          {{ $t('INTEGRATION_SETTINGS.WEBHOOK.FORM.CANCEL') }}
        </woot-button>
      </div>
    </div>
  </form>
</template>

<script>
import { required, url, minLength, maxLength } from 'vuelidate/lib/validators';
import webhookMixin from './webhookMixin';
import wootConstants from 'dashboard/constants/globals';

const { EXAMPLE_WEBHOOK_URL } = wootConstants;

const SUPPORTED_WEBHOOK_EVENTS = [
  'conversation_created',
  'conversation_status_changed',
  'conversation_updated',
  'message_created',
  'message_updated',
  'webwidget_triggered',
  'contact_created',
  'contact_updated',
];

export default {
  mixins: [webhookMixin],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      required: true,
    },
  },
  validations: {
    url: {
      required,
      minLength: minLength(7),
      url,
    },
    subscriptions: {
      required,
    },
    name: {
      minLength: maxLength(120),
    },
  },
  data() {
    return {
      url: this.value.url || '',
      name: this.value.name || '',
      subscriptions: this.value.subscriptions || [],
      supportedWebhookEvents: SUPPORTED_WEBHOOK_EVENTS,
      inboxes: this.alreadySelected(this.value.inbox_ids),
    };
  },
  computed: {
    inboxOptions() {
      return this.$store.getters['inboxes/getInboxes'].map(inbox => ({
        id: inbox.id,
        name: inbox.name,
      }));
    },
    webhookURLInputPlaceholder() {
      return this.$t(
        'INTEGRATION_SETTINGS.WEBHOOK.FORM.END_POINT.PLACEHOLDER',
        {
          webhookExampleURL: EXAMPLE_WEBHOOK_URL,
        }
      );
    },
  },
  methods: {
    alreadySelected(selectedInboxIds) {
      if (!selectedInboxIds || selectedInboxIds.length === 0) {
        return [];
      }

      const allInboxes = this.$store.getters['inboxes/getInboxes'];
      return allInboxes.filter(inbox => selectedInboxIds.includes(inbox.id));
    },
    onSubmit() {
      this.$emit('submit', {
        url: this.url,
        name: this.name,
        subscriptions: this.subscriptions,
        inbox_ids: this.inboxes?.map(inbox => inbox.id),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.checkbox {
  @apply mr-2;
}
.multiselect-wrap--small {
  @apply mb-4;
}
</style>
