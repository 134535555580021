import types from '../mutation-types';
import InboxApi from '../../api/inboxes';
import watemplatesAPI from '../../api/watemplates';

import Vue from "vue";

export const state = {
  records: {},
  recordsFull: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getTemplates: $state => id => {
    return $state.records[Number(id)] || [];
  },
  getUIFlags: $state => {
    return $state.uiFlags;
  },
  getTemplatesFull: $state => {
    return $state.recordsFull || [];
  },
};

export const actions = {
  get: async function ({ commit }, inboxId) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxApi.getOnlineWaTemplates(inboxId);
      commit(types.SET_TEMPLATES, {id: inboxId, data: response.data.payload});
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },

  getFull: async function ({ commit }) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: true });
    try {
      const response = await watemplatesAPI.get();
      response.data?.forEach(t=>t.templates?.forEach(x=>{if(x.footer === 0) x.footer = undefined}));
      commit(types.SET_FULL_TEMPLATES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createTemplate ({ commit }, {inbox, obj}) {
    commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: true });
    let response = {}
    try {
      let {bodyAsHtml, quickButtonCount, headerSampleFile, ...payload} = obj
      if (headerSampleFile?.resource) {
        headerSampleFile = headerSampleFile.resource.file
      }
      response = await watemplatesAPI.create({headerSampleFile, ...payload});
      await new Promise(r => setTimeout(r, 4 * 1000)); // delay is need to ensure if all templates are recreated in case new template was created
      //commit(types.ADD_TEMPLATE, {inbox, data: response.data});
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isCreating: false });
    }

    return response?.data
  },
  delete: async ({ commit }, keys) => {
    commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: true });
    try {
      await watemplatesAPI.delete(keys);
      commit(types.DELETE_TEMPLATE, keys);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TEMPLATE_UI_FLAG, { isDeleting: false });
    }
  },
  getSupportedInboxTypes: async function ({ commit }, inboxId) {
    try {
      return await watemplatesAPI.getSupportedInboxTypes();
    } catch (error) {
      // Ignore error
    } finally {
    }

    return []
  },
};

export const mutations = {
  [types.SET_TEMPLATE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_TEMPLATES]: ($state, { id, data }) => {
    Vue.set($state.records, id, data);
  },
  [types.ADD_TEMPLATE]: ($state, {inbox, data}) => {
    let newState
    let inboxTemplates = $state.recordsFull.find(x => x.inbox_id === inbox.id)
    if (!{inbox_id: inbox.id, inbox_name: inbox.name, templates: []}) {
      inboxTemplates = {inbox_id: inbox.id, inbox_name: inbox.name, templates: []}
      newState = [inboxTemplates]
    }
    inboxTemplates.templates.push(data)
    $state.recordsFull = newState; //todo refine to splice
  },
  [types.SET_FULL_TEMPLATES]: ($state, data) => {
    $state.recordsFull = data;
  },

  [types.DELETE_TEMPLATE]: ($state, {inbox_id, name}) => {
    const owner = $state.recordsFull.find(x=>x.inbox_id === inbox_id)
    const childInd = owner?.templates?.findIndex(t=>t.name === name)
    owner?.templates?.splice(childInd, 1)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
