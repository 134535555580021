<template>
  <div>
    <div style="position: relative">
      <page-header
        class="mb-6"
        :header-title="$t('EXPORT_WIZARD.STEP_3.TITLE')"
        :header-content="$t('EXPORT_WIZARD.STEP_3.DESC')"
      />

      <div class="label--export-process text-sm text-slate-400 my-2">
        {{ $t(`EXPORT_WIZARD.STEP_3.STATUS.${status}`) }}
        <div v-if="status === 'failed'" class="text-red-300">
          {{ downloadContactsInfo.message }}
        </div>
        <span v-else-if="!readyToDownload">
          ({{ downloadContactsInfo.message }})
        </span>
      </div>

      <a v-if="readyToDownload" :href="downloadContactsInfo.file.url">
        {{ downloadContactsInfo.file.name }}
      </a>

      <woot-button
        v-if="!isExportInProcess"
        color-scheme="info"
        class="mx-4"
        size="small"
        icon="ion-android-refresh"
        :is-disabled="isExportInProcess"
        @click="exportContacts"
      >
        {{ $t('EXPORT_WIZARD.STEP_3.REGENERATE') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../../routes/dashboard/settings/SettingsSubPageHeader.vue';
import filterQueryGenerator from '../../../helper/filterQueryGenerator';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  props: {
    category: { type: String, default: 'conversations' },
    filters: { type: Array, default: () => [] },
  },
  data() {
    return {
      refreshTimer: undefined,
      downloadContactsInfo: {},
      data_migrate_id: null,
    };
  },
  computed: {
    isExportInProcess() {
      return this.getIsExportInProcess(this.status)
    },
    status() {
      return this.downloadContactsInfo.status || 'pending';
    },
    readyToDownload() {
      return this.downloadContactsInfo.status === 'completed' && this.downloadContactsInfo.file;
    },
  },
  mounted() {
    this.exportContacts({ get_status: true });
  },
  destroyed() {
    clearTimeout(this.refreshTimer)
  },
  methods: {
    getIsExportInProcess(status) {
      return ['pending', 'processing'].includes(status);
    },
    exportRequest(payload = {}) {
      if (this.category === 'contacts') {
        return this.$store.dispatch('contacts/exportContacts', payload);
      }
      if (this.category === 'conversations') {
        return this.$store.dispatch('exportConversations', payload);
      }
    },
    statusRequest(payload = {}) {
      if (this.category === 'contacts') {
        return this.$store.dispatch('contacts/getExportContactsStatus', payload);
      }
      if (this.category === 'conversations') {
        return this.$store.dispatch('getExportConversationsStatus', payload);
      }
    },
    async exportContacts(payload = {}) {
      try {
        const { status, message, file, data_migrate_id } =
          await this.exportRequest({
            ...payload,
            filters: filterQueryGenerator(this.filters),
          });
        this.data_migrate_id = data_migrate_id;
        this.downloadContactsInfo = { status, message, file };
        this.fetchExportStatus();
      } catch (error) {
        this.downloadContactsInfo = {}
        this.showAlert(
          error.message || this.$t('IMPORT_CONVERSATIONS.ERROR_MESSAGE')
        );
      }
    },
    async fetchExportStatus() {
      try {
        const { status, message, file } = await this.statusRequest({ data_migrate_id: this.data_migrate_id });
        this.downloadContactsInfo = { status, message, file };
        if (this.getIsExportInProcess(status)) {
          this.refreshTimer = setTimeout(() => this.fetchExportStatus(), 500);
        }
        this.$emit('readyToDownload', this.readyToDownload);
        // this.autoDownloadFile();
      } catch (error) {
        console.error('fetchExportStatus of Contacts', error)
      }
    },
    autoDownloadFile() {
      if (this.readyToDownload) {
        const link = document.createElement('a');
        link.setAttribute('href', this.downloadContactsInfo.file.url);
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    cancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style scoped lang="scss">

</style>
