import Index from './Index';
import SettingsContent from '../../Wrapper';
import Webhook from '../Webhooks/Index';
import DashboardApps from '../DashboardApps/Index';
import ShowIntegration from '../ShowIntegration';
import { frontendURL } from '../../../../../helper/URLHelper';
import ExternalDashboard from "./ExternalDashboard/Index.vue";
import DirectChat from "./DirectChat/Index.vue";
import MobileApp from "./MobileApp/Index.vue";
import IntegrationHooks from "../../integrationapps/IntegrationHooks.vue";

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings-integrations'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_integrations';
        const backUrl =
          params.name === 'settings_integrations_integration'
            ? { name: 'settings_integrations' }
            : '';
        return {
          headerTitle: 'INTEGRATION_SETTINGS.HEADER',
          icon: 'flash-on',
          showBackButton,
          backUrl,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_integrations',
          component: Index,
          roles: ['administrator'],
        },
        {
          path: 'webhook',
          component: Webhook,
          name: 'settings_integrations_webhook',
          roles: ['administrator'],
        },
        {
          path: 'external_dashboard',
          component: ExternalDashboard,
          name: 'settings_integrations_external_dashboard',
          roles: ['administrator'],
        },
        {
          path: 'direct_chat',
          component: DirectChat,
          name: 'settings_integrations_direct_chat',
          roles: ['administrator'],
        },
        {
          path: 'mobile_app',
          component: MobileApp,
          name: 'settings_integrations_mobile_app',
          roles: ['administrator'],
        },
        {
          path: 'app/:integration_id',
          name: 'settings_integrations_app',
          component: IntegrationHooks,
          roles: ['administrator'],
          props: route => {
            return {
              integrationId: route.params.integration_id
            };
          },
        },
        {
          path: ':integration_id',
          name: 'settings_integrations_integration',
          component: ShowIntegration,
          roles: ['administrator'],
          props: route => {
            return {
              integrationId: route.params.integration_id,
              code: route.query.code,
            };
          },
        },
      ],
    },
  ],
};
