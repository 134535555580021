/* global axios */
import { LocalStorage } from '../../shared/helpers/localStorage';
// import CacheEnabledApiClient from './CacheEnabledApiClient';
import ApiClient from './ApiClient';

// class Inboxes extends CacheEnabledApiClient {
class Inboxes extends ApiClient {
  // hotfix try to fix sometimes inboxes are not loading (no call to api)
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox';
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

  deleteOpenChatImage(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/chat_open_image`);
  }

  getOnlineWaTemplates(inboxId) {
    return axios.get(`${this.url}/${inboxId}/wa_templates`);
  }

  async getOnlineWaProfile(inboxId) {
    const storageKey = `inboxes/waProfile_${inboxId}`;
    const data = LocalStorage.getWithExpiration(storageKey);
    if (data) {
      return data;
    }
    const {
      data: { payload },
    } = await axios.get(`${this.url}/${inboxId}/wa_get_profile`);
    LocalStorage.setWithExpiration(storageKey, payload, 24 * 60 * 60 * 1000);
    return payload;
  }

  setOnlineWaProfile(inboxId, payload = {}) {
    return axios.post(`${this.url}/${inboxId}/wa_set_profile`, payload);
  }

  getPhones() {
    return axios.get(`${this.url}/sms_phones`);
  }

  importConversations(inboxId, file) {
    const formData = new FormData();
    formData.append('import_file', file);
    return axios.post(`${this.url}/${inboxId}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  getImportConversationsStatus(inboxId) {
    return axios.get(`${this.url}/${inboxId}/import`);
  }

  getAgentBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/agent_bot`);
  }

  setAgentBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_agent_bot`, {
      agent_bot: botId,
    });
  }
}

export default new Inboxes();
