export default {
  computed: {
    rtl() {
      return this.isRTL()
    }
  },
  methods: {
    isRTL() {
      return this.$i18n.locale !== 'en'
    },
    endAlign() {
      return this.isRTL() ? 'left' : 'right'
    },
    startAlign() {
      return this.isRTL() ? 'right' : 'left'
    },
  },
};
