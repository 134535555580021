/* global axios */
import ApiClient from './ApiClient';

export const buildContactParams = (page, sortAttr, label, search, search_with) => {
  let params = `include_contact_inboxes=false&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  if (label) {
    params = `${params}&labels[]=${label}`;
  }
  if (search_with) {
    params = `${params}&search_with=${search_with}`;
  }
  return params;
};

class ContactAPI extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  get(page, sortAttr = 'name', label = '') {
    let requestURL = `${this.url}?${buildContactParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return axios.get(requestURL);
  }

  getAll(sortAttr = 'name') {
    return axios.get(`${this.url}?&sort=${sortAttr}`);
  }

  exportContacts({ filters, get_status } = {}) {
    return axios.post(`${this.url}/export`, { filters, get_status });
  }

  getExportContactsStatus(params = {}) {
    return axios.get(`${this.url}/export`, { params });
  }

  getConversations(contactId) {
    return axios.get(`${this.url}/${contactId}/conversations`);
  }

  getContactableInboxes(contactId) {
    return axios.get(`${this.url}/${contactId}/contactable_inboxes`);
  }

  getContactLabels(contactId) {
    return axios.get(`${this.url}/${contactId}/labels`);
  }

  updateContactLabels(contactId, labels) {
    return axios.post(`${this.url}/${contactId}/labels`, { labels });
  }

  search(search = '', page = 1, sortAttr = 'name', label = '', search_with = '') {
    let requestURL = `${this.url}/search?${buildContactParams(
      page,
      sortAttr,
      label,
      search,
      search_with
    )}`;
    return axios.get(requestURL);
  }

  // eslint-disable-next-line default-param-last
  filter(page = 1, sortAttr = 'name', queryPayload) {
    let requestURL = `${this.url}/filter?${buildContactParams(page, sortAttr)}`;
    return axios.post(requestURL, queryPayload);
  }

  // d99d
  block(contactId, block) {
    return axios.post(`${this.url}/${contactId}/${block ? 'block' : 'unblock'}`);
  }

  filterWithConversations(queryPayload) {
    let requestURL = `${this.url}/filter?include_contact_inboxes=false&include_conversations=true`;
    return axios.post(requestURL, queryPayload);
  }
  // .

  importContacts(file) {
    const formData = new FormData();
    formData.append('import_file', file);
    return axios.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  getImportContactsStatus() {
    return axios.get(`${this.url}/import`);
  }

  destroyCustomAttributes(contactId, customAttributes) {
    return axios.post(`${this.url}/${contactId}/destroy_custom_attributes`, {
      custom_attributes: customAttributes,
    });
  }

  destroyAvatar(contactId) {
    return axios.delete(`${this.url}/${contactId}/avatar`);
  }

  fetchFiles(id) {
    return axios.get(`${this.url}/${id}/files`);
  }

  addFile(contactId, data) {
    return axios.post(`${this.url}/${contactId}/add_file`, data);
  }

  removeFile(contactId, fileId) {
    return axios.delete(`${this.url}/${contactId}/remove_file/${fileId}`);
  }

  privateMessages(id) {
    return axios.get(`${this.url}/${id}/private_messages`);
  }

  privateAttachments(id) {
    return axios.get(`${this.url}/${id}/private_attachments`);
  }

  // d99d remarked out - we already have this
  // exportContacts() {
  //   return axios.get(`${this.url}/export`);
  // }
}

export default new ContactAPI();
