<template>
  <div class="settings full-height no-spin">
    <h5 style="margin: 0 1rem">{{ $t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.PAGE_LABEL') }}</h5>
    <div class="settings--content">
      <settings-section
        :title="pages[0].STEP_LABEL"
        :sub-title="sidebar_text(0)"
      >
        <chat-with-business/>
      </settings-section>
    </div>
    <div class="settings--content">
      <settings-section
        :title="pages[1].STEP_LABEL"
        :sub-title="sidebar_text(1)"
      >
        <chat-with-customers/>
      </settings-section>
    </div>
    <div class="settings--content">
      <settings-section
        :title="pages[2].STEP_LABEL"
        :sub-title="sidebar_text(2)"
      >
        <wa-widget/>
      </settings-section>
    </div>
  </div>
</template>

<script>

import ChatWithBusiness from './ChatWithBusiness.vue'
import ChatWithCustomers from "./ChatWithCustomers.vue";
import WaWidget from "./WaWidget.vue";
import SettingsSection from 'dashboard/components/SettingsSection';
import { mapGetters } from 'vuex';

export default {
  components: {
    WaWidget,
    ChatWithCustomers,
    ChatWithBusiness,
    SettingsSection,
  },
  data() {
    return {
      page: undefined
    }
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
    account() {
      return this.getAccount(this.accountId);
    },
    iPracticomFeatures() {
      return this.account.platform_features?.iPracticom;
    },
    oneCityFeatures() {
      return this.account.platform_features?.one_city;
    },
    pages() {
      return this.$t('INTEGRATION_SETTINGS.DIRECT_CHAT.FORM.PAGES')
    },
  },
  methods: {
    sidebar_text(index) {
      let txt = this.pages[index].SIDEBAR_TXT;
      let platformLink = 'https://chat.99digital.co.il';
      if (this.iPracticomFeatures) {
        txt = txt.replace(platformLink, 'https://chat.ip-com.co.il');
      } else if (this.oneCityFeatures) {
        txt = txt.replace(platformLink, 'https://www.onecity.co.il');
      }
      return txt;
    },
  },
}
</script>

<style lang="scss">
.no-spin {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
}
</style>
