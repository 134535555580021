<template>
  <woot-modal :show="show" :on-close="closeModal">
    <div class="column content-box">
      <woot-modal-header :header-title="header" />

      <form class="row" @submit.prevent="submit">
        <woot-input
          v-model.trim="app.title"
          :class="{ error: $v.app.title.$error }"
          class="medium-12 columns"
          :label="$t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.TITLE_LABEL')"
          :placeholder="
            $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.TITLE_PLACEHOLDER')
          "
          :error="
            $v.app.title.$error
              ? $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.TITLE_ERROR')
              : null
          "
          data-testid="app-title"
          @input="$v.app.title.$touch"
        />
        <woot-input
          v-model.trim="app.content.url"
          :class="{ error: $v.app.content.url.$error }"
          class="medium-12 columns app--url_input"
          :label="$t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.URL_LABEL')"
          :placeholder="
            $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.URL_PLACEHOLDER')
          "
          :error="
            $v.app.content.url.$error
              ? $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.URL_ERROR')
              : null
          "
          data-testid="app-url"
          @input="$v.app.content.url.$touch"
        />
        <label class="medium-12 columns">
          {{ $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.TYPE_LABEL') }}
          <select v-model="app.content.type">
            <option
              v-for="(value, type) in $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.TYPES')"
              :key="type"
              :value="type"
            >
              {{ value['TEXT'] }}
            </option>
          </select>
        </label>
        <label v-if="app.content.type === 'link'" class="medium-12 columns">
          {{ $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.FORM.LINK_ATTRIBUTE_LABEL') }}
<!--          <multiselect-->
<!--            v-model="app.content.link_attribute"-->
<!--            :options="attributesToSelect"-->
<!--            :allow-empty="true"-->
<!--            :show-labels="false"-->

<!--            label="attribute_display_name"-->
<!--            track-by="id"-->
<!--            :show-no-results="false"-->
<!--          >-->
<!--          </multiselect>-->
          <select v-model="app.content.link_attribute">
            <option
              v-for="attribute in attributesToSelect"
              :key="attribute.attribute_key"
              :value="attribute.object"
            >
              {{ attribute.attribute_display_name }}
            </option>
          </select>
        </label>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-button
              type="submit"
              :is-loading="isLoading"
              :is-disabled="$v.$invalid"
              data-testid="label-submit"
            >
              {{ submitButtonLabel }}
            </woot-button>
            <woot-button class="button clear" @click.prevent="closeModal">
              {{ $t('INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.CREATE.FORM_CANCEL') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import attributeMixin from "dashboard/mixins/attributeMixin";

export default {
  mixins: [alertMixin, attributeMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'create',
    },
    selectedAppData: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    app: {
      title: { required },
      content: {
        type: { required },
        url: { required, url },
      },
    },
  },
  data() {
    return {
      isLoading: false,
      app: {
        title: '',
        content: {
          type: 'frame',
          url: '',
        },
      },
    };
  },
  computed: {
    header() {
      return this.$t(`INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.${this.mode}.HEADER`);
    },
    submitButtonLabel() {
      return this.$t(
        `INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.${this.mode}.FORM_SUBMIT`
      );
    },
    attributesToSelect() {
      return this.$store.getters['attributes/getAttributes']
        .filter(x => x.attribute_model === 'conversation_attribute')
        .map(x=>({...x, object: {key: x.attribute_key, model: x.attribute_model}}))
    },
  },
  mounted() {
    if (this.mode === 'UPDATE' && this.selectedAppData) {
      this.app.title = this.selectedAppData.title;
      this.app.content = this.selectedAppData.content[0];
    }
  },
  methods: {
    closeModal() {
      // Reset the data once closed
      this.app = {
        title: '',
        content: { type: 'frame', url: '' },
      };
      this.$emit('close');
    },
    async submit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        const action = this.mode.toLowerCase();
        const payload = {
          title: this.app.title,
          content: [this.app.content],
        };

        if (action === 'update') {
          payload.id = this.selectedAppData.id;
        }

        this.isLoading = true;
        await this.$store.dispatch(`dashboardApps/${action}`, payload);
        this.showAlert(
          this.$t(
            `INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.${this.mode}.API_SUCCESS`
          )
        );
        this.closeModal();
      } catch (err) {
        this.showAlert(
          this.$t(`INTEGRATION_SETTINGS.EXTERNAL_DASHBOARD.${this.mode}.API_ERROR`)
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-row {
  display: flex;
  align-items: center;
  width: 100%;
  .app--url_input {
    flex: 1;
  }
  .app--url_add_btn {
    margin-left: var(--space-one);
    margin-top: var(--space-one);
  }
}
</style>
