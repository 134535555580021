<template>
  <div class="logo border-r dark:border-slate-800/50 rtl:border-r-0 rtl:border-l border-slate-50">
    <router-link :to="dashboardPath" replace>
      <img :src="source" :alt="name" />
    </router-link>
  </div>
</template>
<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>
<style lang="scss" scoped>
$logo-size: 43px;

.logo {
  position: relative;
    height: 75px;

  img {
    position: absolute;
    height: $logo-size;
    margin: var(--space-normal);
    object-fit: cover;
    object-position: center center;
  }

  &.fixed-logo img {
    position: fixed;
    z-index: 15;
    margin: var(--space-smaller);
    box-shadow: 0 0 16px 10px white;
    transform: scale(0.5) translate(-50%, -50%);
    transition-delay: 1000ms;
    transition-duration: 1s;
  }
}

div[dir=rtl] .logo.fixed-logo img {
  transform: scale(0.5) translate(50%, -50%) ;
}


</style>
