<template>
  <div
    v-if="withText"
    class="with-text inline-flex items-center rounded-md !px-1 !py-0.5 text-xs text-white font-bold"
    :class="`status-badge__${status}`"
  >
    {{ $t(`OVERVIEW_REPORTS.AGENT_STATUS.${OVERVIEW_METRICS[status]}`) }}
  </div>
  <div
    v-else
    :class="`status-badge status-badge__${status} rounded-full w-2.5 h-2.5 mr-0.5 rtl:mr-0 rtl:ml-0.5 inline-flex`"
  />
</template>
<script>
import { OVERVIEW_METRICS } from '../../../routes/dashboard/settings/reports/constants';

export default {
  computed: {
    OVERVIEW_METRICS() {
      return OVERVIEW_METRICS;
    },
  },
  props: {
    status: { type: String, default: '' },
    withText: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
.status-badge {
  &__online {
    @apply bg-green-400;
  }
  &__offline {
    @apply bg-slate-500;
    &.with-text {
      @apply bg-red-500;
    }
  }
  &__busy {
    @apply bg-yellow-500;
  }
}
</style>
