<template>
  <div
    class="bg-white dark:bg-slate-900 flex justify-between items-center py-2 px-4 border-b border-slate-50 dark:border-slate-800/50 flex-col md:flex-row"
  >
    <div
      class="flex-1 w-full min-w-0 flex flex-col md:flex-row items-center justify-center"
    >
      <div
        class="flex justify-start items-center mr-4 rtl:mr-0 rtl:ml-4 min-w-0 w-[inherit]"
      >
        <back-button
          v-if="showBackButton"
          :back-url="backButtonUrl"
          class="ltr:ml-0 rtl:mr-0 rtl:ml-4"
        />
        <Thumbnail
          :src="currentContact.thumbnail"
          :badge="inboxBadge"
          :username="currentContact.name"
          :status="currentContact.availability_status"
        />
        <div
          class="items-start flex flex-col ml-2 rtl:ml-0 rtl:mr-2 min-w-0 w-[inherit] overflow-hidden"
        >
          <div class="flex items-center flex-row gap-1 m-0 p-0 w-[inherit]">
            <woot-button
              variant="link"
              color-scheme="secondary"
              class="[&>span]:overflow-hidden [&>span]:whitespace-nowrap [&>span]:text-ellipsis min-w-0"
              @click.prevent="$emit('contact-panel-toggle')"
            >
              <span
                class="text-base leading-tight text-slate-900 dark:text-slate-100"
              >
                {{ currentContact.name }}
              </span>
            </woot-button>
            <fluent-icon
              v-if="!isHMACVerified"
              v-tooltip="$t('CONVERSATION.UNVERIFIED_SESSION')"
              size="14"
              class="text-yellow-600 dark:text-yellow-500 my-0 mx-0 min-w-[14px]"
              icon="warning"
            />
          </div>

          <div
            class="conversation--header--actions items-center flex text-xs gap-2 text-ellipsis overflow-hidden whitespace-nowrap"
          >
            <inbox-name v-if="hasMultipleInboxes" :inbox="inbox" />
            <span
              v-if="isSnoozed"
              class="font-medium text-yellow-600 dark:text-yellow-500"
            >
              {{ snoozedDisplayText }}
            </span>
            <woot-button
              class="p-0"
              size="small"
              variant="link"
              @click="$emit('contact-panel-toggle')"
            >
              {{ contactPanelToggleText }}
            </woot-button>
          </div>
        </div>
      </div>
      <div
        class="header-actions-wrap items-center flex flex-row flex-grow justify-end mt-3 lg:mt-0"
        :class="{ 'justify-end': isContactPanelOpen }"
      >
        <SLA-card-label v-if="isDisplaySlaLabel" :chat="chat" show-extended-info />
        <div
          v-if="hasInboxPermission('Inbox can assign')"
          class="multiselect-box multiselect-wrap--small pe-1"
        >
          <i class="icon ion-headphone pe-2" />
          <multiselect
            v-model="currentChat.meta.assignee"
            :loading="uiFlags.isFetching"
            :allow-empty="true"
            deselect-label=""
            :options="agentList"
            :placeholder="$t('CONVERSATION.ASSIGNMENT.SELECT_AGENT')"
            select-label=""
            label="name"
            selected-label
            track-by="id"
            @select="assignAgent"
            @remove="removeAgent"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <availability-status-badge
                  :status="props.option.availability_status"
                />
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
            <span slot="noResult">{{
              $t('AGENT_MGMT.SEARCH.NO_RESULTS')
            }}</span>
          </multiselect>
        </div>
        <more-actions :conversation-id="currentChat.id" />
      </div>
    </div>
  </div>
</template>
<script>
import { hasPressedAltAndOKey } from 'shared/helpers/KeyboardHelpers';
import { mapGetters } from 'vuex';
import agentMixin from '../../../mixins/agentMixin.js';
import BackButton from '../BackButton.vue';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import inboxMixin from 'shared/mixins/inboxMixin';
import workingHoursMixin from 'shared/mixins/workingHoursMixin';
import InboxName from '../InboxName.vue';
import AvailabilityStatusBadge from '../conversation/AvailabilityStatusBadge';

import MoreActions from './MoreActions.vue';
import Thumbnail from '../Thumbnail.vue';
import SLACardLabel from './components/SLACardLabel.vue';
import wootConstants from 'dashboard/constants/globals';
import { conversationListPageURL } from 'dashboard/helper/URLHelper';
import { conversationReopenTime } from 'dashboard/helper/snoozeHelpers';
import hasPermissionMixin from 'dashboard/mixins/hasPermission';


export default {
  components: {
    BackButton,
    InboxName,
    MoreActions,
    Thumbnail,
    SLACardLabel,
    AvailabilityStatusBadge,
  },
  mixins: [
    inboxMixin,
    agentMixin,
    eventListenerMixins,
    workingHoursMixin,
    hasPermissionMixin,
  ],
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getAgents: 'inboxAssignableAgents/getAssignableAgents',
      uiFlags: 'inboxAssignableAgents/getUIFlags',
      currentChat: 'getSelectedChat',
    }),
    chatMetadata() {
      return this.chat.meta;
    },
    backButtonUrl() {
      const {
        params: { accountId, inbox_id: inboxId, label, teamId },
        name,
      } = this.$route;
      return conversationListPageURL({
        accountId,
        inboxId,
        label,
        teamId,
        conversationType: name === 'conversation_mentions' ? 'mention' : '',
      });
    },
    isHMACVerified() {
      if (!this.isAWebWidgetInbox) {
        return true;
      }
      return this.chatMetadata.hmac_verified;
    },
    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chat.meta.sender.id
      );
    },
    agentList() {
      const { inbox_id: inboxId } = this.chat;
      const agents =
        this.getAgentsByUpdatedPresence(this.getAgents(inboxId)) || [];
      return [
        {
          confirmed: true,
          name: 'None',
          id: 0,
          role: 'agent',
          account_id: 0,
          email: 'None',
        },
        ...agents,
      ];
    },
    isResolved() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.RESOLVED;
    },
    isSnoozed() {
      return this.currentChat.status === wootConstants.STATUS_TYPE.SNOOZED;
    },
    snoozedDisplayText() {
      const { snoozed_until: snoozedUntil } = this.currentChat;
      if (snoozedUntil) {
        return `${this.$t(
          'CONVERSATION.HEADER.SNOOZED_UNTIL'
        )} ${conversationReopenTime(snoozedUntil)}`;
      }
      return this.$t('CONVERSATION.HEADER.SNOOZED_UNTIL_NEXT_REPLY');
    },
    contactPanelToggleText() {
      return `${
        this.isContactPanelOpen
          ? this.$t('CONVERSATION.HEADER.CLOSE')
          : this.$t('CONVERSATION.HEADER.OPEN')
      } ${this.$t('CONVERSATION.HEADER.DETAILS')}`;
    },
    inbox() {
      const { inbox_id: inboxId } = this.chat;
      return this.$store.getters['inboxes/getInbox'](inboxId);
    },
    hasMultipleInboxes() {
      return this.$store.getters['inboxes/getInboxes'].length > 1;
    },
    hasSlaPolicyId() {
      return this.currentChat?.sla_policy_id;
    },
    appliedSLA() {
      return this.currentChat?.applied_sla;
    },
    isSlaWorkingHours() {
      return (
        !this.appliedSLA?.sla_only_during_business_hours ||
        this.isInBusinessHours
      );
    },
    isDisplaySlaLabel() {
      return !this.isResolved && this.hasSlaPolicyId && this.isSlaWorkingHours;
    },
  },

  methods: {
    handleKeyEvents(e) {
      if (hasPressedAltAndOKey(e)) {
        this.$emit('contact-panel-toggle');
      }
    },
    assignAgent(agent) {
      this.$store
        .dispatch('assignAgent', {
          conversationId: this.currentChat.id,
          agentId: agent.id,
        })
        .then(() => {
          bus.$emit('newToastMessage', this.$t('CONVERSATION.CHANGE_AGENT'));
        });
    },
    removeAgent() {},
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';
// d99
.multiselect-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 0;
  max-width: 15rem;
  min-width: 5rem;
  margin-inline-end: 1rem;

  .icon {
    line-height: 3.8rem;
  }
}
::v-deep {
  .multiselect {
    border-radius: var(--border-radius-small);
    margin: 0;

    .multiselect__tags {
      padding-top: 0;
      min-width: 5rem;

      .multiselect__placeholder {
        width: max-content;
      }
    }
  }

  .multiselect__content-wrapper {
    width: auto;
  }
}
//.

.conversation--header--actions {
  ::v-deep .inbox--name {
    @apply m-0;
  }
}
</style>

