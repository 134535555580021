<template>
    <form @submit.prevent="onSubmit" style="min-height: 30rem; position: relative">
        <div class="row">
            <div class="small-12 medium-12 large-12 column">
                <bafi-client-select
                        v-model="selectedClient"
                        :current-chat="currentChat"
                        :is-init.sync="isLoading"
                        :validation-error="$v.selectedClient.$error"/>
            </div>
            <div
                    class="large-12 column"
                    :class="{ error: $v.selectedLink.$error }"
            >
                <label>
                    {{ $t('PLATFORM.FORM.LINK.LABEL') }}
                </label>
                <multiselect
                        v-model="selectedLink"
                        :options="links"
                        :allow-empty="false"
                        :loading="isLinkFetching"
                        :show-labels="false"
                        group-values="items"
                        group-label="type"
                        label="name"
                        track-by="compoundId"
                        :show-no-results="false"
                        :placeholder="$t('PLATFORM.FORM.LINK.PLACEHOLDER')"
                        :disabled="!links.length || isLinkFetching"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.type }} - {{ option.name }}</template>

                  <template slot="option" slot-scope="{ option }">
                      <div v-if="!option.$groupLabel">
                          <span
                            class="link-color--display"
                            :style="{ backgroundColor: linkColor(option.type) }"
                          />
                          <span style="margin-inline-start: 0.5rem; width: unset">{{ tt(`PLATFORM.FORM.LINK.TYPES.${option.type.toUpperCase()}`,  option.type) }} - {{ option.name }}</span>
                      </div>
                      {{ option.$groupLabel && tt(`PLATFORM.FORM.LINK.TYPES.${option.$groupLabel}`.toUpperCase(), option.$groupLabel) }}
                  </template>

                </multiselect>
                <span v-if="$v.selectedLink.$error" class="message">
                {{ $t('WA_SETTINGS.TEMPLATES.ERROR_REQUIRED') }}
                </span>
            </div>
        </div>
        <div v-if="viewType==='chat'" class="row">
            <div class="medium-12 large-6 column">
                <label>
                    {{ $t('PLATFORM.FORM.CHAT_PERIOD.LABEL') }}
                </label>
                <multiselect
                        v-model="conversationPeriodType"
                        :options="conversationPeriodTypes"
                        :allow-empty="false"
                        :show-labels="false"
                        label="name"
                        track-by="id"
                        :placeholder="$t('PLATFORM.FORM.CHAT_PERIOD.PLACEHOLDER')"
                />
            </div>
            <div v-if="conversationPeriodType && conversationPeriodType.id === 'custom'"
                 class="small-12 medium-12 large-6 column date-picker">
                <label>
                    {{ $t('PLATFORM.FORM.DATE_FROM.LABEL') }}
                    <date-picker
                            type="datetime"
                            :confirm="true"
                            :clearable="false"
                            :editable="false"
                            :confirm-text="$t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.CONFIRM')"
                            :class="{ error: $v.conversationPeriodFrom.$error }"
                            :value="conversationPeriodFrom"
                            @change="onChangeConversationPeriodFrom"
                    />
                    <span v-if="$v.conversationPeriodFrom.$error" class="editor-warning__message">
                {{ $t('CAMPAIGN.ADD.FORM.SCHEDULED_AT.ERROR') }}
            </span>
                </label>
            </div>
            <div v-if="metaPeriodData.count >= 0" class="large-12 column">
                <div v-if="metaPeriodData.count > 0" class="row message-preview">
                    <span style="margin-inline-end: 4px">{{ $t('PLATFORM.FORM.CHAT_PERIOD.PREVIEW.FROM_MESSAGE') }}:</span>
                    <div class="" style="max-width: 60%; min-width: 6rem">
                        <span class="message--wrapper" v-tooltip.top="`${metaPeriodData.first.content}\n${messageStampFull(metaPeriodData.first.created_at)}`" >
                          {{metaPeriodData.first.content}}
                        </span>
                    </div>
                    <div v-if="metaPeriodData.count > 1">
                        {{ $t('PLATFORM.FORM.CHAT_PERIOD.PREVIEW.TOTAL_MESSAGES', {count: metaPeriodData.count}) }}
                    </div>


                </div>
                <div v-else class="message-preview" style="background-color: #f18080">
                    {{ $t('PLATFORM.FORM.CHAT_PERIOD.PREVIEW.NO_MESSAGES') }}
                </div>

            </div>
        </div>

        <div class="row">
            <div class="medium-12 column">
                <label>
                    {{ $t('PLATFORM.FORM.SUBJECT.LABEL') }}
                </label>
                <input
                        v-model.trim="subject"
                        type="text"
                        :placeholder="$t('PLATFORM.FORM.SUBJECT.PLACEHOLDER')"
                />
            </div>
        </div>

        <div class="row">
            <div class="medium-12 column">
                <label>
                    {{ $t('PLATFORM.FORM.NOTES.LABEL') }}
                    <textarea
                            v-model.trim="notes"
                            type="text"
                            rows="3"
                            :placeholder="$t('PLATFORM.FORM.NOTES.PLACEHOLDER')"
                    />
                </label>
            </div>
        </div>

        <div v-if="isLoading" class="dd-loader">
            <spinner />
        </div>
        <div class="footer">
            <woot-button variant="clear" @click.prevent="onCancel">
                {{ $t('MERGE_CONTACTS.FORM.CANCEL') }}
            </woot-button>
            <woot-button type="submit" :disabled="viewType === 'chat' && !(metaPeriodData.count > 0)" :is-loading="isSubmitting">
                {{ $t('PLATFORM.FORM.SUBMIT') }}
            </woot-button>
        </div>
    </form>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import OnlineWaAPI from 'dashboard/api/onlinewa'
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import alertMixin from "../../../../shared/mixins/alertMixin";
import timeMixin from 'dashboard/mixins/time';
import BafiClientSelect from "./components/BafiClientSelect";

export default {
  name: "ConversationToRemoteForm",
  mixins: [alertMixin, timeMixin],
  components: {
    BafiClientSelect,
    Spinner,
    DatePicker
  },
  props: {
    currentChat: {
      type: Object,
      default: () => ({}),
    },
    viewType: {
      type: String,
      default: 'chat',
    },
    message: {
      type: Object,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedClient: undefined,
      selectedLink: undefined,
      conversationPeriodType: undefined,
      conversationPeriodFrom: new Date(),
      subject: undefined,
      notes: undefined,
      links: [],
      isLoading: false,
      isLinkFetching: false,
      metaPeriodData: {},
    }
  },
  validations: {
    selectedClient: {
      required,
    },
    selectedLink: {
    },
    conversationPeriodFrom: {
    },
  },
  mounted() {
    this.init()
  },
  computed: {
    conversationPeriodTypes() {
      return this.$t('PLATFORM.FORM.CHAT_PERIOD.TYPES')
    },
    conversationPeriodData() {
      return this.conversationPeriodType ? {
        type_id: this.conversationPeriodType?.id,
        from: this.conversationPeriodType?.id === 'custom' ? Math.floor(this.conversationPeriodFrom?.getTime()/1000) : undefined
      } : undefined
    }
  },
  watch: {
    currentChat() {
      this.init()
    },
    selectedClient() {
      this.selectedLink = undefined
      this.fetchLinks()
      this.setDefaultSubject()
    },
    conversationPeriodType() {
      this.fetchMetaPeriodData()
    },
    conversationPeriodFrom() {
      this.fetchMetaPeriodData()
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit('submit', {
        client_id: this.selectedClient.id,
        conversation_id: this.currentChat.id,
        link_type: this.selectedLink?.type_original,
        link_id: this.selectedLink?.id,
        period: this.conversationPeriodData,
        subject: this.subject,
        notes: this.notes,
      });
    },
    onCancel() {
      this.$emit('cancel');
    },
    async init() {
      this.selectedClient = undefined
      this.conversationPeriodType = undefined
      if (this.viewType === 'chat') this.conversationPeriodType = this.conversationPeriodTypes.find(x=>x.id === 'new')
    },
    async fetchLinks() {
      this.isLinkFetching = true
      if (this.selectedClient) {
        try {
          const {data} = await OnlineWaAPI.sendPlatformPsRequest('links', {client_id: this.selectedClient.id}) || {data: []}
          data.forEach(x=>x.items = x.items.map(t=>({...t, compoundId: `${t.type_original}_${t.id}`})))
          this.links = data
        } catch (e) {
          this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
          console.error('links', e)
        }
      } else {
        this.links = []
      }
      this.isLinkFetching = false
    },
    linkColor(linkType) {
      switch (linkType) {
        case 'policy': return '#5ba961';
        case 'claim': return '#a9695b';
        case 'offer': return '#5b86a9';
        default: return 'white';
      }
    },
    tt(key, defaultValue) {
      return this.$te(key) ? this.$t(key) : defaultValue
    },
    setDefaultSubject() {
      const phoneFromRemoteClientPart =
        this.selectedClient?.phone && this.selectedClient?.phone !== this.simplifiedPhone ?
          ` (${this.selectedClient?.phone})` : ''
      this.subject = this.selectedClient ? this.$t('PLATFORM.FORM.SUBJECT.DEFAULT',
        {phone: `${this.simplifiedPhone ? this.simplifiedPhone : this.selectedClient.name}${phoneFromRemoteClientPart}`}) : undefined
    },
    onChangeConversationPeriodFrom(value) {
      this.conversationPeriodFrom = value
    },
    async fetchMetaPeriodData() {
      this.metaPeriodData = {}
      if (this.conversationPeriodType) {
        try {
          this.metaPeriodData = (await OnlineWaAPI.sendPlatformPsRequest('chatbox_add-only-meta',
            {
              conversation_id: this.currentChat.id,
              period: this.conversationPeriodData
            }
          ))?.data || {}
        } catch (e) {
          this.showAlert(this.$t('PLATFORM.API.GET_ERROR'));
          console.error('chatbox_add-only-meta', e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .column {
  padding: 0 1rem;
}

::v-deep input:disabled {
  border: unset;
}

.link-color--display {
  //border-radius: var(--border-radius-normal);
  height: var(--space-slab);
  width: var(--space-slab) !important;
  transform: rotate(45deg);
}

.footer {
  //margin-top: var(--space-medium);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;

}

.date-picker {
  margin-left: 0;
}

.message-preview {
  background: #e6e8ea;
  order-radius: var(--border-radius-normal);
  padding: 0.5rem;
  color: #444;
  margin-top: 0;
  margin-bottom: 1rem;
}
.message--wrapper {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: italic;
  font-weight: bold;
  width: 99%;
}

</style>
